import { UserProfession } from '../enums/UserProfession';
import { filterObjectKeys } from '../utils';

export const EducationUtils = {
  /**
   * @param specialtiesAndProcedures
   * @param {UserProfession} profession
   * @param specialty
   * @returns {string[]}
   */
  getProceduresOfProfession({
    specialtiesAndProcedures,
    profession,
    specialty
  }) {
    if ([UserProfession.FD, UserProfession.RDH].includes(profession)) {
      const procedures = filterObjectKeys(specialtiesAndProcedures, 'procedure');

      return Object.keys(procedures);
    }

    if ([UserProfession.DA, UserProfession.DN].includes(profession)) {
      return specialtiesAndProcedures?.[specialty] ?? [];
    }

    return [];
  }
}
