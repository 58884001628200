import React from 'react';
import GoogleIcon from '../../images/GoogleIcon';

const GoogleLoginButton = ({ onClick = () => {} }) => {
  return (
    <div
      onClick={onClick}
      style={{
        height: '38px',
        position: 'relative',
        backgroundColor: '#fff',
        border: '1px solid #dadce0',
        borderRadius: '4px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        padding: '0 12px',
        cursor: 'pointer',
      }}
    >
      <div>
        <GoogleIcon width="18px" height="18px" style={{ marginRight: 9 }} />
        <span
          style={{
            fontFamily: 'Google Sans,arial,sans-serif',
          }}
        >
          Sign in with Google
        </span>
      </div>
    </div>
  );
};

export default GoogleLoginButton;
