import { CustomSelect } from './CustomSelect';

export const CustomFilters = ({ filter, handleFilterChange, FILTERS }) => (
  <>
    {FILTERS.map(({ key, label, options }) => (
      <CustomSelect
        key={key}
        id={key}
        label={label}
        options={options}
        value={filter[key]}
        onChange={handleFilterChange}
      />
    ))}
  </>
);
