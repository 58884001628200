import { userDaSubProfessions } from '../../../constants';

export const LICENSE_FILTERS = ({ allProfessions }) => [
  {
    key: 'profession',
    label: 'Profession',
    options: [{ code: 'all', name: 'All' }, ...allProfessions],
  },
  {
    key: 'subProfession',
    label: 'Sub-Profession Type',
    options: [{ code: 'all', name: 'All' }, ...userDaSubProfessions],
  },
  {
    key: 'status',
    label: 'Status',
    options: [
      { code: 'all', name: 'All' },
      { code: 'new', name: 'New' },
      { code: 'verified', name: 'Verified' },
      { code: 'expired', name: 'Expired' },
      { code: 'upcoming', name: 'Upcoming' },
      { code: 'invalid', name: 'Invalid' },
    ],
  },
  {
    key: 'credentialType',
    label: 'Credential Type',
    options: [
      { code: 'all', name: 'All' },
      { code: 'state_license', name: 'State License' },
      { code: 'da_certificate', name: 'DA Certificate' },
      { code: 'radiology', name: 'Radiology' },
      { code: 'cpr/bls', name: 'CPR/BLS' },
    ],
  },
  {
    key: 'accountStatus',
    label: 'User Status',
    options: [
      { code: 'all', name: 'All' },
      { code: 'verified', name: 'Verified' },
      { code: 'blocked', name: 'Blocked' },
      { code: 'paused', name: 'Paused' },
      { code: 'restricted', name: 'Restricted' },
      { code: 'removed', name: 'Removed' },
    ],
  },
  {
    key: 'govIdUploaded',
    label: 'Gov. ID Uploaded',
    options: [
      { code: 'all', name: 'All' },
      { code: 'true', name: 'Yes' },
      { code: 'false', name: 'No' },
    ],
  },
  {
    key: 'expiresSoon',
    label: 'Expiring Soon',
    options: [
      { code: 'all', name: 'All' },
      { code: '15', name: '15 days' },
      { code: '30', name: '30 days' },
    ],
  },
  {
    key: 'contractorStatus',
    label: 'Contractor Status',
    options: [
      { code: 'all', name: 'All' },
      { code: 'new', name: 'New' },
      { code: 'verified', name: 'Verified' },
      { code: 'restricted', name: 'Restricted' },
    ],
  },
  {
    key: 'employeeStatus',
    label: 'Employee Status',
    options: [
      { code: 'all', name: 'All' },
      { code: 'new', name: 'New' },
      { code: 'verified', name: 'Verified' },
      { code: 'restricted', name: 'Restricted' },
    ],
  },
];
