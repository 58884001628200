import Modal from 'react-modal';
import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import dateFormat from 'dateformat';
import moment from 'moment';
import momentTZ from 'moment-timezone';
import { getApplicationStatus } from './Enums';
import history from '../../../../history';

import ApplicationGeneralIfo from './ApplicationGeneralIfo';
import {
  createPPAction, setInterviewApplication, uploadApplicationResume, editResumeDetails,
} from '../../../../actions/ppJobsAction';
import { states } from '../../../commonComponents/StatePicker';

const styles = {
  titleText: {
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    fontSize: 18,
    margin: 5,
    marginLeft: 0,
  },
  descriptionText: {
    fontFamily: 'Nunito',
    fontSize: 16,
    margin: 5,
    marginLeft: 0,
  },
};

export default ({
  closePopUp, applicationInfo,
}) => {
  const dispatch = useDispatch();
  const [interviewDate, setInterviewDate] = useState(dateFormat(new Date(), 'ddd, mmmm dd, yyyy'));
  const [interviewTime, setInterviewTime] = useState('');
  const [notes, setNotes] = useState('');

  const [actionType, setActionType] = useState('');
  const [resumeFile, setResumeFile] = useState('');
  const selectedApplication = useSelector((state) => state.pp.selectedApplication);
  const currentJob = useSelector((state) => state.pp.curentJob);
  const timeZoneOffice = useSelector((state) => state.users.curentUser?.time_zone);

  const isInterview = applicationInfo && applicationInfo.lastApplicationAction && applicationInfo.lastApplicationAction?.action.includes('interview-');
  const isInterviewPending = isInterview && applicationInfo.interview?.status === 'initialized';
  const isInterviewConfirmed = isInterview && applicationInfo.interview?.status === 'scheduled';

  const handleInterviewSetTime = () => {
    const verifiedTimezome = timeZoneOffice || 'EST';
    const interviewDateTime = moment(
      `${interviewDate} ${interviewTime}`,
      'ddd, MMM DD, YYYY hh:mm a',
    ).toDate();
    const interviewTimezoneTime = momentTZ
      .tz(interviewDateTime, verifiedTimezome)
      .toISOString();

    dispatch(setInterviewApplication(currentJob.id, selectedApplication.applicationId, interviewTimezoneTime));
  };

  const handleResumeChange = (event) => {
    setResumeFile(event.target.files[0]);
  };
  const wrapperRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closePopUp();
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);
  const uploadResume = async (applicationId) => {
    if (!resumeFile) {
      return;
    }

    // const urlResume = URL.createObjectURL(resumeFile);
    dispatch(uploadApplicationResume(currentJob.id, applicationId, resumeFile));
  };

  return (
    <div
      style={{
        flex: 1,
        backgroundColor: 'rgba(67, 89, 99, 0.8)',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <div
        ref={wrapperRef}
        style={{
          backgroundColor: 'white',
          maxHeight: '80%',
          height: '80%',
          overflowY: 'auto',
          alignContent: 'center',
          maxWidth: 900,
          minWidth: 450,
          width: '50%',
          display: 'flex',
          borderRadius: 5,
          flexDirection: 'column',
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
          shadowColor: '#979797',
          shadowOpacity: 0.3,
          shadowOffset: {
            height: 3,
            width: 0,
          },
        }}
      >
        {!selectedApplication
          ? <div />

          : (
            <div
              style={{
                padding: 20,
                marginBottom: 70,
                backgroundColor: 'white',
                borderRadius: 10,
                paddingLeft: 50,
              }}
            >
              <div
                style={{
                  fontSize: 20,
                  fontWeight: 'bold',
                  color: '#B0BEC5',
                  cursor: 'pointer',
                  textAlign: 'right',
                }}
                onClick={() => closePopUp()}
              >
                X
              </div>
              <div style={{
                fontWeight: 'bold',
                fontFamily: 'Nunito',
                fontSize: 24,
                marginBottom: 20,
                color: '#3eb0ca',
              }}
              >
                Application information

              </div>
              <div>
                <div
                  className="card-text"
                  style={{
                    width: '90%',
                    fontWeight: 'bold',
                    fontFamily: 'Nunito',
                    fontSize: 18,
                    color: selectedApplication?.isHidden ? 'red' : 'green',
                  }}
                >
                  Application is
                  {' '}
                  {applicationInfo?.isHidden ? 'Hidden' : 'Active' }
                </div>

                {!selectedApplication?.isHidden
                  ? (
                    <div
                      className="card-text"
                      style={{
                        fontFamily: 'Nunito', fontSize: 18, fontWeight: 'bold', width: '90%',
                      }}
                    >
                      Stage is
                      {' '}
                      {getApplicationStatus(applicationInfo.lastApplicationAction?.action || '')}
                    </div>
                  )
                  : <div />}
              </div>

              <ApplicationGeneralIfo application={selectedApplication} />

              <ResumeDetails application={selectedApplication} jobId={currentJob.id} />
              <div
                className="card-text"
                style={styles.titleText}
              >
                Resume:
                {' '}

              </div>
              {selectedApplication.resumeUrl !== '' ? (
                <a
                  href={selectedApplication.resumeUrl}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="card-text"
                  style={{
                    cursor: 'pointer',
                    fontSize: 16,
                    fontFamily: 'Nunito',

                  }}
                >
                  Download resume
                </a>
              ) : (
                <p
                  className="card-text"
                  style={{
                    color: 'red',
                    fontFamily: 'Nunito',
                    fontSize: 16,
                  }}
                >
                  No resume
                </p>
              ) }
              {isInterviewPending ? (
                <div>
                  <div style={styles.titleText}> Interview Info: </div>
                  <div style={{
                    color: 'red',
                    fontFamily: 'Nunito',
                    fontSize: 16,
                  }}
                  >
                    {' '}
                    Interview requested

                  </div>
                </div>
              ) : <div />}
              {isInterviewConfirmed ? (
                <div>
                  <div style={styles.titleText}> Interview Info: </div>

                  <div style={styles.descriptionText}>
                    {' ' }
                    {moment(applicationInfo.interview?.time).format('YYYY-MM-DD HH:mm A')}
                  </div>
                </div>
              ) : <div />}
              <div style={{
                display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%',
              }}
              >

                <div className="card mt-2 mb-2" style={{ width: '45%' }}>
                  <div className="card-body">
                    <div style={styles.titleText}>Upload resume: </div>
                    <input
                      style={{ width: 150 }}
                      type="file"
                      onChange={(e) => handleResumeChange(e)}
                    />
                    <button
                      className="btn btn-info"
                      onClick={() => uploadResume(selectedApplication.applicationId)}
                      style={{
                        marginTop: '6px',
                        marginBottom: 10,
                      }}
                      disabled={!resumeFile}
                      type="submit"
                    >
                      Submit Resume
                    </button>
                  </div>
                </div>

                <div className="card" style={{ width: '45%' }}>
                  <div className="card-body">
                    <div style={styles.titleText}>Select application action: </div>
                    <textarea style={{ height: 70, marginBottom: 10 }} className="form-control mt-1 mb-1" value={notes} type="text" placeholder="Enter notes" onChange={(e) => setNotes(e.target.value)} />
                    <select
                      className="form-control"
                      style={{
                        fontSize: 16, fontFamily: 'Nunito', marginTop: 20, marginBottom: 10,
                      }}
                      onChange={(e) => setActionType(e.target.value)}
                      value={actionType}
                    >
                      <option value="">Please select action</option>
                      <option value="show">Show</option>
                      <option value="hide">Hide</option>

                      <option value="interview-phone">Interview Call </option>
                      <option value="interview-inperson">Interview in Person</option>
                      <option value="interview-workingday">Interview Working</option>
                      <option value="declined">Decline Candidate</option>
                      <option value="rejected">Reject Candidate</option>

                      <option value="hired">Hire</option>
                      <option value="cancel-interview">Cancel-interview</option>

                    </select>
                    <div>
                      <button
                        className="btn btn-info mt-2"
                        type="button"
                        onClick={() => {
                          closePopUp();
                          dispatch(createPPAction(currentJob.id, selectedApplication?.applicationId, actionType, notes));
                        }}
                      >
                        Apply

                      </button>
                    </div>
                  </div>

                </div>

              </div>
              {isInterviewPending || isInterviewConfirmed || applicationInfo?.lastApplicationAction?.action === 'hired' || isInterview
                ? (
                  <div className="card mt-2">
                    <div className="card-body">
                      <div style={styles.titleText}>Enter interview date and time: </div>
                      <DayPickerInput
                        className="form-control"
                        keepFocus={false}
                        style={{ marginBottom: 20, marginTop: 10 }}
                        onDayChange={(e) => setInterviewDate(dateFormat(e, 'ddd, mmmm dd, yyyy'))}
                        value={interviewDate}
                      />

                      <input className="form-control mt-1 mb-1" value={interviewTime} type="time" onChange={(e) => setInterviewTime(e.target.value)} />
                      <button type="button" style={{ marginTop: 10 }} className="btn btn-info" onClick={() => handleInterviewSetTime()}> Submit </button>
                    </div>
                  </div>
                )
                : <></>}
            </div>
          )}
      </div>
    </div>
  );
};

function ResumeDetails({ application, jobId }) {
  const [isEditing, setIsEditing] = useState(false);
  const [resumeDetails, setResumeDetails] = useState(application.resume);
  const dispatch = useDispatch();
  useEffect(() => setResumeDetails(application.resume), [application]);

  if (!resumeDetails) {
    return <div>No resume data</div>;
  }
  return (
    <div>

      {!application?.isAnonymous && (
      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>

        <div
          className="card-text"
          style={styles.titleText}
        >
          Hygienist Registered:

        </div>
        <div
          style={{
            textDecoration: 'underline', cursor: 'pointer', color: 'blue', fontColor: 16, marginTop: 10, marginBottom: 10,
          }}
          onClick={() => history.push(`/user/hygienist/${application.professional.id}`, {
            user_id: application.professional.id,
          })}
        >
          View Hygienist Profile
        </div>
      </div>
      )}

      <div style={{ display: 'flex', alignItems: 'center' }}>

        <div
          className="card-text"
          style={styles.titleText}
        >
          Hygienist License Number:
          {' '}

        </div>
        <TextAndInput keyName="licId" isEditing={isEditing} setResumeDetails={setResumeDetails} resumeDetails={resumeDetails} />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <div
          className="card-text"
          style={styles.titleText}
        >
          State:
          {' '}

        </div>
        <TextAndInput keyName="state" isEditing={isEditing} setResumeDetails={setResumeDetails} resumeDetails={resumeDetails} />

      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>

        <div
          className="card-text"
          style={styles.titleText}
        >
          Year Issued:
          {' '}

        </div>
        <TextAndInput keyName="issuedYear" isEditing={isEditing} setResumeDetails={setResumeDetails} resumeDetails={resumeDetails} />
      </div>
      <div style={{ display: 'flex', alignItems: 'center' }}>

        <div
          className="card-text"
          style={styles.titleText}
        >
          School Attended:
          {' '}

        </div>
        <TextAndInput keyName="schoolName" isEditing={isEditing} setResumeDetails={setResumeDetails} resumeDetails={resumeDetails} />
      </div>

      <div>
        <div
          className="card-text"
          style={styles.titleText}
        >
          How many years of dental industry experience do you have:
          {' '}
        </div>
      </div>
      <TextAndInput keyName="yearsOfExperience" isEditing={isEditing} setResumeDetails={setResumeDetails} resumeDetails={resumeDetails} />
      <div>
        <div
          className="card-text"
          style={styles.titleText}
        >
          Are you available part time or full time:
          {' '}

        </div>
        <TextAndInput keyName="scheduleType" isEditing={isEditing} setResumeDetails={setResumeDetails} resumeDetails={resumeDetails} />
      </div>

      <div>
        <div
          className="card-text"
          style={styles.titleText}
        >
          List your last 3 employers:
          {' '}

        </div>
        <TextAndInput keyName="officesWorkedIn" isEditing={isEditing} setResumeDetails={setResumeDetails} resumeDetails={resumeDetails} />
      </div>

      <div>
        <div
          className="card-text"
          style={styles.titleText}
        >
          When are you generally available for an interview:
          {' '}

        </div>
        <TextAndInput keyName="interviewAvailability" isEditing={isEditing} setResumeDetails={setResumeDetails} resumeDetails={resumeDetails} />
      </div>

      <div>
        <div
          className="card-text"
          style={styles.titleText}
        >
          How did you find out about this job:
          {' '}

        </div>
        <TextAndInput keyName="howFindOutAboutJob" isEditing={isEditing} setResumeDetails={setResumeDetails} resumeDetails={resumeDetails} />
      </div>

      <div>
        <div
          className="card-text"
          style={styles.titleText}
        >
          About hygienist:
          {' '}

        </div>
        <TextAndInput keyName="about" isEditing={isEditing} setResumeDetails={setResumeDetails} resumeDetails={resumeDetails} />
      </div>

      <div>
        <div
          className="card-text"
          style={styles.titleText}
        >
          Office Note
          {' '}

        </div>
        <TextAndInput keyName="officeNote" isEditing={isEditing} setResumeDetails={setResumeDetails} resumeDetails={resumeDetails} />
      </div>
      <button
        type="button"
        onClick={() => {
          if (isEditing) {
            dispatch(editResumeDetails(jobId, application.applicationId, resumeDetails));
          }
          setIsEditing(!isEditing);
        }}
        className="btn btn-info"
        style={{
          marginTop: '6px',
          marginBottom: 10,
        }}
      >
        {isEditing ? 'Save' : 'Edit Resume'}
      </button>
      { isEditing && (
      <button
        type="button"
        onClick={() => {
          setResumeDetails(application.resume);
          setIsEditing(!isEditing);
        }}
        className="btn btn-danger"
        style={{
          marginTop: '6px',
          marginBottom: 10,
          marginLeft: 5,
        }}
      >
        Cancel
      </button>
      )}
    </div>
  );
}

function TextAndInput({
  keyName, isEditing, setResumeDetails, resumeDetails,
}) {
  if (isEditing) {
    if (keyName === 'scheduleType') {
      return (
        <select
          className="custom-select custom-select-lg"
          style={{ width: '60%' }}
          value={resumeDetails[keyName]}
          onChange={(e) => setResumeDetails({ ...resumeDetails, [keyName]: e.target.value })}
        >
          <option value="Full-Time">Full Time Preferred </option>
          <option value="Part-Time">Part Time Preferred</option>
          <option value="Flexible">Flexible (Full or Part Time)</option>
        </select>
      );
    }
    if (keyName === 'state') {
      return (
        <select
          id="stateFilter"
          className="custom-select custom-select-lg mb-3"
          style={{ position: 'relative', width: 90 }}
          value={resumeDetails[keyName]}
          onChange={(e) => setResumeDetails({ ...resumeDetails, [keyName]: e.target.value })}
        >
          {states.map((state) => (
            <option className="dropdown-item" value={state.abbreviation}>
              {state.abbreviation}
            </option>
          ))}
        </select>
      );
    }
    if (keyName === 'about') {
      return (
        <textarea
          className="form-control"
          style={{ width: '70%' }}
          value={resumeDetails[keyName]}
          onChange={(e) => setResumeDetails({ ...resumeDetails, [keyName]: e.target.value })}
        />
      );
    }
    return <input type="text" value={resumeDetails[keyName]} onChange={(e) => setResumeDetails({ ...resumeDetails, [keyName]: e.target.value })} />;
  }
  if (!Object.hasOwn(resumeDetails, keyName)) {
    return (
      <div style={styles.descriptionText}>
        {' '}
      </div>
    );
  }
  return (
    <div style={styles.descriptionText}>
      {resumeDetails[keyName] || ''}
    </div>
  );
}
