import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import App from './components/App';
import store from './store';

import { growthbook } from './growthbook';
import { GrowthBookProvider } from '@growthbook/growthbook-react';

growthbook.loadFeatures();

ReactDOM.render(
  <GrowthBookProvider growthbook={growthbook}>
    <Provider store={store}>
      <App />
    </Provider>
  </GrowthBookProvider>,

  document.querySelector('#root'),
);
