const getTransactionType = (type) => {
  switch (type) {
  case 'card':
    return 'Card';
  case 'bank_account':
    return 'ACH';
  case 'invoice':
    return 'Invoice';
  case 'debit':
    return 'Debit';
  default:
    return ' - ';
  }
};

export { getTransactionType };
