import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import MultiSelect from '../../../commonComponents/MultiSelect';
import { getAdminRamUser } from '../../../../actions/userActions';

export const RamFilter = ({ filter, handleFilterChange }) => {
  const dispatch = useDispatch();
  const ramList = useSelector((state) => state.users.ramUsers);

  const ramUsersOptions = [
    { label: 'Unassigned', value: 'unassigned' },
    ...ramList.map((el) => ({
      label: [el.first_name, el.last_name].join(' '),
      value: el.id,
    })),
  ];

  useEffect(() => {
    if (!ramList) {
      dispatch(getAdminRamUser());
    }
  }, [dispatch, ramList]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 140,
      }}
    >
      <p>RAM:</p>
      <MultiSelect
        className="custom-select custom-select-lg"
        style={{ position: 'relative', width: 150 }}
        value={filter?.ram ? filter?.ram.split(',') : ramUsersOptions.map((el) => el.value)}
        options={ramUsersOptions}
        onChange={(values) => {
          // if all options are selected, then we don't need to send the filter to backend
          handleFilterChange(
            'ram',
            values.length === ramUsersOptions.length ? null : values.join(','),
          );
        }}
        placeholder="All"
        showAllButton
        autoApply={false}
      />
    </div>
  );
};
