import { UserProfession } from '../../../../../enums/UserProfession';
import { SelectRow } from '../SelectRow';
import {
  bookedOutPatientsOptions,
  numberOfOperatoriesOptions,
  numberOfPermanentStaffOptions,
} from './utils';

export const ProductivityGroup = ({ isEdit, onChange, productivity, defaultProductivity }) => (
  <>
    <SelectRow
      isEdit={isEdit}
      title="# Operatories"
      options={numberOfOperatoriesOptions.map((item) => ({ title: item, value: item }))}
      onChange={(val) => onChange({ productivity: { ...productivity, operatories: val } })}
      value={isEdit ? productivity?.operatories : defaultProductivity?.operatories}
    />

    {[
      { title: 'DA', value: UserProfession.DA },
      { title: 'RDH', value: UserProfession.RDH },
      { title: 'Doctors', value: UserProfession.DN },
    ].map((item) => (
      <SelectRow
        key={item.value}
        isEdit={isEdit}
        title={`# Permanent Staff (${item.title})`}
        options={numberOfPermanentStaffOptions.map((option) => ({ title: option, value: option }))}
        onChange={(val) =>
          onChange({
            productivity: {
              ...productivity,
              permanentStaff: {
                ...productivity?.permanentStaff,
                [item.value]: val,
              },
            },
          })
        }
        value={
          isEdit
            ? productivity?.permanentStaff[item.value]
            : defaultProductivity?.permanentStaff[item.value]
        }
      />
    ))}

    <SelectRow
      isEdit={isEdit}
      title="Currently Booked Out Patients"
      options={bookedOutPatientsOptions.map((item) => ({ title: item, value: item }))}
      onChange={(val) => onChange({ productivity: { ...productivity, bookedOutPatients: val } })}
      value={isEdit ? productivity?.bookedOutPatients : defaultProductivity?.bookedOutPatients}
    />
  </>
);
