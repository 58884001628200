import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import UserSearchField from '../UserSearchField';

const crossImage = require('../../../../images/close-cross.png');
const starGreen = require('../../../../images/star_Green.png');
const profileDefault = require('../../../../images/profileDefault.png');

export default ({ office, onCancel, assignOffice }) => {
  const [isAssignInputShow, setAssignInputShow] = useState(false);

  const getAddress = (address) => {
    if (!address.formatted_address) {
      return '';
    }

    address = address.formatted_address.split(', ');
    return `${address[1]}, ${address[2]}`;
  };

  return (
    <div
      className="card ml-5 mr-5"
      style={{
        display: 'flex',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'space-between',
        maxWidth: 800,
        width: '60%',
      }}
    >
      {!office?.id || office?.id !== '' ? (
        <div
          className="card-body"
          style={{
            display: 'flex',
            // alignItems: 'center',
            justifyContent: 'space-between',
            width: '80%',
          }}
        >
          <div
            style={{
              width: '45%',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
            }}
          >
            <img
              src={profileDefault}
              alt="tooth"
              className="card-img-top mt-2 mb-2"
              style={{
                objectFit: 'cover',
                width: '100px',
                height: '100px',
                borderRadius: 50,
                boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
              }}
            />
          </div>

          <div
            style={{
              width: '50%',
              padding: 10,
            }}
          >
            {isAssignInputShow ? (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                }}
              >
                <div
                  style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start' }}
                >
                  <p>Assign dentist</p>
                  <img
                    src={crossImage}
                    style={{
                      width: 16,
                      height: 16,
                      marginLeft: '60%',
                    }}
                    onClick={() => {
                      setAssignInputShow(false);
                    }}
                    alt="close"
                  />
                </div>
                <div style={{ width: 250 }}>
                  <UserSearchField
                    user_type="DNT"
                    onSelectUser={(e) => {
                      setAssignInputShow(false);
                      assignOffice(e);
                    }}
                    placeholder="Type a Dentist Name"
                    style={{ width: 250 }}
                  />
                </div>
              </div>
            ) : (
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'center',
                }}
              >
                <strong
                  className="card-title"
                  style={{ color: office.id !== '' ? '#3EB0CA' : '#f4511e' }}
                >
                  {office.id !== '' ? 'Dental Office' : 'No Dental Office'}
                </strong>

                <h4 className="card-text" style={{}}>
                  {office?.office_name}
                </h4>

                <p className="card-text">{getAddress(office?.address || '')}</p>
                {office.id !== '' ? (
                  <Link
                    className="card-text"
                    style={{ marginTop: 10, marginBottom: 10 }}
                    to={{
                      pathname: `/user/dentist/${office.id}`,
                      state: {
                        user_id: office.id,
                        profile: 'DENTIST',
                      },
                    }}
                  >
                    View Profile
                  </Link>
                ) : (
                  <div />
                )}
                <button
                  className="btn btn-success"
                  type="button"
                  onClick={() => setAssignInputShow(true)}
                  style={{ width: 150 }}
                >
                  Assign Dentist
                </button>
              </div>
            )}
          </div>
        </div>
      ) : (
        <div>
          <h3 className="card-title">Not yet filled</h3>
        </div>
      )}
    </div>
  );
};
