import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getReferralTypes,
  updateUserReferralType,
} from '../../../actions/userActions';


class NotificationCard extends Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    const { getReferralTypes, referralTypes } = this.props;

    if (!referralTypes.length) {
      getReferralTypes();
    }
  }

  renderReferralGroup() {
    const { referralTypes, updateUserReferralType } = this.props;

    return (
      <select
        className="custom-select custom-select-lg mb-3"
        style={{ 
          marginTop: '5px', 
          position: 'relative',
        }}
        value={this.props.user_profile?.defaultReferralType}
        onChange={(e) => updateUserReferralType({ defaultReferralType: e.target.value })}
      >
        {referralTypes?.map((type) => (
          <option value={type}>
            {type}
          </option>
        ))}
      </select>
    );
  }

  render() {

    return (
      <div
        style={{
          width: '90%',
          alignSelf: 'center',
        }}
      >
        <div className="card border-0" style={{ marginBottom: 30 }}>
          <h3 className="card-title" style={{ textAlign: 'center' }}>
            Referral Group
          </h3>
          
          {this.renderReferralGroup()}
        </div>
      </div>
    );
  }
}



const mapStateToProps = (state) => {
  if (state.users.currentUser) {
    return {
      user_profile: state.users.currentUser,
      referralTypes: state.users.referralTypes,
    };
  }
  return {};
};

export default connect(mapStateToProps, {
  getReferralTypes,
  updateUserReferralType,
})(NotificationCard);
