import { MULTIDAY_ACTIONS } from '../actions/actionTypes';

const defaultState = {
  curentMDB: null,
  currentMDBJobs: [],
  allJobs: [],
  totalJobs: 0,
  postMultidayData: [],
  isMDBinfoProvided: false,
  totalJobsByMDB: 0,
  totalJobsPostedByMDB: 0,
  searchParams: { page: 1 },
  searchParamsJobs: { page: 1 },
};
export default (state = defaultState, action) => {
  switch (action.type) {
    case MULTIDAY_ACTIONS.FETCH_MULTIDAY_JOBS_REQUEST:
      return {
        ...state,
        searchParams: action.payload,
      };
    case MULTIDAY_ACTIONS.FETCH_MDB_JOBS_BY_ID_REQUEST:
      return {
        ...state,
        searchParamsJobs: action.payload,
      };
    case MULTIDAY_ACTIONS.FETCH_MULTIDAY_JOBS_SUCCESS:
      return {
        ...state,
        allJobs: action.payload.response,
        totalJobs: action.payload.total,
        totalJobsPostedByMDB: action.payload.totalJobsPostedByMDB,
      };

    case MULTIDAY_ACTIONS.FETCH_MDB_BY_ID_SUCCESS:
      return { ...state, curentMDB: action.payload };

    case MULTIDAY_ACTIONS.FETCH_MDB_JOBS_BY_ID_SUCCESS:
      return {
        ...state,
        currentMDBJobs: action.payload.response,
        totalJobsByMDB: action.payload.totalFiltered,
      };

    case MULTIDAY_ACTIONS.PREPARE_DATA_MDB_POST_SUCCESS:
      const jobDates = Object.keys(action.payload.selectedDates);

      const postMultidayData = jobDates
        .map((localDate) => ({ ...action.payload.jobData, localDate }))
        .sort((a, b) => new Date(a.localDate) - new Date(b.localDate));

      return {
        ...state,
        isMDBinfoProvided: true,
        postMultidayData,
      };
    case MULTIDAY_ACTIONS.CANCEL_POSTING_SUCCESS:
    case MULTIDAY_ACTIONS.POST_MULTIDAY_REQUEST:
      return {
        ...state,
        isMDBinfoProvided: false,
      };

    default:
      return state;
  }
};
