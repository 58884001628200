import React from 'react';

const styles = {
  tableTextComponents: {
    fontFamily: 'Nunito',
    margin: 5,
    textAlign: 'center',
    width: '50%',
  },
};

export const EntriesView = ({ transaction }) => (
  <div className="card" style={{ alignSelf: 'center' }}>
    <div
      className="card-body"
      style={{
        padding: 0,
        flex: 1,
        width: '100%',
        alignItems: 'center',
        flexDirection: 'column',
        justifyContent: 'center',
      }}
    >
      <div style={{
        flex: 1,
        display: 'flex',
        flexDirection: 'row',
        alignItems: 'center',
        borderBottom: '1px solid #efefef',
        justifyItems: 'center',
      }}
      >
        <strong style={styles.tableTextComponents}>
          Label
        </strong>
        <strong style={styles.tableTextComponents}>
          Amount
        </strong>
      </div>

      {transaction?.entries?.map((component) => (
        <div
          key={component.id}
          style={{
            flex: 1,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyItems: 'center',
            borderBottom: '1px solid #efefef',
          }}
        >
          <div style={styles.tableTextComponents}>
            {component.label}
          </div>
          <div style={styles.tableTextComponents}>
            {component.label !== 'hygienist-pay' ? `${component.ratio}%` : '-' }
          </div>
          <div style={styles.tableTextComponents}>
            {component.amount ? `$${component.amount.toFixed(2)}` : '-'}
          </div>
        </div>
      ))}

      <div style={{
        display: 'flex', justifyContent: 'space-between', paddingLeft: 10, paddingRight: 10,
      }}
      >
        <strong style={styles.tableTextComponents}>
          Total
        </strong>
        <div style={{ width: '30%' }} />
        <strong style={styles.tableTextComponents}>
          {`$${transaction.total?.toFixed(2)}`}
        </strong>
      </div>
    </div>
  </div>
);
