import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import Styles from '../../themes/style.module.scss';
import { logout } from '../../actions/authActions';
import {
  getAdminRamUser,
  getAdminUser,
  getAllMetadata,
  getCancellationPolicies,
  getDsoNames,
} from '../../actions/userActions';
import {
  getAllProfessions,
  getAllSoftwares,
  getAllSpecializations,
} from '../../actions/jobsAction';
import { fetchPPRecruiter } from '../../actions/ppJobsAction';
import { fetchUsersForMap } from '../../actions/mapActions';
import { fetchSkills } from '../../actions/licensesAction';
import { createLoadingSelector } from '../../apis/selectors';

import deviceTabletIcon from '../../images/device-tablet.svg';
import appleIcon from '../../images/apple_icon.svg';
import logoIcon from '../../images/logo-icon.svg';
import androidIcon from '../../images/android_icon.svg';
import { OpenLinkIcon } from '../../images/open_link';
import { Constant } from '../../ApiRules/ApiRules';

class Dashboard extends Component {
  constructor(props) {
    super(props);
  }

  componentDidMount() {
    const { 
      fetchUsersForMap,
      getAdminUser,
      getAdminRamUser,
      getAllProfessions,
      getAllSpecializations,
      getAllSoftwares,
      fetchSkills,
      getAllMetadata,
      fetchPPRecruiter,
      getDsoNames,
      getCancellationPolicies,
    } = this.props;
    
    fetchUsersForMap();
    getAdminUser();
    getAdminRamUser();
    getAllProfessions();
    getAllSpecializations();
    getAllSoftwares();
    fetchSkills();
    getAllMetadata();
    fetchPPRecruiter();
    getDsoNames();
    getCancellationPolicies();
  }

  logoutClick = () => {
    const { logout } = this.props;
    logout();
  };

  render() {
    const { isRefeshLoading, appVersions } = this.props;

    if (isRefeshLoading) {
      return (
        <div className={Styles.screenView}>
          <div className={Styles.ModalTransparent}>
            <div className="spinner-border text-info d-flex justify-content-center bg-transparent" />
          </div>
        </div>
      );
    }

    return (
      <nav
        className="navbar navbar-expand-md navbar-dark bg-dark h4"
        style={{ marginTop: 0, marginBottom: 0 }}
      >
        <Link className="nav-link" to="/">
          <img
            src={logoIcon}
            className="navbar-brand"
            alt="gotu_logo"
            style={{ width: 70, height: 70 }}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>

        <div className="collapse navbar-collapse" id="navbarText" aria-labelledby="navbarText">
          <ul
            className="navbar-nav"
            style={{
              display: 'flex',
              width: '100%',
              height: '100%',
              justifyContent: 'space-between',
            }}
          >

            <li className="nav-item h4 dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Dashboard
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <Link
                  className="dropdown-item h4"
                  to={{ pathname: '/dash-list' }}
                >
                  List
                </Link>
                <Link
                  className="dropdown-item h4"
                  to={{ pathname: '/dash-metrics' }}
                >
                  Metrics
                </Link>
              </div>
            </li>

            <li className="nav-item h4 dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Users
              </a>
              <div className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <Link
                  className="dropdown-item h4"
                  to={{ pathname: '/dentist', state: { profile: 'DNT' } }}
                >
                  Dentist
                </Link>
                <Link
                  className="dropdown-item h4"
                  to={{ pathname: '/dso', state: { profile: 'DNT' } }}
                >
                  DSO Offices
                </Link>
                <Link
                  className="dropdown-item h4"
                  to={{ pathname: '/hygienist', state: { profile: 'HYG' } }}
                >
                  Professionals
                </Link>

                <Link className="dropdown-item h4" to="/unfinished">
                  Unfinished users
                </Link>
              </div>
            </li>
            <li className="nav-item h4 dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="jobsId"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                Active Offers
              </a>
              <div className="dropdown-menu" aria-labelledby="jobsId">
                <Link className="dropdown-item h4" to="/multiday">
                  Multiday
                </Link>

                <Link className="dropdown-item h4" to="/jobs">
                  Temp Shifts
                </Link>
                <Link className="dropdown-item h4" to="/pp-jobs">
                  Permanent Jobs
                </Link>
              </div>
            </li>
            <li className="nav-item h4">
              <Link className="nav-link" to="/licenses">
                Licenses
              </Link>
            </li>

            <li className="nav-item h4">
              <Link className="nav-link" to="/sms">
                SMS Campaign
              </Link>
            </li>
            <li className="nav-item h4">
              <Link className="nav-link" to="/messages">
                Messages
              </Link>
            </li>
            <li className="nav-item h4">
              <Link className="nav-link" to="/referral">
                Referral
              </Link>
            </li>
            <div style={{ flex: 0.5 }} />
            <li className="nav-item h4 dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="appVersion"
                data-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
                style={{
                  position: 'relative',
                  display: 'inline-block',
                  paddingLeft: 35,
                }}
              >
                <img
                  src={deviceTabletIcon}
                  style={{
                    position: 'absolute',
                    left: 0,
                    top: '50%',
                    transform: 'translateY(-50%)',
                    marginRight: 5,
                    objectFit: 'contain',
                  }}
                  alt="app_version"
                />
                App Version
              </a>
              <div className="dropdown-menu dropdown-menu-right" aria-labelledby="appVersion">
                <a
                  href={Constant.IOS_APP_URL}
                  target="_blank"
                  className="dropdown-item h4"
                  style={{ display: 'flex', alignItems: 'center' }}
                  rel="noreferrer"
                >
                  <img
                    src={appleIcon}
                    style={{ objectFit: 'contain', marginRight: 7, alignSelf: 'center' }}
                    alt="apple_icon"
                  />
                  iOS Version:
                  <span style={{ marginLeft: 3, color: appVersions?.ios ? null : '#4FC0CF' }}>
                    {appVersions?.ios || 'Click Here'}
                  </span>
                  <OpenLinkIcon fill="#4FC0CF" style={{ marginLeft: 10 }} />
                </a>
                <a
                  href={Constant.ANDROID_APP_URL}
                  target="_blank"
                  className="dropdown-item h4"
                  style={{ display: 'flex', alignItems: 'center' }}
                  rel="noreferrer"
                >
                  <img
                    src={androidIcon}
                    style={{ objectFit: 'contain', marginRight: 7 }}
                    alt="android_icon"
                  />
                  Android Version:
                  <span style={{ marginLeft: 3, color: appVersions?.android ? null : '#4FC0CF' }}>
                    {appVersions?.android || 'Click Here'}
                  </span>
                  <OpenLinkIcon fill="#4FC0CF" style={{ marginLeft: 10 }} />
                </a>
              </div>
            </li>
            <li className="nav-item h4" style={{ right: 50 }}>
              <Link to="/" className="nav-link" onClick={() => this.logoutClick()}>
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </nav>
    );
  }
}

const refreshLoadingSelector = createLoadingSelector(['AUTH_REFRESH']);
const mapStateToProps = (store) => ({
  isRefeshLoading: refreshLoadingSelector(store),
  appVersions: store.users.allMetadata?.appVersions,
});

export default connect(mapStateToProps, { 
  logout, 
  fetchUsersForMap,
  getAdminUser,
  getAdminRamUser,
  getAllProfessions,
  getAllSpecializations,
  getAllSoftwares,
  fetchSkills,
  getAllMetadata,
  fetchPPRecruiter,
  getDsoNames,
  getCancellationPolicies,
})(Dashboard);
