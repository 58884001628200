import Alert from 'react-s-alert';
import { SHOW_MESSAGE } from '../actions/actionTypes';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

export default (state = { message: null }, action) => {
  const { type } = action;
  const matches = /(.*)_(FAILURE)/.exec(type);
  if (matches) {
    const errors = action.payload.response?.data?.errors || [];
    const message = errors.map((err) => err.message).join('\n');
    Alert.error(message, {
      position: 'bottom-right',
      effect: 'slide',
      beep: false,
      timeout: 5000,
      offset: 100,
    });
    return state;
  }

  switch (action.type) {
    case SHOW_MESSAGE:
      const { message } = action.payload;
      Alert.success(message, {
        position: 'bottom-right',
        effect: 'slide',
        beep: false,
        timeout: 5000,
        offset: 100,
      });
      break;
    default:
      break;
  }

  return state;
};
