import React, { useEffect, useState } from 'react';
import '../../themes/InputNumber.scss';
import useDebounce from '../../hooks/useDebounce';

const InputNumber = ({
  value,
  onChange,
  min,
  max,
  step = 1,
  style,
  className,
  autoApply = true,
  debounceTime = 500,
}) => {
  const [inputValue, setInputValue] = useState(value);

  const handleDecrease = () => {
    const newValue = (parseInt(inputValue, 10) || 0) - step;
    const adjustedValue = min && newValue < min ? min : newValue;
    setInputValue(adjustedValue);
  };

  const handleIncrease = () => {
    const newValue = (parseInt(inputValue, 10) || 0) + step;
    const adjustedValue = max && newValue > max ? max : newValue;
    setInputValue(adjustedValue);
  };

  const handleOnInput = (e) => {
    const newValue = parseInt(e.target.value, 10);

    if (min && newValue < min) {
      setInputValue(min);
    } else if (max && newValue > max) {
      setInputValue(max);
    } else {
      setInputValue(newValue);
    }
  };

  const emitOnChange = (options) => {
    if (onChange) {
      onChange(options);
    }
  };

  const emitOnChangeDebounced = useDebounce(emitOnChange, debounceTime);

  useEffect(() => {
    if (autoApply) {
      emitOnChangeDebounced(inputValue);
    }
  }, [inputValue]);

  return (
    <div className="input-number-wrapper">
      <div className={`input-number form-control ${className}`} style={style}>
        <button type="button" className="minus" onClick={handleDecrease}>
          -
        </button>
        <input type="number" value={inputValue} onChange={handleOnInput} />
        <button type="button" className="plus" onClick={handleIncrease}>
          +
        </button>
      </div>
      {autoApply ? null : (
        <button
          disabled={Number.isNaN(inputValue) || inputValue === value}
          type="button"
          className="btn btn-info mt-2 mb-2 ml-3"
          onClick={() => emitOnChange(inputValue)}
        >
          Apply
        </button>
      )}
    </div>
  );
};

export default InputNumber;
