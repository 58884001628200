import React from 'react';
import GreenStar from '../../../../images/star_Green.png';

const RatingCard = ({ title, name, averageRating, ratingItems = [], feedback }) => (
  <div
    className="card"
    style={{
      width: 200,
      minHeight: 430,
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
      gap: 10,
      paddingBlock: 40,
      paddingInline: 23,
    }}
  >
    <p className="f-14 bold" style={{ color: '#307F92' }}>
      {title}
    </p>
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: 10,
        fontSize: 20,
        fontWeight: 400,
      }}
    >
      <p style={{ flex: 0.6, wordBreak: 'break-word' }}>{name}</p>
      <p style={{ flex: 0.4, textAlign: 'right' }}>
        {averageRating ? (
          <>
            AVG{' '}
            <img
              src={GreenStar}
              className="card-img-left"
              width="24px"
              alt="star_icon"
              style={{ marginBottom: 10 }}
            />{' '}
            {averageRating?.toFixed(1)}
          </>
        ) : null}
      </p>
    </div>
    <div style={{ marginTop: 6 }}>
      {ratingItems.map((item) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            fontSize: 16,
            fontWeight: 600,
            paddingBlockStart: 10,
            borderBottom: '1px solid #E9F1F4',
          }}
        >
          <p>{item.label}</p>
          <p>{item.value}</p>
        </div>
      ))}
    </div>
    <div className="mt-3">
      <p className="f-16" style={{ fontWeight: 600 }}>
        Confidential Feedback for GoTu
      </p>
      <span className="f-14">{feedback?.trim() || 'N/A'}</span>
    </div>
  </div>
);

export default RatingCard;
