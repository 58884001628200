import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMultidayById, fetchMultidayJobsById } from '../../../../actions/multidayActions';
import { createLoadingSelector } from '../../../../apis/selectors';
import { Table } from '../../../commonComponents/Table/Table';
import {
  jobListConditionalRowStyles,
  userJobListColumns,
} from '../../../commonComponents/jobs-column';
import MultidayGeneralInfo from './MultidayGeneralInfo';

const ROWS_PER_PAGE = 15;

const actions = ['FETCH_MDB_BY_ID', 'FETCH_MDB_JOBS_BY_ID'];
const loadingSelector = createLoadingSelector(actions);

export const MultidayJobs = ({ history, match }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector((state) => loadingSelector(state));
  const curentMDB = useSelector((state) => state.multiday.curentMDB);
  const curentMDBJobs = useSelector((state) => state.multiday.currentMDBJobs);
  const searchParamsJobs = useSelector((state) => state.multiday.searchParamsJobs);
  const totalJobsByMDB = useSelector((state) => state.multiday.totalJobsByMDB);

  const totalPages = Math.ceil(totalJobsByMDB / ROWS_PER_PAGE);

  const [filters, setFilters] = useState({
    page: searchParamsJobs.page || 1,
    limit: searchParamsJobs.sizePerPage || 15,
    mdb: searchParamsJobs.mdb || null,
  });

  const multidayId = history.location.state
    ? history.location.state.multidayId
    : match.params.multidayId;

  useEffect(() => {
    dispatch(
      fetchMultidayJobsById(multidayId, {
        page: filters.mdb === multidayId ? filters?.page : 1,
      }),
    );

    dispatch(fetchMultidayById(multidayId));
  }, [dispatch, multidayId, filters]);

  const getJobUrl = (job) => `/job/${job.id}`;

  const getUserUrl = (userId, userType) => `/user/${userType.toLowerCase()}/${userId}`;

  const getColumn = () =>
    userJobListColumns({
      getJobUrl,
      getUserUrl,
      sortable: false,
    });

  const handleOnSort = (selectedColumn, sortDirection) => {
    const sortObj = {
      page: filters.page,
      sortField: selectedColumn.sortField,
      order: sortDirection,
    };
    setFilters((prevState) => ({
      ...prevState,
      ...sortObj,
    }));
  };

  const handleTableChange = (page) => {
    dispatch(fetchMultidayJobsById(multidayId, { ...filters, page }));
  };

  if (isLoading || !curentMDB || curentMDBJobs.length === 0 || multidayId !== curentMDB?.id) {
    return (
      <div
        style={{
          display: 'flex',
          marginTop: 30,
          justifyContent: 'center',
        }}
      >
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 30,
            width: 30,
            marginLeft: 20,
            marginTop: 10,
          }}
        />
      </div>
    );
  }

  return (
    <div
      style={{
        height: '100vh',
        overflowY: 'scroll',
        width: '100vw',
      }}
    >
      <div className="card border-0">
        <div
          className="card-body"
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
            alignItems: 'center',
            width: '100%',
            height: '100%',
            marginBottom: 100,
          }}
        >
          <MultidayGeneralInfo curentMDB={curentMDB} />
          <h3
            className="card-title"
            style={{ fontSize: '24px', textAlign: 'center', marginBottom: 10 }}
          >
            Multi-Day Jobs
          </h3>
          <div style={{ width: '90%' }}>
            <Table
              keyField="multidayJobs.id"
              columns={getColumn()}
              data={curentMDBJobs}
              noDataComponent="There are no jobs"
              isLoading={isLoading}
              totalPages={totalPages}
              onSort={handleOnSort}
              showTotal={false}
              showColumnsToHide={false}
              paginationPerPage={ROWS_PER_PAGE}
              paginationTotalRows={totalJobsByMDB}
              onPageChange={handleTableChange}
              conditionalRowStyles={jobListConditionalRowStyles}
            />
          </div>
        </div>
      </div>
    </div>
  );
};
