import React, { useEffect, useState } from 'react';

const styles = {
  titleText: {
    fontWeight: 'bold',
    fontFamily: 'Nunito',
    fontSize: 18,
    marginRight: 10,
  },
  descriptionText: {
    fontFamily: 'Nunito',
    fontSize: 16,
  },
};

export default ({ application }) => (
  <div style={{ width: '40%', marginTop: 10, marginBottom: 10 }}>

    <div style={{ display: 'flex' }}>
      <div
        className="card-text"
        style={styles.titleText}
      >
        Name:
        {' '}
      </div>
      <div style={styles.descriptionText}>
        {application.professional.first_name}
        {' '}
        {application.professional.last_name}
      </div>
    </div>

    <div style={{ display: 'flex' }}>
      <div
        className="card-text"
        style={styles.titleText}
      >
        Email:
        {' '}
      </div>
      <div style={styles.descriptionText}>
        {application.professional.email_id}
      </div>
    </div>
    <div style={{ display: 'flex' }}>

      <div
        className="card-text"
        style={styles.titleText}
      >
        Phone:
        {' '}

      </div>
      <div style={styles.descriptionText}>
        {application.professional.contact_number}
      </div>
    </div>
  </div>
);
