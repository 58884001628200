import {
  FETCH_JOBS_SUCCESS,
  FETCH_JOBS_REQUEST,
  RELOAD_JOBS_SUCCESS,
  RELOAD_JOBS_REQUEST,
  EDIT_JOB_SUCCESS,
  FETCH_JOB_SUCCESS,
  ACCEPT_JOB_SUCCESS,
  CANCEL_JOB_SUCCESS,
  REFUND_JOB_SUCCESS,
  DELETE_HYG_FROM_OFFER_SUCCESS,
  CREATE_JOB_SUCCESS,
  CLEAR_DATE_RANGE_JOBS_SUCCESS,
  ADJUSTMENT_CHANGE_SUCCESS,
  GET_ALL_PROFESSIONS_SUCCESS,
  PAYOUT_JOB_SUCCESS,
  REPORT_ACTIONS,
  JOB_ACTION,
  PAYMENT_METHODS_ACTIONS,
  GET_ALL_SPECIALIZATIONS_SUCCESS,
  GET_ALL_SOFTWARES_SUCCESS,
  DISABLE_PAYOUT_SUCCESS,
  ENABLE_AUTO_REFUND_SUCCESS,
  STOP_ADDITIONAL_CHARGES_SUCCESS,
  STOP_INVOICES_SUCCESS, FETCH_JOB_VIEWS_SUCCESS,
  FETCH_JOB_VIEWS_FROM_JOB_VIEW_SERVICE_SUCCESS,
} from '../actions/actionTypes';

const defaultState = {
  jobs: [],
  total: 0,
  currentJob: null,
  usersArround: [],
  totalFiltered: 0,
  totalComplete: 0,
  rating: {},
  searchParams: {
    page: 1,
    sizePerPage: 15,
    searchText: '',
    sortField: 'createdAt',
    sortOrder: 'desc',
    jobStatus: 'all',
    startDate: null,
    endDate: null,
    dsoName: null,
    dsoManager: null,
    dsoRamIds: null,
  },
  allProfessions: [],
  allSpecializations: [],
  taxReport: '',
  jobsReport: '',
  jobViews: 0, // different from views - fetched from job-view-service
};

export default (state = defaultState, action) => {
  switch (action.type) {
  case FETCH_JOBS_SUCCESS:
  case RELOAD_JOBS_SUCCESS:
    if (!action.payload.response) {
      return state;
    }
    const jobs = action.payload.response.map((job) => {
      job.offer_final_reciever
        ? (job.hygienist_name = `${job.offer_final_reciever.first_name} ${job.offer_final_reciever.last_name}`)
        : (job.hygienist_name = 'N/A');
      return job;
    });

    return {
      ...state,
      jobs,
      page: action.payload.page,
      totalFiltered: action.payload.totalFiltered,
      total: action.payload.total,
      totalComplete: action.payload.totalComplete,
    };
  case REPORT_ACTIONS.FETCH_USER_TAX_REPORT_SUCCESS:
    return {
      ...state,
      taxReport: action.payload,
    };
  case GET_ALL_PROFESSIONS_SUCCESS:
    return {
      ...state,
      allProfessions: action.payload,
    };
  case GET_ALL_SPECIALIZATIONS_SUCCESS:
    return {
      ...state,
      allSpecializations: action.payload,
    };
  case GET_ALL_SOFTWARES_SUCCESS:
    return {
      ...state,
      allSoftwares: action.payload,
    };

  case FETCH_JOB_SUCCESS: {
    const response = action.payload;

    return {
      ...state,
      currentJob: {
        ...response.job,
        transactionsPaymentService: response.transactionsPaymentService,
      },
      usersArround: response.users_around,
      rating: response.rating,
    };
  }
  case FETCH_JOB_VIEWS_SUCCESS: {
    const response = action.payload;

    return {
      ...state,
      views: response.views
    }
  }
  case FETCH_JOB_VIEWS_FROM_JOB_VIEW_SERVICE_SUCCESS: {

    return {
      ...state,
      jobViews: action.payload.views
    }
  }
  case ADJUSTMENT_CHANGE_SUCCESS:
    const { status, adjId } = action.payload;
    const adjIndex = state.currentJob.adjustments.findIndex(
      (adjustment) => adjustment.id === adjId,
    );

    if (adjIndex === -1) {
      return state;
    }

    return {
      ...state,
      currentJob: {
        ...state.currentJob,
        adjustments: [
          ...state.currentJob.adjustments.map((el, index) => {
            if (index === adjIndex) {
              return { ...el, status };
            }
            return el;
          }),
        ],
      },
    };
  case FETCH_JOBS_REQUEST:
  case RELOAD_JOBS_REQUEST:
    return {
      ...state,
      searchParams: action.payload,
      jobsReport: '',
    };
  case REPORT_ACTIONS.FETCH_JOBS_REPORT_SUCCESS:
    return {
      ...state,
      jobsReport: action.payload,
    };
  case CREATE_JOB_SUCCESS:
  case ACCEPT_JOB_SUCCESS:
  case DISABLE_PAYOUT_SUCCESS:
  case ENABLE_AUTO_REFUND_SUCCESS:
  case STOP_ADDITIONAL_CHARGES_SUCCESS:
  case STOP_INVOICES_SUCCESS:
  case EDIT_JOB_SUCCESS:
  case CANCEL_JOB_SUCCESS:
  case JOB_ACTION.CONFIRM_JOB_SUCCESS:
  case REFUND_JOB_SUCCESS:
  case JOB_ACTION.DECLINE_COUINTER_OFFER_SUCCESS:
  case PAYMENT_METHODS_ACTIONS.CREATE_INSTANT_PAYOUT_SUCCESS:
  case PAYMENT_METHODS_ACTIONS.WAIVE_INSTANT_PAYOUT_SUCCESS:
  case PAYMENT_METHODS_ACTIONS.DELETE_INSTANT_TRANSACTION_SUCCESS:
  case PAYMENT_METHODS_ACTIONS.CREATE_MARK_JOB_PAID_OUT_SUCCESS:
  case PAYOUT_JOB_SUCCESS:

    return {
      ...state,
      currentJob: action.payload,
    };
  case PAYMENT_METHODS_ACTIONS.UPDATE_TRANSACTION_STATUS_SUCCESS:
    return {
      ...state,
      currentJob: {
        ...state.currentJob,
        transactionsPaymentService: state.currentJob.transactionsPaymentService.map((transaction) => {
          if (transaction.id === action.payload) {
            return {...transaction, status: 'paid'};
          }
          return transaction;
        }),
      },
    };
      
  case PAYMENT_METHODS_ACTIONS.DELETE_INVOICE_SUCCESS:
    const invoiceId = action.payload;

    return {
      ...state,
      currentJob: {
        ...state.currentJob,
        transactions: state.currentJob.transactions.filter(
          ({ transaction_id }) => transaction_id !== invoiceId,
        ),
      },
    };

  case PAYMENT_METHODS_ACTIONS.SEND_JOB_COUNTER_COVER_SUCCESS:
    return {
      ...state,
      currentJob: {
        ...state.currentJob,
        counterCover: action.payload.counterCover
      },
    };

  case PAYMENT_METHODS_ACTIONS.REMOVE_JOB_COUNTER_COVER_SUCCESS:
    return {
      ...state,
      currentJob: {
        ...state.currentJob,
        counterCover: null
      },
    };

  case CLEAR_DATE_RANGE_JOBS_SUCCESS:
    return {
      ...state,
      searchParams: {
        ...state.searchParams,
        startDate: null,
        endDate: null,
      },
    };
  case DELETE_HYG_FROM_OFFER_SUCCESS:
    return {
      ...state,
      currentJob: {
        ...state.currentJob,
        offer_counters: state.currentJob.offer_counters.filter(
          (el) => el.requestor.id !== action.payload,
        ),
      },
    };

  case PAYMENT_METHODS_ACTIONS.MARK_INVOICE_AS_PAID_SUCCESS:
    const updatedTransaction = action.payload;
    const trIndex = state.currentJob.transactions.findIndex(
      (transaction) => transaction.transaction_id === updatedTransaction.transaction_id,
    );
    if (trIndex === -1) {
      return state;
    }

    return {
      ...state,
      currentJob: {
        ...state.currentJob,
        transactions: [
          ...state.currentJob.transactions.slice(0, trIndex),
          updatedTransaction,
          ...state.currentJob.transactions.slice(trIndex + 1),
        ],
      },

    };
  case JOB_ACTION.REMOVE_JOB_UNAVAILABLE_USERS_SUCCESS: {
    const usersIds = action.payload;
    const newUnavailable = state.currentJob.offer_unavailable.filter(
      (user) => !usersIds.includes(user.id),
    );
    return {
      ...state,
      currentJob: {
        ...state.currentJob,
        offer_unavailable: newUnavailable,
      },
    };
  }
  default:
    return state;
  }
};
