interface TabScreenProps {
  component: JSX.Element;
  name: string; // identifier for TabNavigator
  options?: {
    title?: string, // title for TabNavigator
  };
}

const TabScreen = ({ component }: TabScreenProps) => <>{component}</>;

TabScreen.displayName = 'TabScreen';
export default TabScreen;
