import React from 'react';
import dateFormat from 'dateformat';

export default ({ adjustmentList, btnAdjClicked }) => {
  if (adjustmentList?.length === 0) {
    return (
      <div
        style={{
          minHeight: 200,
          maxHeight: 500,
          overflowY: 'auto',
          borderBottom: '1px solid #efefef',
        }}
      >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <span
            style={{
              fontFamily: 'Nunito',
              fontSize: 18,
              alignText: 'center',
            }}
          >
            No Adjustments
          </span>
        </div>
      </div>
    );
  }

  const getColor = (status) => {
    if (status === 'declined') {
      return 'red';
    }
    if (status === 'applied') {
      return 'green';
    }
    return 'black';
  };

  return (
    <div
      style={{
        maxHeight: 500,
        overflowY: 'auto',
        borderBottom: '1px solid #efefef',
      }}
    >
      {adjustmentList?.map((adjustment) => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyItems: 'center',
            paddingLeft: 10,
            margin: 10,
            border: '1px solid #efefef',
            borderRadius: 25,
          }}
          key={adjustment.id}

        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              marginLeft: 20,
              marginTop: 10,
              width: '100%',
            }}
          >
            <span
              style={{
                fontFamily: 'Nunito',
                width: '30%',
                textAlign: 'center',
              }}
            >
              {`${adjustment.author.first_name} ${adjustment.author.last_name}`}
            </span>

            <span
              style={{
                fontFamily: 'Nunito',
                width: '25%',
                textAlign: 'center',

              }}
            >
              {dateFormat(adjustment.createdAt, 'ddd, mmmm dS, yyyy h:MM:ss TT') || ''}
            </span>

            <span
              style={{
                fontFamily: 'Nunito',
                width: '10%',
                textAlign: 'center',
                textDecoration: 'line-through',

              }}
            >
              {adjustment.original_start}
            </span>
            <span
              style={{
                fontFamily: 'Nunito',
                width: '10%',
                textAlign: 'center',
              }}
            >
              {adjustment.start}
            </span>
            <span
              style={{
                fontFamily: 'Nunito',
                width: '10%',
                textAlign: 'center',
                textDecoration: 'line-through',

              }}
            >
              {adjustment.original_finish}
            </span>
            <span
              style={{
                fontFamily: 'Nunito',
                width: '10%',
                textAlign: 'center',
              }}
            >
              {adjustment.finish}
            </span>
            <span
              style={{
                fontFamily: 'Nunito',
                width: '10%',
                textAlign: 'center',
                textDecoration: 'line-through',
              }}
            >
              {adjustment.original_break}
            </span>

            <span
              style={{
                fontFamily: 'Nunito',
                width: '10%',
                textAlign: 'center',
              }}
            >
              {adjustment.break}
            </span>
            <span
              style={{
                fontFamily: 'Nunito',
                width: '10%',
                textAlign: 'center',
                fontWeight: 'bold',
                color: getColor(adjustment.status),
              }}
            >
              {adjustment.status}
            </span>

            {adjustment.status === 'none' ? (
              <div style={{ width: '28%' }}>
                <div
                  className="btn btn-success"
                  style={{
                    fontFamily: 'Nunito',
                  }}
                  onClick={() => btnAdjClicked(adjustment.id, 'applied')}
                >
                  Apply
                </div>

                <div
                  className="btn btn-danger ml-1"
                  style={{
                    fontFamily: 'Nunito',
                  }}
                  onClick={() => btnAdjClicked(adjustment.id, 'declined')}
                >
                  Reject
                </div>
              </div>
            ) : (
              <div style={{ width: '28%' }} />
            )}
          </div>
          
          <div className='d-flex w-100'>
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 20,
                marginBottom: 20,
                width: '100%',
                
              }}
            >
              <span style={{ fontFamily: 'Nunito', fontWeight: 700 }}>Message</span>
              <span
                style={{
                  fontFamily: 'Nunito',
                }}
              >
                {adjustment.message}
              </span>
            </div>
            <div
              className="col-md-6 col-sm-12"
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'center',
                marginTop: 20,
                marginBottom: 20,
                width: '100%',

              }}
            >
              <span style={{ fontFamily: 'Nunito', fontWeight: 700 }}>Dispute Reason</span>
              <span
                style={{
                  fontFamily: 'Nunito',
                }}
              >
                {adjustment.disputeReason}
              </span>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
