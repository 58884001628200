import React from 'react';
import { CSVLink } from 'react-csv';

export default ({
  array, title, currentJob, redirectToProfile, redirectToSms,
}) => {
  const availableHygienistChart = () => (
    <div style={{ height: 200, overflowY: 'auto', borderBottom: '1px solid #efefef' }}>
      {array.length > 0 ? (
        array.map((hyg) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyItems: 'center',
              marginLeft: 10,
              marginRight: 10,
            }}
          >
            <div
              key={hyg.id}
              style={{
                display: 'flex',
                alignItems: 'center',
                border: '1px solid #efefef',
                borderRadius: 25,
                marginTop: 10,
                cursor: 'pointer',
                width: '100%',
              }}
              onClick={() => redirectToProfile(hyg.id)}
            >
              <p
                style={{
                  fontFamily: 'Nunito',
                  margin: 0,
                  padding: '10px 10px',
                }}
              >
                {`${hyg.first_name} ${hyg.last_name}`}
              </p>
            </div>
          </div>
        ))
      ) : (
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <p style={{ fontFamily: 'Nunito', fontSize: 18, alignText: 'center' }}>No Activity</p>
        </div>
      )}
    </div>
  );
  const displayTitleColor = () => {
    if (title === 'Declined') {
      return 'red';
    }
    if (title === 'Ignored') {
      return 'orange';
    }
    if (title === 'More Details') {
      return 'blue';
    }
    if (title === 'Seen') {
      return '#3EB0CA';
    }
    return '#aeea00';
  };
  const headers = [
    { label: 'First Name', key: 'first_name' },
    { label: 'Last Name', key: 'last_name' },
  ];

  return (
    <div
      className="card mt-5"
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '56%',
        justifyContent: 'center',
      }}
    >
      <div
        className="card-body"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2
          className="card-title"
          style={{ marginLeft: 10, fontFamily: 'Nunito', color: '#494963' }}
        >
          {title}
        </h2>
        <div
          style={{
            backgroundColor: displayTitleColor(),
            height: 40,
            width: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 20,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <h3
            className="card-title"
            style={{
              color: '#fff',
              margin: 0,
              padding: 0,
            }}
          >
            {array.length}
          </h3>
        </div>
        {
          redirectToSms ? (
            <button
              type="button"
              className="btn btn-success"
              onClick={redirectToSms}
              style={{
                width: 150,
                height: 30,
                borderRadius: 5,
                marginTop: 5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '1px 1px 4px',
              }}
            >
              SMS Blast
            </button>
          ) : <div style={{ minWidth: 100 }} />
        }
      </div>
      <div
        style={{
          display: 'flex',
          borderBottom: '1px solid #ededed',
          flex: 1,
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <div>
          <p style={{ fontFamily: 'Nunito', fontWeight: 700 }}>Name</p>
        </div>
      </div>
      {availableHygienistChart()}
    </div>
  );
};
