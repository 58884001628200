import React from 'react';
import AdjustRenderData from './AdjustRenderData';

export default ({
  adjustmentList, btnAdjClicked, showAdjustmentButton, showAdjustmentPopup,
}) => (
  <div
    className="card mt-5"
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: '50px',
      width: '80%',
    }}
  >
    <div
      className="card-body"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <h2 className="card-title" style={{ marginLeft: 10, fontFamily: 'Nunito', color: '#494963' }}>
        Adjustments
      </h2>
      <div style={{
        display: 'flex',
      }}
      >
        {
          showAdjustmentButton && (
            <button
              type="button"
              className="btn btn-info"
              onClick={showAdjustmentPopup}
              style={{
                width: 150,
                height: 30,
                borderRadius: 5,
                marginTop: 5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '1px 1px 4px',
              }}
            >
              Submit Adjusment
            </button>
          )
        }
        <div
          style={{
            backgroundColor: '#7e3737',
            height: 40,
            width: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 20,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <h3
            className="card-title"
            style={{
              color: '#fff',
              margin: 0,
              padding: 0,
            }}
          >
            {adjustmentList.length}
          </h3>
        </div>
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        borderBottom: '1px solid #ededed',
        width: '100%',
        marginLeft: 20,
        marginRight: 20,
      }}
    >
      <span style={{
        fontFamily: 'Nunito', fontWeight: 700, textAlign: 'center', width: '30%',
      }}
      >
        Author
      </span>
      <span style={{
        fontFamily: 'Nunito', fontWeight: 700, textAlign: 'center', width: '25%',
      }}
      >
        Created At
      </span>

      <span style={{
        fontFamily: 'Nunito', fontWeight: 700, textAlign: 'center', width: '10%',
      }}
      >
        Start
      </span>

      <span style={{
        fontFamily: 'Nunito', fontWeight: 700, textAlign: 'center', width: '10%',
      }}
      >
        New Start
      </span>

      <span style={{
        fontFamily: 'Nunito', fontWeight: 700, textAlign: 'center', width: '10%',
      }}
      >
        End
      </span>

      <span style={{
        fontFamily: 'Nunito', fontWeight: 700, textAlign: 'center', width: '10%',
      }}
      >
        New End
      </span>

      <span style={{
        fontFamily: 'Nunito', fontWeight: 700, textAlign: 'center', width: '10%',
      }}
      >
        Break
      </span>

      <span style={{
        fontFamily: 'Nunito', fontWeight: 700, textAlign: 'center', width: '10%',
      }}
      >
        New Break
      </span>

      <span style={{
        fontFamily: 'Nunito', fontWeight: 700, textAlign: 'center', width: '10%',
      }}
      >
        Status
      </span>
      <div style={{ width: '30%' }} />
    </div>
    <AdjustRenderData adjustmentList={adjustmentList} btnAdjClicked={btnAdjClicked} />
  </div>
);
