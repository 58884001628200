import React, { useEffect } from 'react';
import Alert from 'react-s-alert';
import { useDispatch } from 'react-redux';
import { signInWithGoogle } from '../../actions/authActions';
import { useLocation } from 'react-router-dom/cjs/react-router-dom';

function SSOCallbackHandler() {
  const location = useLocation();
  const dispatch = useDispatch();

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const errorMessage = params.get('error');
    const token = params.get('token');

    if (errorMessage) {
      Alert.error(errorMessage, {
        position: 'bottom-right',
        effect: 'slide',
        beep: false,
        timeout: 5000,
        offset: 100,
      });
    }

    if (token) {
      dispatch(signInWithGoogle(token));
    }
  }, [location]);

  return null;
}

export default SSOCallbackHandler;
