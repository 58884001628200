import React from 'react';

const AdjustmentRowItem = ({ leftComponent, rightComponent, direction = 'row' }) => (
  <div
    style={{
      width: '100%',
      display: 'flex',
      flexDirection: direction,
      justifyContent: 'center',
      gap: direction === 'row' ? 15 : 0,
    }}
  >
    <div style={{ flex: 1 }}>{leftComponent}</div>
    <div style={{ flex: 1 }}>{rightComponent}</div>
  </div>
);

export default AdjustmentRowItem;
