import { requiredCredentialOptions } from '../../../../constants';
import MultiSelect from '../../../commonComponents/MultiSelect';

export const HasRequiredCredential = ({ filter, handleFilterChange }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      minWidth: 140,
    }}
  >
    <p>Has Required Credential:</p>
    <MultiSelect
      className="custom-select custom-select-lg"
      style={{ position: 'relative', minWidth: 140 }}
      value={filter?.requiredCredentials ? filter?.requiredCredentials.split(',') : requiredCredentialOptions.map((el) => el.value)}
      options={requiredCredentialOptions}
      onChange={(values) => {
        handleFilterChange(
          'requiredCredentials',
          values.length === requiredCredentialOptions.length ? null : values.join(','),
        );
      }}
      placeholder="All"
      showAllButton
      autoApply={false}
    />
  </div>
);
