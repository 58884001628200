import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getAdminRamUser } from '../../../../actions/userActions';
import { DNT_FILTERS } from '../utils';
import { CustomSelect } from './CustomSelect';
import { RamFilter } from './RamFilter';
import { DsoNameFilter } from './DsoNameFilter';
import { DsoRAMFilter } from './DsoRamFilter';

export const DNTCustomFilters = ({ filter, handleFilterChange }) => {
  const dispatch = useDispatch();
  const dsoManagerList = useSelector((state) => state.users.dsoManagers);

  useEffect(() => {
    if (!dsoManagerList) {
      dispatch(getAdminRamUser());
    }
  }, [dsoManagerList, dispatch]);

  return (
    <>
      <RamFilter filter={filter} handleFilterChange={handleFilterChange} />

      <DsoNameFilter filter={filter} handleFilterChange={handleFilterChange} />

      <DsoRAMFilter filter={filter} handleFilterChange={handleFilterChange} />

      {DNT_FILTERS({ dsoManagerList })?.map(({ key, label, options }) => (
        <CustomSelect
          key={key}
          id={key}
          label={label}
          options={options}
          value={filter[key]}
          onChange={handleFilterChange}
        />
      ))}
    </>
  );
};
