import { useEffect, useState } from 'react';
import DataTable from 'react-data-table-component';
import styled from 'styled-components';
import { ButtonGroup } from '../ButtonGroup';
import { Pagination } from './Pagination';

const CustomDataTable = styled(DataTable)`
  [role='columnheader'] > div {
    white-space: normal;
    overflow: visible;
  }
`;

export const Table = ({
  filter,
  keyField,
  columns,
  data,
  noDataComponent,
  isLoading,
  totalPages,
  paginationPerPage,
  paginationTotalRows,
  onPageChange,
  conditionalRowStyles,
  CustomHeaderComponent,
  onSort,
  fixedHeader,
  onRowClicked,
  selectableRows,
  onSelectedRowsChange,
  selectableRowDisabled,
  resetPage,
  initialPage = 1,
  sortServer = true,
  showTotal = true,
  showColumnsToHide = true,
  forceLoading = false,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [hideColumns, setHideColumns] = useState([]);

  useEffect(() => {
    setCurrentPage(+initialPage || 1);
  }, [initialPage, resetPage]);

  const handlePageChange = (page) => {
    setCurrentPage(page);
    onPageChange(page);
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          alignItems: 'flex-start',
        }}
      >
        {showColumnsToHide ? (
          <ButtonGroup
            options={columns
              .filter((column) => !column.omit)
              .map((column) => ({ value: column.name, text: column.name }))}
            onClick={setHideColumns}
          />
        ) : (
          <div />
        )}

        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'end',
          }}
        >
          {showTotal && (
            <span
              style={{
                fontSize: 20,
                fontWeight: 'bold',
                color: '#435963',
              }}
            >
              Total | {paginationTotalRows !== undefined ? Intl.NumberFormat().format(paginationTotalRows) : ''}
            </span>
          )}
          {CustomHeaderComponent ? <CustomHeaderComponent /> : null}
        </div>
      </div>

      <hr />

      <CustomDataTable
        keyField={keyField}
        columns={columns
          .filter((column) => !column.omit)
          .map((column) => ({
            ...column,
            omit: hideColumns.includes(column.name),
          }))}
        data={data}
        conditionalRowStyles={conditionalRowStyles}
        noDataComponent={noDataComponent}
        progressPending={isLoading && (data?.length === 0 || forceLoading)}
        progressComponent={<span style={{ marginTop: 20 }}>Loading...</span>}
        pagination
        paginationServer
        paginationTotalRows={paginationTotalRows}
        paginationPerPage={paginationPerPage}
        paginationComponentOptions={{
          noRowsPerPage: true,
        }}
        fixedHeader={fixedHeader}
        onSort={onSort}
        defaultSortAsc={filter?.order !== 'desc'}
        defaultSortFieldId={filter?.sortField}
        onRowClicked={onRowClicked}
        sortServer={sortServer}
        selectableRows={selectableRows}
        onSelectedRowsChange={onSelectedRowsChange}
        selectableRowDisabled={selectableRowDisabled}
        persistTableHead
        responsive
        highlightOnHover
        paginationComponent={() => (
          <Pagination
            currentPage={currentPage}
            totalPages={totalPages}
            onPageChange={handlePageChange}
          />
        )}
        customStyles={{
          headCells: {
            style: {
              fontSize: 14,
              fontWeight: 'bold',
            },
          },
          rows: {
            style: {
              minHeight: '48px',
              padding: '12px 0',
            },
          },
        }}
      />
    </>
  );
};
