import React from 'react';
import { useSelector } from 'react-redux';
import { hygListConditionalRowStyles } from '../../../commonComponents/user-column';
import { CustomTable } from '../components/CustomTable';
import { CustomFilters } from './CustomFilters';

export const ProfessionalList = () => {
  const data = useSelector((state) => state.users.usersHYG);
  const count = useSelector((state) => state.users.countHYG);
  const searchParams = useSelector((state) => state.users.hygienistSearchParams);

  return (
    <CustomTable
      title="Professionals"
      data={data}
      count={count}
      searchParams={searchParams}
      params={{ user_type: 'HYG' }}
      omitColumns={['Office', 'DSO name', 'User Type']}
      conditionalRowStyles={hygListConditionalRowStyles}
      CustomFilters={CustomFilters}
    />
  );
};
