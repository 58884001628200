import { useEffect } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { fetchMultidayJobs } from '../../actions/multidayActions';
import { createLoadingSelector } from '../../apis/selectors';
import history from '../../history';
import { Table } from '../commonComponents/Table/Table';
import { multidayColumn, multidayJobConditionalRowStyles } from '../commonComponents/jobs-column';

const ROWS_PER_PAGE = 15;

const actions = ['FETCH_MULTIDAY_JOBS'];
const loadingSelector = createLoadingSelector(actions);

export const MultidayList = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => loadingSelector(state));
  const allJobs = useSelector((state) => state.multiday.allJobs);
  const searchParams = useSelector((state) => state.multiday.searchParams);
  const totalJobs = useSelector((state) => state.multiday.totalJobs);
  const totalJobsPostedByMDB = useSelector((state) => state.multiday.totalJobsPostedByMDB);
  const totalPages = Math.ceil(totalJobs / ROWS_PER_PAGE);

  const handleTableChange = (page) => {
    dispatch(fetchMultidayJobs({ page }));
  };

  const getJobUrl = (job) => `/multiday/${job.id}`;

  const getOfficeUrl = (userId, userType) => `/user/${userType.toLowerCase()}/${userId}`;

  const getColumn = () =>
    multidayColumn({
      getJobUrl,
      getOfficeUrl,
    });

  useEffect(() => {
    dispatch(fetchMultidayJobs({ page: searchParams.page ? searchParams.page : 1 }));
  }, [dispatch]);

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        overflowY: 'auto',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 20,
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <h3 className="card-title" style={{ fontSize: '24px', textAlign: 'center' }}>
          Multi-Day Booking
        </h3>
        <button
          style={{
            position: 'absolute',
            right: 80,
            top: 30,
            width: 150,
          }}
          className="btn btn-info"
          onClick={() => history.push('/multiday/post')}
          type="button"
        >
          Post Multi-Day
        </button>
        {isLoading ? (
          <div>
            <Spinner
              animation="border"
              role="status"
              variant="info"
              style={{
                height: 30,
                width: 30,
                marginLeft: 20,
                marginTop: 10,
              }}
            />
          </div>
        ) : null}
      </div>
      <h4
        style={{
          fontSize: '20px',
          fontWeight: 'bold',
          textAlign: 'right',
          width: '90%',
        }}
      >
        Total | {new Intl.NumberFormat().format(totalJobs)}
      </h4>
      <h4
        style={{
          fontSize: '20px',
          fontWeight: 'bold',
          textAlign: 'right',
          width: '90%',
        }}
      >
        Total Jobs | {new Intl.NumberFormat().format(totalJobsPostedByMDB)}
      </h4>
      <div style={{ width: '90%', paddingTop: 20 }}>
        <Table
          keyField="multiday.id"
          columns={getColumn()}
          data={allJobs}
          noDataComponent="There are no Jobs"
          isLoading={isLoading}
          totalPages={totalPages}
          showTotal={false}
          paginationPerPage={ROWS_PER_PAGE}
          paginationTotalRows={totalJobs}
          onPageChange={handleTableChange}
          showColumnsToHide={false}
          sortServer={false}
          conditionalRowStyles={multidayJobConditionalRowStyles}
        />
      </div>
    </div>
  );
};
