import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Styles from '../../../themes/style.module.scss';
import StatePicker from '../../commonComponents/StatePicker';
import debounceHook from '../../commonComponents/debounceHook';

export const CustomHeader = ({ filter, handleFilterChange }) => {
  const [searchText, setSearchText] = useState(filter?.officeName || '');
  const [zipCode, setZipCode] = useState(filter?.zipCode || '');
  const totalPPJobs = useSelector((state) => state.pp.totalPPJobs);
  const allPPRecruiters = useSelector((state) => state.pp.allPPRecruiters);

  const debouncedSearchText = debounceHook(searchText, 1000);
  const debouncedSearchZipCode = debounceHook(zipCode, 1000);

  const handleOnChange = (type, value) => {
    handleFilterChange(type, value);
  };

  useEffect(() => {
    handleFilterChange('officeName', searchText);
  }, [debouncedSearchText]);

  useEffect(() => {
    handleFilterChange('zipCode', zipCode);
  }, [debouncedSearchZipCode]);

  return (
    <div style={{ display: 'flex' }}>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p>Office Name:</p>
        <input
          style={{
            paddingLeft: '8px',
            fontFamily: 'Nunito',
            width: 200,
            height: 32,
          }}
          placeholder="Search by office name"
          value={searchText}
          onChange={(event) => {
            setSearchText(event.target.value);
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 200,
          marginRight: 10,
          marginLeft: 10,
        }}
      >
        <p>Status:</p>

        <select
          className="custom-select custom-select-lg"
          style={{ height: 32 }}
          value={filter.status}
          onChange={(val) => handleOnChange('status', val.target.value)}
        >
          <option value="all">All</option>
          <option value="active">Active</option>
          <option value="draft">Draft</option>
          <option value="complete">Completed</option>
          <option value="closed">Closed</option>
        </select>
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 200,
          marginRight: 10,
          marginLeft: 10,
        }}
      >
        <p>State:</p>

        <StatePicker
          style={{
            height: 32,
          }}
          value={filter.state}
          didSelect={(state) => handleOnChange('state', state)}
        />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <p>Zip Code:</p>
        <input
          style={{
            paddingLeft: '8px',
            fontFamily: 'Nunito',
            width: 200,
            height: 32,
          }}
          type="number"
          placeholder="Search by zip code"
          value={zipCode}
          onChange={(event) => {
            setZipCode(event.target.value);
          }}
        />
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          width: 200,
          marginRight: 10,
          marginLeft: 10,
        }}
      >
        <p>Recruiter:</p>

        <select
          className="custom-select custom-select-lg"
          style={{ height: 32 }}
          value={filter.recruiter}
          onChange={(val) => handleOnChange('recruiter', val.target.value)}
        >
          {['All', ...allPPRecruiters].map((r) => (
            <option value={r}>{r}</option>
          ))}
        </select>
      </div>

      <div
        style={{
          display: 'flex',
          flex: 1,
          alignItems: 'flex-end',
          justifyContent: 'flex-end',
        }}
      >
        <h3
          className={Styles.h3}
          style={{
            fontSize: '20px',
            fontWeight: 'bold',
          }}
        >
          Total | {new Intl.NumberFormat().format(totalPPJobs || '0')}
        </h3>
      </div>
    </div>
  );
};
