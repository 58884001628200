import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { fetchPPJobs } from '../../../actions/ppJobsAction';
import { createLoadingSelector } from '../../../apis/selectors';
import history from '../../../history';
import { Table } from '../../commonComponents/Table/Table';
import {
  permanentJobsColumn,
  ppJobsConditionalRowStyles,
} from '../../commonComponents/jobs-column';
import { CustomHeader } from './CustomHeader';
import { handleFilterChange } from '../../commonComponents/Table/utils';

const ROWS_PER_PAGE = 15;

const actions = ['FETCH_PP_JOBS'];
const loadingSelector = createLoadingSelector(actions);

export const PermanentJobList = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => loadingSelector(state));
  const allPPJobs = useSelector((state) => state.pp.allPPJobs);
  const searchParams = useSelector((state) => state.pp.searchParams);
  const totalPPJobs = useSelector((state) => state.pp.totalPPJobs);
  const totalPages = Math.ceil(totalPPJobs / ROWS_PER_PAGE);
  const [filter, setFilter] = useState({
    officeName: searchParams?.officeName || '',
    status: searchParams?.status || '',
    state: searchParams?.state || '',
    zipCode: searchParams?.zip || '',
    recruiter: searchParams?.recruiter || '',
    page: searchParams?.page || 1,
  });

  const handleTableChange = (page) => {
    dispatch(
      fetchPPJobs({
        ...filter,
        page,
      }),
    );
  };

  useEffect(() => {
    dispatch(
      fetchPPJobs({
        officeName: filter.officeName,
        zipCode: filter.zipCode,
        status: filter.status,
        state: filter.state,
        recruiter: filter.recruiter,
        page: 1,
        sortField: 'createdAt',
        sortOrder: 'desc',
      }),
    );
  }, [filter, dispatch]);

  const getJobUrl = (job) => `/pp-job/${job.id}`;

  const getOfficeUrl = (userId, userType) => `/user/${userType.toLowerCase()}/${userId}`;

  const getColumn = () =>
    permanentJobsColumn({
      getJobUrl,
      getOfficeUrl,
    });

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        overflowY: 'auto',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 20,
          alignItems: 'center',
          width: '100%',
          justifyContent: 'center',
        }}
      >
        <h3 className="card-title" style={{ fontSize: '24px', textAlign: 'center' }}>
          Permanent Jobs
        </h3>
        <button
          style={{
            position: 'absolute',
            right: 80,
            top: 30,
            width: 100,
          }}
          className="btn btn-info"
          onClick={() => history.push('/pp-post-job')}
          type="button"
        >
          Post Job
        </button>

        {isLoading ? (
          <div>
            <Spinner
              animation="border"
              role="status"
              variant="info"
              style={{
                height: 30,
                width: 30,
                marginLeft: 20,
                marginTop: 10,
              }}
            />
          </div>
        ) : (
          <div />
        )}
      </div>

      <div
        style={{
          width: '90%',
          marginTop: 20,
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <CustomHeader 
          filter={filter} 
          handleFilterChange={(type, value) => handleFilterChange(type, value, setFilter)}
        />
        <Table
          keyField="ppJobs.id"
          initialPage={filter.page}
          columns={getColumn()}
          data={allPPJobs}
          noDataComponent="There are no Jobs"
          isLoading={isLoading}
          totalPages={totalPages}
          showTotal={false}
          paginationPerPage={ROWS_PER_PAGE}
          paginationTotalRows={totalPPJobs}
          onPageChange={handleTableChange}
          showColumnsToHide={false}
          sortServer={false}
          conditionalRowStyles={ppJobsConditionalRowStyles}
        />
      </div>
    </div>
  );
};
