import moment from 'moment';
import history from '../../../../history';

export default ({ curentMDB }) => {
  const renderLine = (title, value) => (
    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
      <p style={{ width: '30%', fontWeight: 'bold' }}>{title}</p>
      <p style={{ maxWidth: '50%' }}>{value}</p>
    </div>
  );

  return (
    <div className="card">
      <div className="card-body">
        <h3 style={{ textAlign: 'center' }}>{`${curentMDB?.office?.office_name}`}</h3>
        <a
          href={`/user/dentist/${curentMDB?.office?.id}`}
          style={{
            fontSize: 14,
            cursor: 'pointer',
            justifyContent: 'center',
            display: 'flex',
            color: 'black',
            textDecoration: 'underline',
            textAlign: 'center',
            marginBottom: 20,
          }}
          rel="noreferrer"
          onClick={(event) => {
            event.preventDefault();
            history.push(`/user/dentist/${curentMDB?.office?.id}`);
          }}
        >
          View office
        </a>
        <div style={{ width: '100%', minWidth: 300 }}>
          <p style={{ textAlign: 'center', marginBottom: 20 }}>
            {curentMDB?.office?.address[0]?.formatted_address || ''}
          </p>
          {renderLine('Created At:', moment(curentMDB.createdAt).format('ddd, MMM DD, YYYY'))}

          {renderLine(
            'First Shift Date: ',
            moment(curentMDB.firstShiftDate).format('ddd, MMM DD, YYYY'),
          )}
          {renderLine(
            'Last Shift Date:',
            moment(curentMDB.lastShiftDate).format('ddd, MMM DD, YYYY'),
          )}
        </div>
      </div>
    </div>
  );
};
