import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import WrappedMap from '../../../commonComponents/WrappedMap';

import iconRDH from '../../../../images/rdh_icon.svg';
import iconRDA from '../../../../images/da_icon.svg';
import iconDN from '../../../../images/dn_icon.svg';
import iconOffice from '../../../../images/office_icon.svg';
import InputNumber from '../../../commonComponents/InputNumber';

const MIN_RADIUS = 1;
const MAX_RADIUS = 50;
const DEFAULT_RADIUS = 25;

export default () => {
  const currentUser = useSelector((state) => state.users.currentUser);
  const surroundingUserList = useSelector((state) => state.users.surroundingUserList);

  const [filter, setFilter] = useState('all');
  const [radius, setRadius] = useState(DEFAULT_RADIUS);

  const getIcon = (key) => {
    if (key === 'RDH') return iconRDH;
    if (key === 'DA') return iconRDA;
    if (key === 'DN') return iconDN;
    if (key === 'OFFICE') return iconOffice;
    return null;
  };

  const renderLegend = ({ title, count, Icon, styles }) => (
    <div style={{ display: 'flex', width: '300px', ...styles }}>
      <span
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '200px',
        }}
      >
        <img src={Icon} alt="icon" style={{ width: '24px', height: '24px', marginRight: 5 }} />
        <span style={{ fontSize: '24px' }}>{title}</span>
      </span>
      <span style={{ fontSize: '24px' }}>
        <strong>{count}</strong>
      </span>
    </div>
  );

  const renderTotal = () => {
    if (currentUser.user_type === 'DNT') {
      return (
        <div style={{ display: 'flex' }}>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {renderLegend({
              title: 'Dental Offices',
              count: 1,
              Icon: getIcon('OFFICE'),
            })}
          </div>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
            {Object.keys(surroundingUserList).map((key) =>
              renderLegend({
                title: key,
                count: surroundingUserList[key].length,
                Icon: getIcon(key),
                styles: { width: '180px' },
              }),
            )}
          </div>
          <div style={{ flex: 1, display: 'flex', flexDirection: 'column' }}>
            <div>
              <div style={{ fontSize: '14px' }}>Filter By: </div>
              <select
                className="custom-select custom-select-lg mb-3"
                style={{ marginTop: '5px', position: 'relative', width: '150px' }}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                <option className="dropdown-item" value="all">
                  All
                </option>
                <option className="dropdown-item" value="rdh">
                  RDH
                </option>
                <option className="dropdown-item" value="da">
                  DA
                </option>
                <option className="dropdown-item" value="dn">
                  DN
                </option>
                <option className="dropdown-item" value="dnt">
                  DNT
                </option>
              </select>
            </div>
            <div>
              <div style={{ fontSize: '14px' }}>Radius: </div>
              <InputNumber
                style={{ width: '150px' }}
                value={radius}
                onChange={(value) => setRadius(value)}
                min={MIN_RADIUS}
                max={MAX_RADIUS}
                step={5}
                autoApply={false}
              />
            </div>
          </div>
        </div>
      );
    }
    return (
      <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
        {renderLegend({
          title: 'Dental Offices',
          count: surroundingUserList?.length,
          Icon: getIcon('OFFICE'),
        })}
      </div>
    );
  };
  return (
    <div className="card mt-3 mb-5" style={{ width: '60%' }}>
      <div
        className="card-body"
        style={{
          backgroundColor: 'white',
          padding: '15px',
        }}
      >
        <div style={{ paddingBottom: '15px' }}>{renderTotal()}</div>
        <div
          style={{
            height: '500px',
            width: '100%',
            position: 'relative',
          }}
        >
          <WrappedMap filter={filter} radius={radius} />
        </div>
      </div>
    </div>
  );
};
