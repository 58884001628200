import React, { Component } from 'react';
import { Link } from 'react-router-dom';

const crossImage = require('../../../images/close-cross.png');

class JobDetailsUserBox extends Component {
  getAddress(address) {
    if (!address?.formatted_address) {
      return '';
    }

    address = address?.formatted_address?.split(', ');
    return `${address[1]}, ${address[2]}`;
  }

  renderCloseSign() {
    if (this.props.onCancel) {
      return (
        <img
          alt="icon"
          src={crossImage}
          style={{ width: 16, height: 16 }}
          onClick={() => {
            this.props.onCancel();
          }}
        />
      );
    }

    return <div />;
  }

  render() {
    const { user, style, textStyle } = this.props;
    const profileType = user.user_type === 'DNT' ? 'DENTIST' : 'HYGIENIST';
    const userId = user ? user._id : '';
    const address = user ? this.getAddress(user.address[0]) : '';
    return (
      <div
        className="card"
        style={{
          width: 200,
          height: 300,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
          marginInline: '3rem',
          ...style
        }}
      >
        {user?.profile_image ? (
          <img
            src={user.profile_image}
            alt="profile"
            className="card-img-top mt-2 mb-2"
            style={{
              objectFit: 'cover',
              width: '100px',
              height: '100px',
              borderRadius: 50,
              boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
            }}
          />
        ) : (
          <img
            src={require('../../../images/profileDefault.png')}
            alt="tooth"
            className="card-img-top mt-2 mb-2"
            style={{
              objectFit: 'cover',
              width: '100px',
              height: '100px',
              borderRadius: 50,
              boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
            }}
          />
        )}
        {user ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              justifyContent: 'center',
              padding: 10,
              ...textStyle
            }}
          >
            <strong className="card-title" style={{ textAlign: 'center', color: '#307F92', ...textStyle }}>
              {profileType}
            </strong>

            <h4
              style={{
                textAlign: 'center',
                ...textStyle
              }}
            >
              {user.user_type === 'DNT'
                ? user?.office_name
                : `${user.first_name} ${user.last_name}`}
            </h4>

            {user?.average_rating ? (
              <div
                height="34px"
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'baseline',
                  marginTop: 10,
                }}
              >
                <img
                  src={require('../../../images/star_Green.png')}
                  className="card-img-left"
                  width="15px"
                  alt="star_icon"
                  style={{ marginBottom: 10 }}
                />
                <p className="card-text">{user?.average_rating?.toFixed(1) || '-'}</p>
              </div>
            ) : (
              <div style={{ height: 34 }} />
            )}
            <p className="card-text">{address}</p>
            <Link
              className="card-text"
              to={{
                pathname: `/user/${profileType.toLowerCase()}/${userId}`,
                state: {
                  user_id: userId,
                  profile: profileType,
                },
              }}
            >
              View Profile
            </Link>
            {this.renderCloseSign()}
          </div>
        ) : (
          <div>
            <h3 className="card-title">Not yet filled</h3>
          </div>
        )}
      </div>
    );
  }
}

export default JobDetailsUserBox;
