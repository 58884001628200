import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import { useDispatch, useSelector } from 'react-redux';
import { fetchLicenses } from '../../../actions/licensesAction';
import { createLoadingSelector } from '../../../apis/selectors';
import SyncronizeIcon from '../../../images/synchronize.png';
import Styles from '../../../themes/style.module.scss';
import { Table } from '../../commonComponents/Table/Table';
import { licensesColumns, licensesConditionalRowStyles } from '../../commonComponents/jobs-column';
import { CustomHeader } from './CustomHeader';
import { handleFilterChange } from '../../commonComponents/Table/utils';

const ROWS_PER_PAGE = 15;

const actions = ['GET_ALL_UNVERIFIED_LICENSES'];
const loadingSelector = createLoadingSelector(actions);

export const Licenses = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => loadingSelector(state));
  const licenses = useSelector((state) => state.license.licenses);
  const searchParams = useSelector((state) => state.license.searchParams);
  const totalLicenses = useSelector((state) => state.license.totalLicenses);
  const totalPages = Math.ceil(totalLicenses / ROWS_PER_PAGE);
  const defaultFilter = {
    profession: searchParams.profession,
    subProfession: searchParams.subProfession,
    status: searchParams.status,
    state: searchParams.state,
    credentialType: searchParams.credential_type,
    accountStatus: searchParams.account_status,
    govIdUploaded: searchParams.gov_uploaded || searchParams.gov_status,
    expiresSoon: searchParams.expiresSoon,
    contractorStatus: searchParams.contractor_status,
    employeeStatus: searchParams.employee_status,
    page: searchParams.page || 1,
    sortField: searchParams.sortField || 'createdAt',
    sortOrder: searchParams.sortOrder || 'desc',
  };
  const [filter, setFilter] = useState(defaultFilter);

  const resetFilters = () => {
    setFilter(defaultFilter);
  };

  const getUserUrl = (userId, userType) => `/user/${userType.toLowerCase()}/${userId}`;

  const getColumn = () =>
    licensesColumns({
      getUserUrl,
    });

  const handleTableChange = (page) => {
    setFilter((prevState) => ({ ...prevState, page }));
  };

  const handleOnSort = (selectedColumn, sortDirection) => {
    const sortObj = {
      page: filter.page,
      sortField: selectedColumn.sortField,
      sortOrder: sortDirection,
    };

    setFilter((prevState) => ({
      ...prevState,
      ...sortObj,
    }));
  };

  useEffect(() => {
    dispatch(fetchLicenses({ ...filter }));
  }, [dispatch, filter]);

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        overflowY: 'auto',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          position: 'absolute',
          left: '50%',
          transform: 'translateX(-50%)',
        }}
      >
        <div style={{ display: 'flex', flexDirection: 'row', marginTop: 20, alignItems: 'center' }}>
          <h3 className={Styles.h3} style={{ fontSize: '24px', textAlign: 'center' }}>
            Licenses
          </h3>
          {isLoading ? (
            <div>
              <Spinner
                animation="border"
                role="status"
                variant="info"
                style={{
                  height: 30,
                  width: 30,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              />
            </div>
          ) : (
            <img
              src={SyncronizeIcon}
              width="38px"
              height="38px"
              style={{
                cursor: 'pointer',
                marginLeft: 8,
              }}
              alt="reload action"
              onClick={resetFilters}
            />
          )}
        </div>
      </div>
      <div style={{ width: '90%', paddingTop: 60 }}>
        <CustomHeader 
          filter={filter} 
          handleFilterChange={(type, value) => handleFilterChange(type, value, setFilter)}
        />
        <Table
          keyField="licenses.id"
          initialPage={filter.page}
          columns={getColumn()}
          data={licenses}
          noDataComponent="There are no Licenses"
          isLoading={isLoading}
          totalPages={totalPages}
          paginationPerPage={ROWS_PER_PAGE}
          paginationTotalRows={totalLicenses}
          onSort={handleOnSort}
          onPageChange={handleTableChange}
          conditionalRowStyles={licensesConditionalRowStyles}
        />
      </div>
    </div>
  );
};
