import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import moment from 'moment';
import historyNav from '../../../../history';
import { getApplicationStatus } from './Enums';

export default ({
  closePopUp,
}) => {
  const historyList = useSelector((state) => state.pp.historyList);
  const currentUser = useSelector((state) => state.users.currentUser);

  const wrapperRef = useRef(null);

  function useOutsideAlerter(ref) {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          closePopUp();
        }
      }
      // Bind the event listener
      document.addEventListener('mousedown', handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener('mousedown', handleClickOutside);
      };
    }, [ref]);
  }

  useOutsideAlerter(wrapperRef);

  const renderHistoryTitle = () => (
    <div
      style={{
        width: '100%',
        borderBottom: '1px solid #efefef',
        padding: '5px 10px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'lightgreen',
      }}
    >
      <p className="card-text" style={{ fontWeight: '600', textAlign: 'center', width: '30%' }}>
        Action
      </p>

      <p className="card-text" style={{ fontWeight: '600', textAlign: 'center', width: '30%' }}>
        Author
      </p>
      <p className="card-text" style={{ fontWeight: '600', textAlign: 'center', width: '30%' }}>
        Notes
      </p>
      <p className="card-text" style={{ fontWeight: '600', width: '30%', textAlign: 'center' }}>
        Date
      </p>
    </div>
  );

  const renderHistoryDetails = (history) => {
    if (history.action === '') {
      return <p style={{ width: '90%', textAlign: 'center' }}>There are no history</p>;
    }
    return (
      <div style={{
        width: '100%', display: 'flex', alignItems: 'center', minHeight: 40, justifyContent: 'space-between',
      }}
      >
        <div style={{ width: '30%', textAlign: 'center' }}>
          {getApplicationStatus(history.action)}
        </div>
        <div style={{ width: '30%', textAlign: 'center' }}>
          {history.author?.id === currentUser.id ? history.author?.office_name : (

            <div
              style={{
                textDecoration: 'underline', cursor: 'pointer', color: 'blue', fontColor: 16,
              }}
              onClick={() => historyNav.push(`/user/dentist/${history.author?.id}`, {
                user_id: history.author?.id,
              })}
            >
              View Admin Profile

            </div>
)}
        </div>
        <div style={{ width: '30%', textAlign: 'center' }}>
          {history.notes || '-'}
        </div>
        <div style={{ width: '30%', textAlign: 'center' }}>
          {moment(history.time).format('YYYY-MM-DD HH:mm A')}
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        flex: 1,
        backgroundColor: 'rgba(67, 89, 99, 0.8)',
        position: 'fixed',
        left: 0,
        right: 0,
        bottom: 0,
        top: 0,
        zIndex: 10,
        alignItems: 'center',
        justifyContent: 'center',
        display: 'flex',
      }}
    >
      <div
        ref={wrapperRef}
        style={{
          backgroundColor: 'white',
          maxHeight: '80%',
          height: '80%',
          overflowY: 'auto',
          alignContent: 'center',
          maxWidth: 900,
          minWidth: 450,
          width: '50%',
          display: 'flex',
          borderRadius: 5,
          flexDirection: 'column',
          boxShadow: '0px 1px 2px rgba(0, 0, 0, 0.3)',
          shadowColor: '#979797',
          shadowOpacity: 0.3,
          shadowOffset: {
            height: 3,
            width: 0,
          },
        }}
      >
        {' '}
        <div
          style={{
            padding: 20,
            marginBottom: 70,
            backgroundColor: 'white',
            borderRadius: 10,
          }}
        >
          <div
            style={{
              fontSize: 20,
              fontWeight: 'bold',
              color: '#B0BEC5',
              cursor: 'pointer',
              textAlign: 'right',
            }}
            onClick={() => closePopUp()}
          >
            X
          </div>
          <div style={{
            fontWeight: 'bold',
            fontFamily: 'Nunito',
            fontSize: 24,
            marginBottom: 20,
          }}
          >
            History information

          </div>
          {historyList.length === 0
            ? (
              <Spinner
                animation="border"
                role="status"
                variant="info"
                style={{
                  height: 30,
                  width: 30,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              />
            )

            : (

              <div className="card">
                {renderHistoryTitle()}
                {historyList.map((history) => renderHistoryDetails(history))}
              </div>
            )}
        </div>
      </div>
    </div>
  );
};
