import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { bulkCancelJobs } from '../../../actions/jobsAction';
import { userJobStatusFilterOpts } from '../../../constants';
import history from '../../../history';
import WarningAlertIcon from '../../../images/warning_alert_outline.svg';
import Styles from '../../../themes/style.module.scss';
import CustomPortal from '../CustomPortal';

export const CustomHeader = ({ selectedJobs, setSelectedJobs, filter, handleFilterChange }) => {
  const dispatch = useDispatch();
  const userProfile = useSelector((state) => state.users.currentUser);
  const allProfessions = useSelector((state) => state.jobs.allProfessions);
  const jobs = useSelector((state) => state.users.userJobs);
  const [showConfirmCancelPopup, setShowConfirmCancelPopup] = useState(false);

  const getConfirmCancelText = () => {
    const isAnyPendingOrCounter = jobs.some(
      (job) =>
        selectedJobs.includes(job.id) && (job.status === 'pending' || job.status === 'counter'),
    );
    if (isAnyPendingOrCounter) {
      return 'Are you sure you want to cancel? The shift(s) have applicant(s) that have not been confirmed yet.';
    }

    return 'Are you sure you want to cancel?';
  };

  const goToCreateJob = () => {
    history.push({
      pathname: '/jobcreate',
      state: { user: userProfile },
    });
  };

  const handleOnChange = (type, value) => {
    handleFilterChange(type, value);
  };

  return (
    <div style={{ marginTop: 24 }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
            marginBottom: 20,
            gap: 12
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 200,
            }}
          >
            <p>Status:</p>

            <select
              className="custom-select custom-select-lg"
              style={{ height: 32 }}
              value={filter.jobStatus}
              onChange={(val) => handleOnChange('jobStatus', val.target.value)}
            >
              {userJobStatusFilterOpts.map((el) => (
                <option value={el.value}>{el.label}</option>
              ))}
            </select>
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 200,
            }}
          >
            <p>Profession:</p>

            <select
              className="custom-select custom-select-lg"
              style={{ height: 32 }}
              value={filter.profession}
              onChange={(val) => {
                handleOnChange('profession', val.target.value !== 'all' ? val.target.value : '');
              }}
            >
              <option value="all">All</option>
              {allProfessions.map((el) => (
                <option value={el.code}>{el.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
          }}
        >
          {userProfile?.user_type === 'DNT' && (
            <>
              <button
                disabled={selectedJobs.length === 0}
                onClick={() => setShowConfirmCancelPopup(true)}
                className={Styles.create_job_btn}
                style={{ backgroundColor: '#307F92', opacity: selectedJobs.length === 0 ? 0.5 : 1 }}
                type="button"
              >
                Cancel Shift(s)
              </button>
              <span style={{ marginLeft: '20px' }} />
              <button onClick={goToCreateJob} className={Styles.create_job_btn} type="button">
                Post a Job
              </button>
            </>
          )}
        </div>
      </div>

      {showConfirmCancelPopup && (
        <CustomPortal
          Icon={WarningAlertIcon}
          title="Cancel Shift(s)"
          text={getConfirmCancelText()}
          textStyle={{ textAlign: 'center' }}
          submitText="I'm Sure"
          cancelText="Go Back"
          submitPressed={async () => {
            setShowConfirmCancelPopup(false);
            dispatch(bulkCancelJobs(selectedJobs));
            setSelectedJobs([]);
          }}
          cancelPressed={() => {
            setShowConfirmCancelPopup(false);
          }}
        />
      )}
    </div>
  );
};
