import React from 'react';

export const ClockOutAttestation = ({ answers = [] }) => {
  const renderAnswer = (answer) => (
    <tr key={answer?.code}>
      <td
        style={{
          padding: '30px 30px 30px 70px',
          fontSize: 16,
          border: '1px solid #EDEDED',
          width: '65%',
          verticalAlign: 'top',
        }}
      >
        {answer?.title}
      </td>
      <td
        style={{
          padding: '30px',
          fontSize: 16,
          border: '1px solid #EDEDED',
          width: '35%',
          verticalAlign: 'top',
        }}
      >
        {answer?.answer || '--'}
      </td>
    </tr>
  );

  return (
    <div
      className="card mt-5"
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        justifyContent: 'center',
        paddingTop: 20,
        paddingBottom: 50,
      }}
    >
      <div
        className="card-body"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <p
          className="card-title"
          style={{ fontFamily: 'Nunito', fontSize: 30, color: '#494963', marginLeft: 10 }}
        >
          Clock Out Attestation
        </p>
      </div>

      <div
        className="mt-5 mb-5"
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <table style={{ width: '90%' }}>
          {answers.map((answer) => renderAnswer(answer))}
        </table>
      </div>
    </div>
  );
};
