import React from 'react';
import dateFormat from 'dateformat';
import { UserProfession, UserSubProfession } from '../../../../enums/UserProfession';

export default ({ job_detail, onEdit }) => {
  const renderEditButton = () => (
    <button
      onClick={onEdit}
      className="btn btn-info mb-2"
      type="button"
      style={{ width: 150, marginTop: '12px', borderRadius: 10 }}
    >
      Edit
    </button>
  );
  const renderRow = (title, value) => (
    <div
      style={{
        width: '100%',
        borderBottom: '1px solid #efefef',
        marginTop: 5,
        padding: 5,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <p className="card-text" style={{ fontWeight: '600', width: '30%', margin: 0 }}>
        {title}
      </p>

      <div className="card-text" style={{ whiteSpace: 'pre-line', maxWidth: '80%' }}>{value || ''}</div>
    </div>
  );
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        jutifyContent: 'center',
        maxWidth: 800,
        marginTop: 50,
      }}
      className="card mt-4 mb-4"
    >
      <div className="card-body" style={{ width: '100%' }}>
        <h3 className="card-title mt-2" style={{ textAlign: 'center' }}>
          Job Details
        </h3>

        {renderRow('Status:', job_detail?.status)}
        {renderRow('Job ID:', job_detail?.id)}
        {renderRow(
          'Created At:',
          job_detail?.updatedAt
            ? dateFormat(job_detail?.createdAt, 'dddd, mmmm dS, yyyy, h:MM:ss TT')
            : '-',
        )}
        {renderRow(
          'Updated At:',
          job_detail?.updatedAt
            ? dateFormat(job_detail?.updatedAt, 'dddd, mmmm dS, yyyy, h:MM:ss TT')
            : '-',
        )}
        {renderRow('Profession Type:', UserSubProfession.getName(job_detail?.subProfession ?? job_detail?.profession))}
        {renderRow('Start Date:', job_detail?.start_date)}
        {renderRow('Schedule Type:', job_detail?.position)}
        {renderRow('Rate Range:', job_detail?.rate)}
        {renderRow('Benefit Package:', job_detail?.benefits)}
        {renderRow('Job Description:', job_detail?.content)}
        {renderRow('Calendly link:', job_detail?.calendly_host)}
        {renderRow('Recruiter:', job_detail?.recruiter)}
        {renderRow('Visibility:', job_detail?.visible_for_office ? 'visible' : 'invisible')}
      </div>
      {renderEditButton()}
    </div>
  );
};
