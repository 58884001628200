export const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handleClick = (page) => {
    if (page !== currentPage) {
      onPageChange(page);
    }
  };

  const renderPageNumbers = () => {
    const pageNumbers = [];
    let startPage = 1;
    let endPage = totalPages > 5 ? 5 : totalPages;

    if (currentPage > 3) {
      startPage = currentPage - 2;
      endPage = currentPage + 2;
      if (endPage > totalPages) {
        endPage = totalPages;
        startPage = totalPages - 4;
      }
      if (startPage < 1) {
        startPage = 1;
      }
    }

    for (let i = startPage; i <= endPage; i += 1) {
      pageNumbers.push(
        <button
          type="button"
          key={i}
          onClick={() => handleClick(i)}
          style={{
            backgroundColor: i === currentPage ? 'rgb(62, 176, 202)' : 'gray',
            color: 'white',
            padding: '6px 12px',
            cursor: 'pointer',
            border: `1px solid ${i === currentPage ? 'rgb(62, 176, 202)' : 'gray'}`,
          }}
        >
          {i}
        </button>,
      );
    }

    return pageNumbers;
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        width: '100%',
        justifyContent: 'center',
        marginTop: 48,
        marginBottom: 140,
      }}
    >
      {currentPage > 3 && (
        <button
          type="button"
          onClick={() => handleClick(1)}
          style={{ ...styles.icon, borderRight: 'none' }}
        >
          {'<<'}
        </button>
      )}
      {currentPage > 1 && (
        <button
          type="button"
          onClick={() => handleClick(currentPage - 1)}
          style={{ ...styles.icon, borderRight: 'none' }}
        >
          {'<'}
        </button>
      )}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
        }}
      >
        {renderPageNumbers()}
      </div>
      {currentPage < totalPages && (
        <button
          type="button"
          onClick={() => handleClick(currentPage + 1)}
          style={{ ...styles.icon, borderLeft: 'none' }}
        >
          {'>'}
        </button>
      )}
      {currentPage < totalPages - 2 && (
        <button
          type="button"
          onClick={() => handleClick(totalPages)}
          style={{ ...styles.icon, borderLeft: 'none' }}
        >
          {'>>'}
        </button>
      )}
    </div>
  );
};

const styles = {
  icon: {
    padding: '6px 12px',
    cursor: 'pointer',
    backgroundColor: '#ffffff',
    color: '#000000',
    border: '1px solid #ddd',
  },
};
