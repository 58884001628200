import { MAP_TYPES } from '../actions/actionTypes';

const defaultState = {
  userList: [],
  dentistCount: 0,
  hygienistCount: 0,
  completedJobsCount: 0,
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case MAP_TYPES.FETCH_USER_LIST_MAP_SUCCESS:
      return action.payload;

    default:
      return state;
  }
};
