import React, { useEffect, useMemo, useState } from 'react';
import DayPicker from 'react-day-picker';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';
import JobDetailsUserBox from '../JobDetailsUserBox';
import JobFindUserPlaceholder from '../JobFindUserPlaceholder';
import MobileAppModal from '../../../commonComponents/MobileAppModal';
import { preparePostMDB, postMultiday, cancelPosting } from '../../../../actions/multidayActions';
import { createLoadingSelector } from '../../../../apis/selectors';
import { getUserEducation } from '../../../../actions/licensesAction';
import { filterObjectKeys } from '../../../../utils';
import { userDaSubProfessions } from '../../../../constants';
import { UserProfession, UserSubProfession } from '../../../../enums/UserProfession';
import { UserType } from '../../../../enums/UserType';
import { EducationUtils } from '../../../../utils/EducationUtils';
import { getShouldAllowAdminToPostProcedure } from '../../../../growthbook';
import MultiSelect from '../../../commonComponents/MultiSelect';

export default () => {
  const dispatch = useDispatch();

  const [selectedDates, setSelectedDays] = useState({});

  const [localEnd, setLocalEnd] = useState('17:00');
  const [rate, setRate] = useState('42');
  const [lunch, setLunch] = useState('');
  const [localStart, setLocalStart] = useState('08:00');
  const [isLunchPaid, setIsLunchPaid] = useState('');
  const [dentist, setDentalOffice] = useState(undefined);
  const [selectProfession, setSelectProfession] = useState('RDH');
  const [selectedSubProfession, setSelectedSubProfession] = useState(null);
  const [skills, setSkills] = useState('specialty_General Dentistry');
  const [selectedProcedures, setSelectedProcedures] = useState([]);

  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [modalContent, setModalContent] = useState('');

  const postMultidayData = useSelector((state) => state.multiday.postMultidayData);
  const isMDBinfoProvided = useSelector((state) => state.multiday.isMDBinfoProvided);
  const allProfession = useSelector((state) => state.jobs.allProfessions);
  const userEducation = useSelector((state) => state.users.userEducation);

  const fromMonth = new Date();

  const loadingSelector = createLoadingSelector(['POST_MULTIDAY']);

  const isLoading = useSelector((state) => loadingSelector(state));
  const isEmptyDate = Object.keys(selectedDates).length === 0;
  const isValid = dentist && rate && localEnd && lunch && !isEmptyDate && localStart;

  const daSubProfessions = [{ code: 'none', name: 'None' }, ...userDaSubProfessions];
  const shouldShowSubProfessionOptions = selectProfession === 'DA';

  const availableSpecialties = useMemo(() => Object.keys(
    filterObjectKeys(userEducation?.specialtiesAndProcedures, 'specialty'),
  ), [userEducation?.specialtiesAndProcedures]);

  const availableProcedures = useMemo(() => EducationUtils.getProceduresOfProfession({
    specialtiesAndProcedures: userEducation?.specialtiesAndProcedures,
    profession: selectProfession,
    specialty: skills,
  }), [selectProfession, skills, userEducation?.specialtiesAndProcedures]);

  useEffect(() => {
    dispatch(getUserEducation({ userType: UserType.HYG, profession: selectProfession }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    dispatch(getUserEducation({ userType: UserType.HYG, profession: selectProfession }));

    setSkills('specialty_General Dentistry');
    setSelectedProcedures([]);
  }, [dispatch, selectProfession]);

  useEffect(() => {
    setSelectedProcedures([]);
  }, [dispatch, skills]);

  const modifiersStyles = Object.keys(selectedDates)
    .map((key) => ({
      [key]: {
        backgroundColor: '#3EB0CA',
        color: '#fff',
        width: 18,
        height: 18,
        borderRadius: '48%',
      },
    }))
    .reduce((a, b) => ({ ...a, ...b }), {});

  const handleDayClick = (newDate, { disabled }) => {
    const date = moment(newDate).format('YYYY-MM-DD');

    if (disabled) {
      // Day is disabled, do nothing
    } else if (Object.keys(selectedDates).includes(date)) {
      const key = date;
      const { [key]: val, ...childWithout } = selectedDates;

      setSelectedDays(childWithout);
    } else {
      setSelectedDays({ ...selectedDates, [date]: newDate });
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
  };

  const showContent = (type) => {
    setModalIsOpen(true);
    setModalContent(type);
  };

  const modalResult = (val) => {
    if (modalContent === 'break') {
      setModalIsOpen(false);
      setIsLunchPaid(val.paid);
      setLunch(val.time);
    }
  };

  const reviewPressed = () => {
    const data = {
      selectedDates,
      jobData: {
        localEnd,
        rate,
        break_time: lunch,
        localStart,
        is_break_paid: isLunchPaid,
        prefer_type: 'first_availavle',
        dentist: dentist.id,
        profession: selectProfession,
        subProfession: selectedSubProfession,
        specialty: skills,
        procedures: selectedProcedures,
      },
    };

    dispatch(preparePostMDB(data));
  };

  const confirmPostClicked = () => {
    dispatch(postMultiday());
  };

  const cancelPostClicked = () => {
    dispatch(cancelPosting());
    setSelectProfession('RDH');
  };

  const renderDntView = () => {
    if (dentist) {
      return <JobDetailsUserBox user={dentist} onCancel={() => setDentalOffice(undefined)} />;
    }

    return (
      <JobFindUserPlaceholder
        title="+ Add a Dental Office"
        onSelectUser={(office) => {
          setDentalOffice(office);
        }}
        user_type="DNT"
      />
    );
  };

  const renderConfirmRow = (title, value) => (
    <div
      style={{
        margin: 5,
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: 18,
        gap: 8,
      }}
    >
      <strong>{title}</strong>
      <span style={{ textAlign: 'end' }}>{value}</span>
    </div>
  );

  if (isLoading) {
    return (
      <div
        style={{
          width: '100vw',
          height: '90vh',
          overflowY: 'auto',
          flexGrow: 1,
          minWidth: 750,
          marginTop: 20,
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
          }}
        >
          <div className="spinner-border text-info d-flex justify-content-center bg-transparent" />
        </div>
      </div>
    );
  }

  if (isMDBinfoProvided && postMultidayData.length !== 0) {
    return (
      <div className="modal">
        <div
          className="modal_content"
          style={{
            width: '900px',
            marginTop: 20,
            padding: 20,
          }}
        >
          <h3
            style={{
              fontSize: '18px',
              textAlign: 'center',
              marginBottom: 20,
              marginTop: 50,
            }}
          >
            Please Confirm Multiday Post
            <br />
            <br />
            <strong>
              {' '}
              {dentist?.office_name}
            </strong>
          </h3>
          <div
            style={{
              padding: 30,
              overflowY: 'auto',
              maxHeight: 600,
              width: '90%',
              alignItems: 'center',
              display: 'grid',
              gridTemplateColumns: '1fr 1fr',
            }}
          >
            {postMultidayData.map((job) => (
              <div
                style={{
                  margin: 10,
                  borderRadius: 20,
                  boxShadow: '0px 1px 3px 1px rgba(0, 0, 0, 0.15)',
                  padding: 10,
                  width: '300px',
                  alignSelf: 'center',
                }}
              >
                {renderConfirmRow('Date: ', job.localDate)}
                {renderConfirmRow('Hours: ', `${job.localStart} - ${job.localEnd}`)}
                {renderConfirmRow('Rate: ', job.rate)}
                {renderConfirmRow(
                  'Break: ',
                  `${job.break_time} (${job.is_break_paid ? 'Paid' : 'Unpaid'})`,
                )}
                {renderConfirmRow('Profession: ', UserSubProfession.getName(job.subProfession) ?? UserProfession.getName(job.profession))}
                {renderConfirmRow('Preferred Skill: ', job.specialty?.split('_')?.[1])}
              </div>
            ))}
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 12,
            }}
          >
            <button
              type="button"
              onClick={() => cancelPostClicked()}
              className="btn "
              style={{
                width: 200,
                borderRadius: 50,
                height: 50,
                fontSize: 20,
                marginRight: 40,
                backgroundColor: '#4FC0CF',
                color: 'white',
              }}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => confirmPostClicked()}
              className="btn"
              style={{
                width: 200,
                borderRadius: 50,
                height: 50,
                fontSize: 20,
                backgroundColor: '#307F92',
                color: 'white',
              }}
            >
              Confirm
            </button>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div
      style={{
        width: '100vw',
        height: '90vh',
        overflowY: 'auto',
        flexGrow: 1,
        minWidth: 750,
        marginTop: 20,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
          overflowY: 'auto',
        }}
      >
        <h3
          style={{
            fontSize: '24px',
            textAlign: 'center',
            marginBottom: 20,
            marginTop: 50,
          }}
        >
          Multi-Day Booking
        </h3>

        <h4
          style={{
            textAlign: 'center',
            marginTop: 50,
          }}
        >
          Select office and dates on the calendar you wish to post a job.
        </h4>
        <div
          style={{
            alignSelf: 'center',
            display: 'flex',
            width: '70%',
            marginTop: 20,
            justifyContent: 'center',
          }}
        >
          {renderDntView()}

          <div style={{ width: 300, marginLeft: 100 }}>
            <DayPicker
              modifiers={selectedDates}
              modifiersStyles={modifiersStyles}
              onDayClick={handleDayClick}
              selectedDays={selectedDates}
              fromMonth={fromMonth}
              disabledDays={{ before: new Date(), after: moment().add(99, 'days').toDate() }}
            />
          </div>
        </div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            width: '100%',
            marginTop: 50,
          }}
        >
          <h4
            style={{
              textAlign: 'center',
            }}
          >
            {' '}
            Select shift details
          </h4>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginTop: 50,
              width: 500,
              justifyContent: 'center',
            }}
          >
            <div
              style={{
                marginTop: 0,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              <p style={{ fontSize: 18 }}>Profession:</p>
              <select
                className="form-control"
                style={{
                  width: '60%',
                  marginTop: '5px',
                  display: 'table-cell',
                  height: 50,
                  fontSize: 20,
                }}
                onChange={(e) => {
                  setSelectProfession(e.target.value);
                  setSelectedSubProfession(null);
                }}
              >
                {allProfession.map((profession) => (
                  <option key={`profession-${profession.code}`} className="form-control"
                    style={{ fontSize: 20 }}
                    value={profession.code}>
                    {profession.name}
                  </option>
                ))}
              </select>

            </div>

            {shouldShowSubProfessionOptions && <div
              style={{
                marginTop: 0,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              <p style={{ fontSize: 18 }}>Sub-Profession:</p>
              <select
                className="form-control"
                style={{
                  width: '60%',
                  marginTop: '5px',
                  display: 'table-cell',
                  height: 50,
                  fontSize: 20,
                }}
                onChange={(e) => {
                  const code = e.target.value;

                  setSelectedSubProfession(code === 'none' ? null : code);
                }}
              >
                {daSubProfessions.map((subProfession) => (
                  <option key={`sub-profession-${subProfession.code}`} className="form-control"
                    style={{ fontSize: 20 }}
                    value={subProfession.code}>
                    {subProfession.name}
                  </option>
                ))}
              </select>

            </div>}

            <div
              style={{
                marginTop: 0,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                marginBottom: 20,
              }}
            >
              <p style={{ fontSize: 18 }}>Preferred Skill:</p>
              <select
                className="form-control"
                style={{
                  width: '60%',
                  marginTop: '5px',
                  display: 'table-cell',
                  height: 50,
                  fontSize: 20,
                }}
                onChange={(e) => setSkills(e.target.value)}
                value={skills}
              >
                {availableSpecialties.map((code) => (
                  <option key={code} className="form-control" style={{ fontSize: 20 }} value={code}>
                    {code.replace(/^specialty_/g, '')}
                  </option>
                ))}
              </select>
            </div>

            {
              getShouldAllowAdminToPostProcedure() && availableProcedures.length > 0 && (
                <div
                  style={{
                    marginTop: 0,
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    marginBottom: 20,
                  }}
                >
                  <div style={{
                    display: 'flex',
                    flexDirection: 'column'
                  }}>
                    <p style={{ fontSize: 18, margin: 0 }}>Procedures:</p>
                    <p>(Optional)</p>
                  </div>

                  <MultiSelect
                    className="custom-select custom-select-lg mb-3"
                    style={{
                      position: 'relative',
                      width: '60%',
                      fontSize: 20,
                      height: 50,
                      display: 'inline-flex',
                      alignItems: 'center',
                      padding: '6px 16px',
                    }}
                    placeholder="Procedures"
                    alwaysShowPlaceholder
                    options={availableProcedures.map((procedure) => {
                      const name = procedure.replace(/^procedure_/g, '');

                      return {
                        label: name,
                        value: procedure,
                      };
                    })}
                    autoApply={false}
                    maxSelection={4}
                    value={selectedProcedures}
                    onChange={(values) => {
                      setSelectedProcedures(values);
                    }}
                  />
                </div>
              )
            }

            <div
              style={{
                marginTop: 0,
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <p style={{ fontSize: 18 }}>Arrival:</p>
              <input
                value={localStart}
                onChange={(val) => setLocalStart(val.target.value)}
                type="time"
                className="form-control"
                style={{
                  display: 'table-cell',
                  height: 50,
                  fontSize: 20,
                  width: '60%',
                }}
              />
            </div>

            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p style={{ fontSize: 18 }}>End:</p>
              <input
                value={localEnd}
                onChange={(val) => setLocalEnd(val.target.value)}
                type="time"
                className="form-control"
                style={{
                  display: 'table-cell',
                  height: 50,
                  fontSize: 20,
                  width: '60%',
                }}
              />
            </div>

            <div style={{ marginTop: 0, display: 'flex', justifyContent: 'space-between' }}>
              <p style={{ fontSize: 18 }}>Hourly Offer:</p>
              <input
                value={rate}
                onChange={(val) => setRate(val.target.value)}
                className="form-control"
                style={{ width: '60%', fontSize: 20, height: 50 }}
                placeholder="00"
              />
            </div>

            <div
              style={{
                marginTop: 20,
                marginBottom: 20,
                display: 'flex',
                justifyContent: 'space-between',
              }}
              onClick={() => showContent('break')}
            >
              <p style={{ fontSize: 18 }}>Break:</p>

              <p className="form-control" style={{ width: '60%', fontSize: 20, height: 50 }}>
                {' '}
                {lunch ? `${lunch} ${isLunchPaid ? '(Paid)' : '(Unpaid)'}` : 'Time'}
              </p>
            </div>
          </div>
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              marginTop: 12,
            }}
          >
            <button
              type="button"
              onClick={() => reviewPressed()}
              className="btn btn-success"
              disabled={!isValid}
              style={{
                width: 200,
                borderRadius: 50,
                height: 50,
                fontSize: 20,
              }}
            >
              Review Jobs
            </button>
          </div>
        </div>
      </div>

      <MobileAppModal
        show={modalIsOpen}
        closeModal={closeModal}
        content={modalContent}
        result={modalResult}
      />
      <div style={{ height: 200 }} />
    </div>
  );
};
