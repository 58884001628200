import React, { Component } from 'react';
import UserSearchField from './UserSearchField';

class JobFindUserPlaceholder extends Component {
  constructor(props) {
    super(props);
    this.state = {
      searchHygienist: false,
    };
  }

  render() {
    return (
      <div
        className="card"
        style={{
          width: 200,
          height: 300,
          display: 'flex',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'flex-start',
          paddingTop: 10,
          marginBottom: 10,
          marginLeft: 3,
          marginRight: 3,
          ...this.props.style,
        }}
      >
        <img
          src={require('../../../images/profileDefault.png')}
          alt="tooth"
          className="card-img-top mt-2 mb-2"
          style={{
            objectFit: 'cover',
            width: '100px',
            height: '100px',
            borderRadius: 50,
            boxShadow: '0px 2px 3px rgba(0, 0, 0, 0.3)',
          }}
        />
        {this.state.searchHygienist ? (
          <UserSearchField
            user_type={this.props.user_type}
            onSelectUser={this.props.onSelectUser}
            placeholder={
              this.props.user_type === 'HYG' ? 'Type a Hygienist Name' : 'Type a Dentist Name'
            }
            heightEnabled={this.props.heightEnabled}
            style={{ width: '80%' }}
          />
        ) : (
          <button
            className="btn btn-light border border-info mb-2 mt-2"
            style={{
              height: 150,
              width: '80%',
              borderRadius: 11,
              marginBottom: 10,
            }}
            onClick={() => this.setState({ searchHygienist: true })}
          >
            {this.props.title}
          </button>
        )}
      </div>
    );
  }
}

export default JobFindUserPlaceholder;
