import React from 'react';
import { useSelector } from 'react-redux';
import { unfinishedUserListConditionalRowStyles } from '../../../commonComponents/user-column';
import { CustomFilters } from '../components/CustomFilters';
import { CustomTable } from '../components/CustomTable';
import { UNFINISHED_USERS_FILTERS } from '../utils';

export const UnfinishedUserList = () => {
  const data = useSelector((state) => state.users.unfinsihedUsers);
  const count = useSelector((state) => state.users.unfinsihedUsersCount);
  const searchParams = useSelector((state) => state.users.unfinishedSearchParams);
  const allProfessions = useSelector((state) => state.jobs.allProfessions);

  return (
    <CustomTable
      title="Users who don't complete registration"
      data={data}
      count={count}
      searchParams={searchParams}
      params={{ completeRegistration: false }}
      omitColumns={['Acct Standing', 'Office', 'DSO name', 'Completed Date', 'Average Rating']}
      conditionalRowStyles={unfinishedUserListConditionalRowStyles}
      CustomFilters={(props) => (
        <CustomFilters {...props} FILTERS={UNFINISHED_USERS_FILTERS(allProfessions)} />
      )}
    />
  );
};
