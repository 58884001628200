import { useEffect, useState } from 'react';
import debounceHook from '../../commonComponents/debounceHook';

export const CustomHeader = ({ filter, handleFilterChange }) => {
  const [searchText, setSearchText] = useState(filter?.searchText || '');
  const debouncedSearchText = debounceHook(searchText, 1000);

  const handleOnChange = (type, value) => {
    handleFilterChange(type, value);
  };

  useEffect(() => {
    handleFilterChange('searchText', searchText);
  }, [debouncedSearchText]);

  return (
    <div style={{ marginTop: 24 }}>
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            width: '100%',
          }}
        >
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <p>Search:</p>
            <input
              style={{
                paddingLeft: '8px',
                fontFamily: 'Nunito-Regular',
                width: 200,
                height: 32,
              }}
              placeholder="Search"
              value={searchText}
              onChange={(event) => {
                setSearchText(event.target.value);
              }}
            />
          </div>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: 200,
              marginRight: 10,
              marginLeft: 10,
            }}
          >
            <p>Status:</p>

            <select
              className="custom-select custom-select-lg"
              style={{ height: 32 }}
              value={filter.payFilter}
              onChange={(val) => handleOnChange('payFilter', val.target.value)}
            >
              <option key="all" value="all">
                All
              </option>
              <option key="completed" value="completed">
                Completed
              </option>
              <option key="finished" value="finished">
                Finished
              </option>
              <option key="unfinished" value="unfinished">
                Unfinished
              </option>
            </select>
          </div>
        </div>
      </div>
    </div>
  );
};
