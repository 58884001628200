import React, { useState } from 'react';

import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import backBlueImage from '../../images/back_blue.svg';
import { validateEmail } from '../../hooks/emailValidation';
import phoneHook from '../../hooks/phoneHook';
import { states } from './StatePicker';
import CustomPortal from './CustomPortal';
import { applyForPPJob } from '../../actions/ppJobsAction';
import { createLoadingSelector } from '../../apis/selectors';

const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    minHeight: '200px',
    height: '80%',
    minWidth: 600,
  },
};

const ResumeForm = ({
  isOpen,
  currentJob,
  closeForm,
}) => {
  const dispatch = useDispatch();
  const actions = ['APPLY_FOR_JOB'];
  const loadingSelector = createLoadingSelector(actions);
  const isLoading = useSelector((state) => loadingSelector(state));
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');

  const [contactNumber, setContactNumber] = phoneHook('');
  const [emailId, setEmail] = useState('');

  const [licId, setLicId] = useState('');
  const [issuedYear, setIssuedYear] = useState('');
  const [schoolName, setSchoolName] = useState('');
  const [state, setLicState] = useState('AL');

  const [yearsOfExperience, setYearsOfExperience] = useState('');
  const [available, setAvailable] = useState(
    'Full Time',
  );
  const [officesWorkedIn, setOfficesWorkedIn] = useState(
    '',
  );
  const [howFindOutAboutJob, setHowFindOutAboutJob] = useState(
    '',
  );

  const [interviewAvailability, setInterviewAvailability] = useState(
    '',
  );
  const [about, setAbout] = useState('');

  const [showCheckboxAlert, setShowCheckboxAlert] = useState(false);
  const [showEmailIsNotValid, setShowEmailIsNotValid] = useState(false);

  const MAX_LENHT_ABOUT = 300;
  const charactersCount = MAX_LENHT_ABOUT - about.length >= 0 ? MAX_LENHT_ABOUT - about.length : 0;
  const isInfoMissed = firstName === ''
    || lastName === ''
    || contactNumber === ''
    || emailId === ''
    || schoolName === ''
    || state === ''
    || issuedYear === ''
    || about === ''
    || yearsOfExperience === ''
    || officesWorkedIn === ''
    || howFindOutAboutJob === ''
    || interviewAvailability === '';

  const submitResume = async () => {
    if (isInfoMissed) {
      setShowCheckboxAlert(true);
    } else if (!validateEmail(emailId)) {
      setShowEmailIsNotValid(true);
    } else {
      const resumeData = {
        first_name: firstName,
        last_name: lastName,
        contact_number: contactNumber,
        email_id: emailId,
        schoolName,
        issuedYear,
        about,
        licId,
        state,
        yearsOfExperience,
        scheduleType: available,
        officesWorkedIn,
        howFindOutAboutJob,
        interviewAvailability,
      };
      dispatch(applyForPPJob(currentJob.id, resumeData));
      closeForm();
    }
  };

  return (
    <Modal
      style={customStyles}
      isOpen={isOpen}
    >

      <div
        className="container"
        style={{
          padding: 20,
          marginBottom: 70,
          height: '100%',
          backgroundColor: 'white',
          width: '100%',
          borderRadius: 10,
        }}
      >
        <div
          style={{
            fontSize: 20,
            fontWeight: 'bold',
            color: '#B0BEC5',
            cursor: 'pointer',
            textAlign: 'right',
          }}
          onClick={() => closeForm()}
        >
          X
        </div>
        <div
          style={{ marginBottom: 25, width: '100%' }}
        >

          <div style={{
            fontSize: 24, textAlign: 'center', width: '100%', fontFamily: 'Nunito',
          }}
          >
            Create new application
          </div>
        </div>
        <div>
          <div className="row common-text-bold mb-2">First Name</div>
          <input
            type="text"
            value={firstName}
            className="row input-blue-border form-control"
            onChange={(value) => setFirstName(value.currentTarget.value)}
          />
        </div>
        <div>
          <div className="row common-text-bold mb-2">Last Name</div>
          <input
            type="text"
            value={lastName}
            className="row input-blue-border form-control"
            onChange={(value) => setLastName(value.currentTarget.value)}
          />
        </div>
        <div>
          <div className="row common-text-bold mb-2">Contact Number</div>
          <input
            type="text"
            value={contactNumber}
            className="row input-blue-border form-control"
            onChange={(value) => setContactNumber(value.currentTarget.value)}
          />
        </div>
        <div>
          <div className="row common-text-bold mb-2">Email</div>
          <input
            type="text"
            value={emailId}
            className="row input-blue-border form-control"
            onChange={(value) => setEmail(value.currentTarget.value)}
          />
        </div>
        <div>
          <div className="row common-text-bold mb-2">Hygienist License Number</div>
          <input
            type="text"
            value={licId}
            className="row input-blue-border form-control"
            onChange={(value) => setLicId(value.currentTarget.value)}
          />
        </div>
        <div>
          <div className="row common-text-bold mb-2">State</div>
          <div
            className="row input-blue-border form-control"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              paddingLeft: 5,
            }}
          >
            <select
              className="border-0"
              value={state}
              style={{ paddingLeft: 0, width: '100%' }}
              onChange={(value) => setLicState(value.currentTarget.value)}
            >
              {states.map((state) => (
                <option value={state.abbreviation}>
                  {' '}
                  {state.abbreviation}
                </option>
              ))}
            </select>
          </div>
        </div>
        <div>
          <div className="row common-text-bold mb-2">Year Issued</div>
          <input
            value={issuedYear}
            type="text"
            className="row input-blue-border form-control"
            onChange={(value) => setIssuedYear(value.currentTarget.value)}
          />
        </div>
        <div>
          <div className="row common-text-bold mb-2">School Attended</div>
          <input
            value={schoolName}
            type="text"
            className="row input-blue-border form-control"
            onChange={(value) => setSchoolName(value.currentTarget.value)}
          />
        </div>

        <div>
          <div className="row common-text-bold">
            Availablity part time or full time?
          </div>
          <div
            className="row input-blue-border form-control"
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around',
              paddingLeft: 5,
            }}
          >
            <select
              className="border-0"
              value={available}
              style={{ paddingLeft: 0, width: '100%' }}
              onChange={(value) => setAvailable(value.currentTarget.value)}
            >
              <option value="Full Time"> Full Time</option>
              <option value="Part Time">Part Time </option>
              <option value="Any (Part or Full Time)">
                Any (Part or Full Time)
              </option>
            </select>
          </div>
        </div>
        <div>
          <div className="row common-text-bold mb-2">How many years of dental industry experience?</div>
          <input
            type="text"
            value={yearsOfExperience}
            className="row input-blue-border form-control"
            onChange={(value) => setYearsOfExperience(value.currentTarget.value)}
          />
        </div>
        <div>
          <div className="row common-text-bold mb-2">List last 3 employers</div>
          <input
            type="text"
            value={officesWorkedIn}

            className="row input-blue-border form-control"
            onChange={(value) => setOfficesWorkedIn(value.currentTarget.value)}
          />
        </div>
        <div>
          <div className="row common-text-bold mb-2">How did find out about this job?</div>
          <input
            type="text"
            value={howFindOutAboutJob}
            className="row input-blue-border form-control"
            onChange={(value) => setHowFindOutAboutJob(value.currentTarget.value)}
          />
        </div>
        <div>
          <div className="row common-text-bold mb-2">
            Available time for an interview
          </div>
          <textarea
            className="row input-blue-border form-control"
            style={{ height: 70 }}
            value={interviewAvailability}
            placeholder="Ex. Mon - Fri between 12pm-1pm"
            onChange={(value) => setInterviewAvailability(value.currentTarget.value)}
          />
        </div>
        <div>
          <div className="row common-text-bold mb-2">
            About Professional (
            {`${charactersCount}`}
            {' '}
            characters or less)
          </div>
          <textarea
            className="row input-blue-border form-control "
            style={{ height: 150 }}
            value={about}
            placeholder="Ex. She was born and raised in Florida, and
          has a passion for the dental industry. She
          has also been a member of the ADHA
          community since 2016."
            onChange={(value) => setAbout(value.currentTarget.value)}
          />
        </div>
        {isLoading ? (
          <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '10px' }}>
            <Spinner
              animation="border"
              role="status"
              variant="info"
              style={{
                height: 30,
                width: 30,
                marginLeft: 20,
                marginTop: 10,
              }}
            />
          </div>
        ) : (
          <div />
        )}
        <div style={{
          display: 'flex', justifyContent: 'center', width: '100%', gap: '10px', marginBottom: '30px', marginLeft: '-15px', paddingBottom: '10px',
        }}
        >
          <button
            className="btn btn-secondary"
            type="button"
            onClick={() => closeForm()}
            style={{ width: 150, height: 40 }}
          >
            Cancel
          </button>
          <button
            className="btn btn-success"
            type="button"
            onClick={() => submitResume()}
            style={{ width: 150, height: 40 }}
          >
            Send Resume
          </button>

        </div>
        {showCheckboxAlert ? (
          <div className="card mt-4">
            <CustomPortal
              title=""
              text="Please enter all needed information."
              submitText="OK"
              submitPressed={() => setShowCheckboxAlert(false)}
              cancelPressed={() => setShowCheckboxAlert(false)}
              cancelText="Cancel"
            />
          </div>
        ) : (
          <div />
        )}

        {showEmailIsNotValid ? (
          <div className="card mt-4">
            <CustomPortal
              title=""
              text="Please enter a valid email"
              submitText="OK"
              submitPressed={() => setShowEmailIsNotValid(false)}
              cancelPressed={() => setShowEmailIsNotValid(false)}
              cancelText="Cancel"
            />
          </div>
        ) : (
          <div />
        )}
      </div>
    </Modal>
  );
};
export default ResumeForm;
