import { useSelector } from 'react-redux';
import { CustomSelect } from '../components/CustomSelect';
import { HasRequiredCredential } from '../components/HasRequiredCredential';
import { HYG_FILTERS } from '../utils';

export const CustomFilters = ({ filter, handleFilterChange }) => {
  const allProfessions = useSelector((state) => state.jobs.allProfessions);
  
  return(
    <>
      {HYG_FILTERS(allProfessions)?.map(({ key, label, options }) => (
        <CustomSelect
          key={key}
          id={key}
          label={label}
          options={options}
          value={filter[key]}
          onChange={handleFilterChange}
        />
      ))}

      <HasRequiredCredential filter={filter} handleFilterChange={handleFilterChange} />
    </>
  )
}