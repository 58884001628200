import React, { useEffect, useState, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import moment from 'moment';
import Spinner from 'react-bootstrap/Spinner';
import history from '../../history';
import { createLoadingSelector, createErrorMessageSelector } from '../../apis/selectors';

import {
  fetchMessages,
  fetchJobsWithMessages,
  postMessage,
  changeConversationStatus,
  listenForhNewMessages,
} from '../../actions/messagesAction';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css';
import redAlert from '../../images/redAlert.png';
import chatSendButton from '../../images/chatSendButton.png';
import { fetchJob } from '../../actions/jobsAction';

export default ({ location, match }) => {
  const actions = ['GET_ALL_MESSAGES', 'GET_JOBS_WITH_CONVERSATIONS'];

  const [adminMessage, setAdminMessage] = useState('');

  const inputRef = useRef(null);
  const loadingSelector = createLoadingSelector(actions);
  const isLoading = useSelector((state) => loadingSelector(state));

  const conversations = useSelector((state) => state.conversations.conversations);
  const isFinalPage = useSelector((state) => state.conversations.isFinalPage);
  const currentJobId = useSelector((state) => state.conversations.currJobId);

  const currentJob = useSelector((state) => state.jobs.currentJob);

  const searchParams = useSelector((state) => state.conversations.searchParams);

  let selectedJob = useSelector((state) => state.conversations.selectedJob);

  const dispatch = useDispatch();

  let jobsWithMessages = useSelector((state) => state.conversations.jobsWithMessages);
  const jobId = history.location.state ? history.location?.state.job.id : match.params.id;

  const jobInfo = jobId && history.location?.state?.job ? history.location?.state?.job : currentJob;

  if (jobId && jobInfo) {
    jobsWithMessages = [jobInfo];
  }

  if (jobId) {
    selectedJob = jobInfo;
  } else if (history.location.state) {
    selectedJob = history.location.state.job;
  }
  const scrollToBottom = () => inputRef.current?.scrollIntoView({ behavior: 'smooth', block: 'end' });

  useEffect(() => {
    scrollToBottom();
  }, [currentJobId]);

  useEffect(() => {
    dispatch(listenForhNewMessages());
    if (jobId) {
      dispatch(fetchJob(jobId));
      dispatch(fetchMessages(jobId));
    } else if (!jobId) {
      dispatch(fetchJobsWithMessages({ page: searchParams.page ? searchParams.page : 1 }));
    }
  }, []);

  const renderJobCard = (job) => (
    <div
      className="card"
      key={job.id}
      style={{
        cursor: 'pointer',
        borderColor: job.id === currentJobId ? '#3eb0ca' : null,
      }}
      onClick={() => {
        dispatch(fetchMessages(job.id));
      }}
    >
      <div className="card-body" style={{ display: 'flex', flexDirection: 'column' }}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <h4 className="card-title mb-1">Shift Details:</h4>
          {job?.is_conversation_support_requested ? (
            <img src={redAlert} alt="Support" style={{ height: 30, width: 30, marginBottom: 10 }} />
          ) : (
            <div />
          )}
        </div>
        <div>
          <text style={{ fontWeight: 'bold', fontSize: 18 }}>Address: </text>
          <text className="card-text" style={{ fontSize: 16 }}>
            {job.offer_owner?.address[0]?.formatted_address || ''}
          </text>
        </div>
        <div>
          <text style={{ fontWeight: 'bold', fontSize: 18 }}>Shift Date: </text>
          <text className="card-text" style={{ fontSize: 16 }}>
            {moment(job.job_datetime).format('ddd, MMM DD, YYYY')}
          </text>
        </div>
        <div>
          <text style={{ fontWeight: 'bold', fontSize: 18 }}>Professional: </text>
          <text className="card-text" style={{ fontSize: 16 }}>
            {`${job.offer_final_reciever?.first_name || ''} ${
              job.offer_final_reciever?.last_name || ''
            }`}
          </text>
        </div>
        <div>
          <text style={{ fontWeight: 'bold', fontSize: 18 }}>Dental Office: </text>
          <text className="card-text" style={{ fontSize: 16 }}>
            {`${job.offer_owner?.office_name || ''}`}
          </text>
        </div>
        <div>
          <text
            onClick={() => history.push(`/job/${job.id}`, { job_id: job.id })}
            className="card-text mt-2"
            style={{
              color: '#3eb0ca',
              textDecorationLine: 'underline',
              cursor: 'pointer',
              fontSize: 18,
            }}
          >
            Open Job
          </text>
        </div>
      </div>
    </div>
  );

  const renderMessages = (message) => {
    let backgroundColor = '';
    let alignSelf = '';
    let authorName = '';
    if (message.type === 'ADMIN-INVITE') {
      backgroundColor = '#3eb0ca';
      alignSelf = 'center';
      authorName = message.author.user_type === 'HYG'
        ? `${message.author.first_name} ${message.author.last_name}`
        : message.author.office_name;
    } else if (message.author.isAdmin) {
      backgroundColor = '#3eb0ca';
      alignSelf = 'center';
      authorName = 'Admin';
    } else if (message.author.id === selectedJob.offer_owner?.id) {
      backgroundColor = '#D8F8FF';
      alignSelf = 'flex-start';
      authorName = `${selectedJob.offer_owner.office_name}`;
    } else if (message.author.id === selectedJob.offer_final_reciever?.id) {
      backgroundColor = '#EFEEF4';
      alignSelf = 'flex-end';
      authorName = `${selectedJob.offer_final_reciever.first_name} ${selectedJob.offer_final_reciever.last_name}`;
    }
    return (
      <div
        key={message.id}
        className="card"
        style={{
          margin: 5,
          borderRadius: 20,
          backgroundColor,
          width: '80%',
          alignSelf,
        }}
      >
        <div className="card-body" style={{ display: 'flex', flexDirection: 'column' }}>
          <text className="card-title" style={{ fontFamily: 'Nunito' }}>
            {authorName}
          </text>

          <text className="card-title" style={{ fontWeight: 'bold', fontFamily: 'Nunito' }}>
            {message.type === 'ADMIN-INVITE' ? 'Request Support Assistance' : message.text}
          </text>
          <text
            className="card-text"
            style={{
              color: '#435963',
              fontFamily: 'Nunito',
              fontSize: 12,
              textAlign: 'right',
            }}
          >
            {moment(message.created_at).format('ddd, MMM DD, YYYY, hh:mm a')}
          </text>
        </div>
      </div>
    );
  };

  return (
    <div
      style={{
        display: 'flex',
        overflowY: 'auto',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          marginTop: 20,
          width: '100%',
          height: '100%',
          display: 'flex',
          flexDirection: 'column',
        }}
      >
        <div className={{ flexDirection: 'column', display: 'flex' }}>
          <h3 className="card-title" style={{ fontSize: '24px', textAlign: 'center' }}>
            Conversations
            {isLoading ? (
              <Spinner
                animation="border"
                role="status"
                variant="info"
                style={{
                  height: 30,
                  width: 30,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              />
            ) : (
              <div />
            )}
          </h3>
        </div>

        <div
          className="row"
          style={{
            width: '95%',
            alignSelf: 'center',
          }}
        >
          <div
            className="overflow-auto card"
            style={{
              position: 'fixed',
              top: 200,
              left: 40,
              height: '80%',
              padding: 0,
              width: '25%',
            }}
          >
            {jobsWithMessages.map((job) => renderJobCard(job))}
            {!jobId && !isFinalPage ? (
              <div onClick={() => dispatch(fetchJobsWithMessages({ page: searchParams.page + 1 }))}>
                <h4
                  style={{
                    color: '#3eb0ca',
                    marginTop: 20,
                    marginBottom: 20,
                    cursor: 'pointer',
                  }}
                >
                  Load more jobs...
                </h4>
              </div>
            ) : (
              <div />
            )}
          </div>
          <div
            className="overflow-auto"
            style={{
              position: 'fixed',
              top: 200,
              right: 20,
              height: '80%',
              width: '70%',
              padding: 0,
            }}
          >
            <div
              className="card"
              style={{ boxShadow: '0px 3px 10px -3px #738791', marginBottom: 30 }}
            >
              <div className="card-body">
                <h4
                  className="card-title mt-3"
                  style={{ marginTop: 30, textAlign: 'center', overflowY: 'hidden' }}
                >
                  Messages
                </h4>

                {conversations && conversations.length === 0 ? (
                  <text>Please, select job to see all messages...</text>
                ) : (
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '60%',
                        alignSelf: 'center',
                        marginTop: 30,
                        marginBottom: 20,
                        justifyContent: 'center',
                      }}
                    >
                      <h5 style={{ width: '50%', textAlign: 'right', marginRight: 10 }}>
                        Conversation is
                        {' '}
                      </h5>
                      <select
                        style-={{ width: '50%' }}
                        className="form-control"
                        value={selectedJob?.conversation_status}
                        onChange={(value) => {
                          dispatch(changeConversationStatus(currentJobId, value.target.value));
                        }}
                      >
                        <option value="open">Open</option>
                        <option value="closed">Closed</option>
                      </select>
                    </div>
                    {selectedJob && conversations.length !== 0
                      ? conversations.map((message) => renderMessages(message)) : <div />}
                    <div
                      style={{
                        display: 'flex',
                        flexDirection: 'row',
                        width: '100%',
                        alignItems: 'center',
                        justifyContent: 'center',
                        marginTop: 20,
                      }}
                    >
                      <textarea
                        className="form-colntrol"
                        ref={inputRef}
                        placeholder="Enter your message"
                        multiple
                        style={{
                          width: '100%',
                          borderColor: '#3eb0ca',
                          borderWidth: 1,
                          backgroundColor: 'white',
                          borderRadius: 20,
                          maxHeight: 100,
                        }}
                        value={adminMessage}
                        onChange={(value) => setAdminMessage(value.target.value)}
                      />

                      <img
                        src={chatSendButton}
                        style={{
                          width: 50,
                          height: 50,
                          marginLeft: 30,
                          cursor: 'pointer',
                        }}
                        alt="Send"
                        onClick={() => {
                          setAdminMessage('');
                          dispatch(postMessage(selectedJob?.id, adminMessage));
                        }}
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <div style={{ height: 50 }} />
      </div>
    </div>
  );
};
