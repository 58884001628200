import tempMee from '../apis/tempMee';
import {
  GET_ALL_USER_SMS_SUCCESS,
  GET_ALL_USER_SMS_REQUEST,
  GET_ALL_USER_SMS_FAILURE,
  SEND_SMS_FAILURE,
  SEND_SMS_SUCCESS,
  SEND_SMS_REQUEST,
  SEND_SMS_PHONES_REQUEST,
  SEND_SMS_PHONES_SUCCESS,
  SEND_SMS_PHONES_FAILURE,
  SHOW_MESSAGE,
} from './actionTypes';
import { getAuthHeaders } from './authActions';

export const fetchAllSMS = (phone, textline) => async (dispatch) => {
  dispatch({ type: GET_ALL_USER_SMS_REQUEST });
  try {
    const headers = await getAuthHeaders();

    const response = await tempMee.get('admin/textline', {
      headers: { ...headers, textline },
      params: { phone },
    });

    dispatch({ type: GET_ALL_USER_SMS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: GET_ALL_USER_SMS_FAILURE, payload: err });
  }
};

export const sendSMS = ({ phone, body, textline }) => async (dispatch) => {
  dispatch({ type: SEND_SMS_REQUEST });
  try {
    const headers = await getAuthHeaders();
    await tempMee.post(
      'admin/textline',
      { phone, body },
      {
        headers: { ...headers, textline },
      },
    );

    dispatch({ type: SEND_SMS_SUCCESS });
  } catch (err) {
    dispatch({ type: SEND_SMS_FAILURE, payload: err });
  }
};

export const sendSMSforPhones = ({ arrayPhoneNames, templateBody, textline }) => async (dispatch) => {
  dispatch({ type: SEND_SMS_PHONES_REQUEST });
  try {
    const headers = await getAuthHeaders();
    await tempMee.post(
      '/admin/textline/all',
      { arrayPhoneNames, templateBody },
      {
        headers: { ...headers, textline },
      },
    );

    dispatch({ type: SEND_SMS_PHONES_SUCCESS });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'Messages have been sent.' },
    });
  } catch (err) {
    dispatch({ type: SEND_SMS_PHONES_FAILURE, payload: err });
  }
};
