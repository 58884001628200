import {
  GET_ALL_UNVERIFIED_LICENSES_REQUEST,
  GET_ALL_UNVERIFIED_LICENSES_SUCCESS,
  CREDENTIAL_ACTION,
} from '../actions/actionTypes';

const defaultState = {
  licenses: [],
  totalLicenses: 0,
  searchParams: {
    page: 1,
    status: '',
    profession: '',
    state: '',
  },
  stateCredentials: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
  case GET_ALL_UNVERIFIED_LICENSES_REQUEST:
    return {
      ...state,
      searchParams: action.payload,
    };
  case GET_ALL_UNVERIFIED_LICENSES_SUCCESS:
    if (!action.payload.response) {
      return state;
    }

    return {
      ...state,
      licenses: action.payload.response,
      totalLicenses: action.payload.total,
    };
  case CREDENTIAL_ACTION.GET_ALL_STATE_CREDENTIALS_SUCCESS:
    if (!action.payload) {
      return state;
    }

    return {
      ...state,
      stateCredentials: action.payload,
    };
  default:
    return state;
  }
};
