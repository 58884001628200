import { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { triggerPayoutReferral } from '../../../actions/referralAction';
import Styles from '../../../themes/style.module.scss';
import CustomPortal from '../../commonComponents/CustomPortal';

export const ActionComponent = ({ row }) => {
  const dispatch = useDispatch();
  const isPayoutUser = useSelector((state) => state.users.isPayoutUser);
  const [payoutId, setPayoutId] = useState(null);
  const [showPayoutPopup, setShowPayoutPopup] = useState(false);
  const referrals = useSelector((state) => state.referral.referrals);

  const handlePayoutClick = (id) => {
    dispatch(triggerPayoutReferral(id));
  };

  const prepareText = () => {
    const referral = referrals.find((r) => r.id === payoutId);
    return (
      <div>
        <table>
          <tr>
            <th style={{ minWidth: 30, padding: 20, borderBottom: '1px solid' }}>Name</th>
            <th style={{ minWidth: 30, padding: 20, borderBottom: '1px solid' }}>Amount</th>
          </tr>
          <tr>
            <td style={{ minWidth: 30, padding: '10px 20px 0px 20px' }}>
              {row?.sender?.user_type === 'HYG' ? row?.sender?.full_name : row.sender?.office_name}
            </td>
            <td style={{ minWidth: 30, padding: '10px 20px 0px 20px' }}>
              ${referral.sender.amount}
            </td>
          </tr>
          <tr>
            <td style={{ minWidth: 30, padding: '5px 20px 0px 20px' }}>
              {row?.receiver?.user_type === 'HYG'
                ? row?.receiver?.full_name
                : row.receiver?.office_name}
            </td>
            <td style={{ minWidth: 30, padding: '5px 20px 0px 20px' }}>
              ${referral.receiver.amount}
            </td>
          </tr>
        </table>
      </div>
    );
  };

  return (
    <>
      <div
        className="col"
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        {isPayoutUser && row?.status === 'finished' ? (
          <button
            type="button"
            className={Styles.create_job_btn}
            style={{ margin: 4 }}
            onClick={() => {
              setPayoutId(row.id);
              setShowPayoutPopup(true);
            }}
          >
            Payout
          </button>
        ) : (
          '-'
        )}
      </div>

      {showPayoutPopup && payoutId ? (
        <CustomPortal
          title="Are you sure to payout for this referral?"
          text={prepareText(payoutId)}
          submitText="Payout"
          cancelText="Cancel"
          submitPressed={() => {
            handlePayoutClick(payoutId);
            setShowPayoutPopup(false);
            setPayoutId(null);
          }}
          cancelPressed={() => {
            setShowPayoutPopup(false);
            setPayoutId(null);
          }}
        />
      ) : null}
    </>
  );
};
