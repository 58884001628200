import React, { useCallback, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from 'react-bootstrap';
import { fetchOfficesWithFavoritedProfessional } from '../../actions/userActions';
import { createLoadingSelector } from '../../apis/selectors';
import history from '../../history';

const actions = ['FETCH_OFFICES_WITH_FAVORITED_PROFESSIONAL'];
const loadingSelector = createLoadingSelector(actions);

export default function HYGFavoritesList() {
  const dispatch = useDispatch();
  const officesWithFavoritedProfessional = useSelector(
    (state) => state.users.officesWithFavoritedProfessional,
  );
  const isLoading = useSelector((state) => loadingSelector(state));
  const userId = useSelector((state) => state.users.currentUser.id);

  const handleScrollList = useCallback(
    (event) => {
      const tableScrollHeight = event?.target?.scrollHeight - event?.target?.scrollTop - 20;
      const clientHeight = event?.target?.clientHeight;
      if (
        tableScrollHeight <= clientHeight &&
        !officesWithFavoritedProfessional.isActiveRequest &&
        !officesWithFavoritedProfessional.isFinal
      ) {
        dispatch(
          fetchOfficesWithFavoritedProfessional({
            userId,
            page: officesWithFavoritedProfessional.page + 1,
          }),
        );
      }
    },
    [officesWithFavoritedProfessional, dispatch, userId],
  );

  useEffect(() => {
    dispatch(fetchOfficesWithFavoritedProfessional({ userId }));
  }, [dispatch, userId]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        padding: '0px 90px',
        marginTop: '50px',
      }}
    >
      <div style={{ width: '100%', marginBottom: 20 }}>
        <h4 className="bold global_font">Offices that added this Professional as a Favorite</h4>
      </div>

      <div
        style={{ overflowY: 'scroll', width: '100%', maxHeight: 500 }}
        onScroll={handleScrollList}
      >
        <div
          style={{
            flex: 1,
            display: 'flex',
            width: '100%',
            border: '1px solid transparent',
            backgroundColor: '#B8D7E4',
          }}
        >
          <b style={{ padding: 15, fontSize: 16, textAlign: 'center', width: 300 }}>Action</b>
          <b style={{ padding: 15, fontSize: 16, flex: 1 }}>Office Name</b>
        </div>

        {officesWithFavoritedProfessional?.offices?.map(({ id, office_name: officeName }) => (
          <div style={{ border: '1px solid #efefef', display: 'flex', width: '100%' }}>
            <div style={{ padding: 15, width: 300, display: 'flex', justifyContent: 'center' }}>
              <a
                href={`/user/dentist/${id}`}
                style={{
                  fontSize: 15,
                  cursor: 'pointer',
                  textDecoration: 'underline',
                }}
                rel="noreferrer"
                onClick={(event) => {
                  event.preventDefault();
                  history.push(`/user/dentist/${id}`);
                }}
              >
                View Profile
              </a>
            </div>
            <span style={{ padding: 15, fontSize: 15 }}>{officeName}</span>
          </div>
        ))}

        {isLoading && (
          <div
            style={{ display: 'flex', justifyContent: 'center', marginTop: 10, marginBottom: 20 }}
          >
            <Spinner
              animation="border"
              role="status"
              variant="info"
              style={{
                height: 30,
                width: 30,
                marginLeft: 20,
                marginTop: 10,
              }}
            />
          </div>
        )}
      </div>

      {isLoading ||
        (!officesWithFavoritedProfessional?.offices?.length && (
          <div
            style={{
              border: '1px solid #efefef',
              display: 'flex',
              justifyContent: 'center',
              width: '100%',
              padding: '20px 0px',
            }}
          >
            <h4 className="bold global_font">
              This professional has not been added as a favorite yet.
            </h4>
          </div>
        ))}
    </div>
  );
}
