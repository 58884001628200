import React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';

export default ({ accounts, isPaymentSelected }) => {
  if (accounts.length === 0) {
    return (
      <h5
        style={{
          fontFamily: 'Nunito',
          fontWeight: 400,
          textAlign: 'center',
          color: '#435963',
          margin: 25,
        }}
      >
        There are no accounts for this user.
      </h5>
    );
  }

  return accounts.map((account, index) => (
    <div
      style={{
        width: '80%',
        height: '50px',
        borderRadius: '25px',
        margin: 20,
        boxShadow: '0px 2px 4px rgba(0,0,0,0.5)',
        border: '1px solid #efefef',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        padding: 10,
      }}
      key={index}
      onClick={() => isPaymentSelected(account.id)}
    >
      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
        <img
          src={require(account.type === 'card'
            ? '../../images/debitlime.png'
            : '../../images/bankdark.png')}
          alt="credit"
          style={{
            width: '40px',
            height: '40px',
            cursor: 'pointer',
            marginRight: '20px',
          }}
        />
        <h4
          style={{
            fontFamily: 'Nunito',
            color: '#495963',
          }}
        >
          <strong>{account.type === 'card' ? account.brand : account.bank_name}</strong>
          {' '}
          ••••
          {account.last4}
        </h4>
      </div>
      {account.is_selected ? (
        <img
          src={require('../../images/green-check.png')}
          alt="credit"
          style={{
            width: '40px',
            height: '40px',
            cursor: 'pointer',
          }}
        />
      ) : (
        <h4
          style={{
            fontFamily: 'Nunito',
            color: '#495963',
            marginLeft: 10,
            cursor: 'pointer',
          }}
        >
          Set as Default
        </h4>
      )}
    </div>
  ));
};
