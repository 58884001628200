import React from 'react';

const TabNavigator = ({ children, initialTab, currentTab, onTabChange }) => {
  const [activeTab, setActiveTab] = React.useState(currentTab || initialTab);

  // if same name exists, only the first one will be rendered
  const activeChild = React.Children.toArray(children).find(
    (child) => child.type.displayName === 'TabScreen' && child.props.name === activeTab,
  );

  const changeTab = (tab) => {
    setActiveTab(tab);
    if (onTabChange) {
      // hook notify parent component about tab change
      onTabChange(tab);
    }
  };

  React.useEffect(() => {
    if (currentTab) {
      // if external state (optional) being used to control tab, update active tab
      setActiveTab(currentTab);
    }
  }, [currentTab]);

  return (
    <div style={{ width: '100%' }}>
      {/* Render tab buttons */}
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
        }}
      >
        {React.Children.map(children, (child) => {
          if (child.type.displayName === 'TabScreen') {
            return (
              <button
                key={child.props.name}
                type="button"
                className="btn btn-info mr-1 ml-1"
                style={{
                  backgroundColor: activeTab === child.props.name ? '#4FC0CF' : '#307F92',
                  border: 'none',
                }}
                onClick={() => changeTab(child.props.name)}
              >
                {child.props.options?.title || child.props.name}
              </button>
            );
          }

          return null;
        })}
      </div>

      {/* Render active tab screen */}
      <div
        style={{
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        {activeChild ? React.cloneElement(activeChild) : null}
      </div>
    </div>
  );
};

export default TabNavigator;
