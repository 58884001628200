import React from 'react';
import { ClockOutAttestation } from './ClockOutAttestation';

const rows = [
  {
    title: 'Time In',
    renderItem: (item) => item?.clockInOut?.localTimeIn || 'N/A',
    columnWidth: 3,
  },
  {
    title: 'Time Out',
    renderItem: (item) => item?.clockInOut?.localTimeOut || 'N/A',
    columnWidth: 3,
  },
  {
    title: 'Break Start',
    renderItem: (item) => item?.clockInOut?.localBreakStart || 'N/A',
    invisible: (item) => item?.workerClassification !== 'employee',
    columnWidth: 3,
  },
  {
    title: 'Break End',
    renderItem: (item) => item?.clockInOut?.localBreakEnd || 'N/A',
    invisible: (item) => item?.workerClassification !== 'employee',
    columnWidth: 3,
  },
  {
    title: 'Time In Location',
    renderItem: (item) => {
      if (
        item?.clockInOut?.timeInWithinLoc === null ||
        item?.clockInOut?.timeInWithinLoc === undefined
      ) {
        return 'N/A';
      }

      return item?.clockInOut?.timeInWithinLoc ? 'Yes' : 'No';
    },
    columnWidth: 5,
  },
  {
    title: 'Time Out Location',
    renderItem: (item) => {
      if (
        item?.clockInOut?.timeOutWithinLoc === null ||
        item?.clockInOut?.timeOutWithinLoc === undefined
      ) {
        return 'N/A';
      }

      return item?.clockInOut?.timeOutWithinLoc ? 'Yes' : 'No';
    },
    columnWidth: 5,
  },
  {
    title: 'Clock In Type',
    renderItem: (item) => item?.clockInOut?.clockInType || 'N/A',
    columnWidth: 5,
  },
  {
    title: 'Clock Out Type',
    renderItem: (item) => item?.clockInOut?.clockOutType || 'N/A',
    columnWidth: 5,
  },
  {
    title: 'Reason For Leaving Early',
    renderItem: (item) => item?.clockInOut?.earlyDepartureReason?.title || 'N/A',
    columnWidth: 6,
  },
];

const ClockInOutDetails = ({ currentJob }) => (
  <div
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      fontFamily: 'Nunito',
    }}
  >
    <div
      className="card mt-5"
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: '80%',
        justifyContent: 'center',
        paddingTop: 20,
        paddingBottom: 50,
      }}
    >
      <div
        className="card-body"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="card-title" style={{ fontSize: 30, color: '#494963', marginLeft: 10 }}>
          Clock In / Clock Out
        </p>
      </div>

      {/* Header */}
      <div
        style={{
          display: 'flex',
          borderBottom: '1px solid #ededed',
          flex: 1,
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        {rows
          .filter((item) => !item.invisible || !item.invisible(currentJob))
          .map((item) => (
            <div key={item.title} style={{ flex: item.columnWidth }}>
              <p>
                <strong>{item.title}</strong>
              </p>
            </div>
          ))}
      </div>

      {/* Body */}
      <div
        style={{
          maxHeight: 500,
          overflowY: 'auto',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyItems: 'center',
            marginLeft: 10,
            marginRight: 10,
            paddingLeft: 10,
            paddingBottom: 10,
            paddingRight: 10,
          }}
        >
          <div
            style={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              border: '1px solid #efefef',
              borderRadius: 25,
              marginTop: 10,
              flex: 1,
            }}
          >
            {rows
              .filter((item) => !item.invisible || !item.invisible(currentJob))
              .map((item) => (
                <div key={item.title} style={{ flex: item.columnWidth }}>
                  <p style={{ margin: 0, padding: 10 }}>{item.renderItem(currentJob)}</p>
                </div>
              ))}
          </div>
        </div>
      </div>

      <div
        style={{
          marginTop: 13,
          marginLeft: 20,
          marginRight: 20,
          fontSize: 14,
        }}
      >
        <p>
          <strong>Comments:</strong>
        </p>
        <p>
          {currentJob?.adjustments?.[0]?.message ||
            currentJob?.clockInOut?.earlyDepartureReason?.comment ||
            'N/A'}
        </p>
      </div>
    </div>

    {currentJob?.clockInOut?.questionsCA && (
      <ClockOutAttestation answers={currentJob?.clockInOut?.questionsCA?.answers} />
    )}
  </div>
);

export default ClockInOutDetails;
