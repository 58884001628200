import React from 'react';
import EditsRenderData from './EditsRenderData';

export default ({ editList, isUserEdits }) => (
  <div
    className="card mt-5"
    style={{
      display: 'flex',
      flexDirection: 'column',
      minWidth: '80%',
      justifyContent: 'center',
    }}
  >
    <div
      className="card-body"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <h2 className="card-title" style={{ marginLeft: 10, fontFamily: 'Nunito', color: '#494963' }}>
        Edits
      </h2>
      <div
        style={{
          backgroundColor: '#b4b7ed',
          height: 40,
          width: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 20,
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <h3
          className="card-title"
          style={{
            color: '#fff',
            margin: 0,
            padding: 0,
          }}
        >
          {editList.length}
        </h3>
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        borderBottom: '1px solid #ededed',
        flex: 1,
        marginLeft: 20,
        marginRight: 20,
      }}
    >
      <div style={{ flex: 5 }}>
        <p style={{ fontFamily: 'Nunito', fontWeight: 700 }}>Date</p>
      </div>
      <div style={{ flex: 3 }}>
        <p style={{ fontFamily: 'Nunito', fontWeight: 700 }}>Author</p>
      </div>
      <div style={{ flex: 3 }}>
        <p style={{ fontFamily: 'Nunito', fontWeight: 700 }}>Type</p>
      </div>
      <div style={{ flex: 3 }}>
        <p style={{ fontFamily: 'Nunito', fontWeight: 700 }}>Old Value</p>
      </div>
      <div style={{ flex: 3 }}>
        <p style={{ fontFamily: 'Nunito', fontWeight: 700 }}>New Value</p>
      </div>
    </div>
    <EditsRenderData editList={editList} isUserEdits={isUserEdits} />
  </div>
);
