import React, { Component } from 'react';

import { connect } from 'react-redux';
import JobDetailsUserBox from '../Job/JobDetailsUserBox';
import UserSearchField from '../Job/UserSearchField';
import { blockHygienist, unblockHygienist } from '../../../actions/userActions';
import history from '../../../history';

class BlockedHygienist extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hygienist: null,
    };
  }

  unnlockHandeled = async (hyg) => {
    const { dentist, unblockHygienist } = this.props;
    unblockHygienist(dentist._id, hyg.id);
  };

  blockUser = async () => {
    const { dentist, blockHygienist } = this.props;
    const { hygienist } = this.state;
    const success = await blockHygienist(dentist._id, hygienist._id);
    if (success) {
      this.setState({ hygienist: undefined });
    }
  };

  goToHygProfile = (hyg) => {
    history.push(`/user/hygienist/${hyg.id}`, {
      user_id: hyg.id,
      profile: 'HYGIENIST',
    });
  };

  renderHyg = (hyg) => (
    <div
      key={hyg.id}
      style={{
        width: '100%',
        borderBottom: '1px solid #efefef',
        padding: '5px 10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <p className="card-text" style={{ width: '20%', textAlign: 'center' }}>
        {hyg.first_name}
      </p>
      <p className="card-text" style={{ width: '20%', textAlign: 'center' }}>
        {hyg.last_name}
      </p>
      <p
        className="card-text"
        style={{
          width: '50%',
          textAlign: 'center',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => this.goToHygProfile(hyg)}
      >
        {hyg.email_id}
      </p>
      <img
        src={require('../../../images/close-cross.png')}
        alt="remove"
        style={{ width: '20px', cursor: 'pointer' }}
        onClick={() => this.unnlockHandeled(hyg)}
      />
    </div>
  );

  renderTitle() {
    return (
      <div
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: '5px 10px',
          display: 'flex',
          justifyContent: 'space-between',
          backgroundColor: 'lightblue',
        }}
      >
        <p className="card-text" style={{ fontWeight: '600', width: '20%', textAlign: 'center' }}>
          First Name
        </p>
        <p className="card-text" style={{ fontWeight: '600', textAlign: 'center', width: '20%' }}>
          Last Name
        </p>
        <p className="card-text" style={{ fontWeight: '600', textAlign: 'center', width: '47%' }}>
          Email
        </p>
        <p className="card-text" style={{ fontWeight: '600' }}>
          Remove
        </p>
      </div>
    );
  }

  renderHygenistSearch() {
    if (this.state.hygienist) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'center',
            width: 200,
          }}
        >
          <p
            className="card-text"
            style={{ fontWeight: '600', width: '100%', textAlign: 'center' }}
          >
            Add Hygienist:
          </p>
          <JobDetailsUserBox
            user={this.state.hygienist}
            onCancel={(_) => this.setState({ hygienist: undefined })}
          />
          <button
            onClick={(_) => this.blockUser()}
            className="btn btn-danger"
            style={{ marginTop: 12 }}
          >
            Block Hygienist
          </button>
        </div>
      );
    }

    if (this.props.dentist) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'flex-start',
            alignItems: 'start',
            width: '20%',
          }}
        >
          <p
            className="card-text"
            style={{ fontWeight: '600', width: '100%', textAlign: 'center' }}
          >
            Add Hygienist:
          </p>
          <UserSearchField
            style={{ flex: 1 }}
            user_type="HYG"
            placeholder="Type a Hygienist Name"
            onSelectUser={(u) => this.setState({ hygienist: u })}
            except={this.props.dentist.blocked_hygienist.map((u) => u._id)}
          />
        </div>
      );
    }

    return <div />;
  }

  render() {
    const { dentist } = this.props;

    if (dentist) {
      return (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            height: '100%',
            marginTop: 20,
            width: '90%',
          }}
        >
          {this.renderHygenistSearch()}
          {dentist.blocked_hygienist.length !== 0 ? (
            <div className="card" style={{ width: '100%', height: '100%', marginLeft: 20 }}>
              {this.renderTitle()}
              {dentist.blocked_hygienist.map((hyg) => this.renderHyg(hyg))}
            </div>
          ) : (
            'There are no blocked hygienists'
          )}
        </div>
      );
    }

    return <div />;
  }
}

export default connect(null, { unblockHygienist, blockHygienist })(BlockedHygienist);
