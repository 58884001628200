import React, { useEffect, useCallback } from 'react';

import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { fetchUserReviews } from '../../../actions/userActions';

const LIMIT_VALUE = 15;

export default ({ goToJobProfile }) => {
  const dispatch = useDispatch();

  const {
    reviews, reviewsPagination, isFinalPage, isActiveRequest,
  } = useSelector((state) => state.users.reviews);

  useEffect(() => {
    dispatch(fetchUserReviews({ page: 1, limit: LIMIT_VALUE }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const profileContentSection = document.getElementById('profile-content');

    if (profileContentSection != null) {
      profileContentSection.addEventListener('scroll', handleReceiptScrolling);
    }

    return () => {
      if (profileContentSection != null) {
        profileContentSection.removeEventListener('scroll', handleReceiptScrolling);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewsPagination, reviews]);

  const handleReceiptScrolling = useCallback((event) => {
    // tolerance is now set to height of navbar when it's on bigger size state
    // need to dynamically get from `.navbar` component later
    const tolerance = 102
    const sectionHeight = event.target.getBoundingClientRect().height;
    const elementMaxScrollExtent = event.target.scrollHeight - tolerance;
    const scrollProgress = event.target.scrollTop + sectionHeight;

    if (scrollProgress >= elementMaxScrollExtent && !isActiveRequest && !isFinalPage) {
      dispatch(fetchUserReviews({ page: reviewsPagination?.page + 1, limit: LIMIT_VALUE }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewsPagination, reviews]);

  const formatAverageRating = (rating) => {
    if (rating) {
      return Number(rating).toFixed(1);
    }

    return rating;
  };

  const renderReview = (review) => (
    <tr
      key={review.id}
      style={{
        width: '100%',
        borderBottom: '1px solid #efefef',
        padding: '5px 10px',
        alignItems: 'center',
      }}

    >
      <td
        className="card-text"
        onClick={() => goToJobProfile(review.joboffer_id)}
        style={{
          cursor: 'pointer', textAlign: 'center', textDecoration: 'underline',
        }}
      >
        View Job
      </td>
      <td className="card-text table-value-text">{moment(review.joboffer_id?.job_datetime).format('ddd, MMM DD, YYYY')}</td>
      <td className="card-text table-value-text">{moment(review.createdAt).format('ddd, MMM DD, YYYY')}</td>

      {review.rating_by?.user_type === 'HYG' ? (
        <td className="card-text table-value-text">
          {review.rating_by?.first_name}
          {' '}
          {review.rating_by?.last_name || 'N/A'}
        </td>
      )
        : (
          <td className="card-text table-value-text">
            {review.rating_by?.office_name || 'N/A'}
          </td>
        )}

      <td className="card-text table-value-text">
        {review.rating_count || 'N/A'}
      </td>

      <td className="card-text table-value-text">
        {review.professionalSkills || 'N/A'}
      </td>

      <td className="card-text table-value-text">
        {review.interpersonalSkills || 'N/A'}
      </td>

      <td className="card-text table-value-text">
        {review.timeManagement || 'N/A'}
      </td>

      <td className="card-text table-value-text">
        {formatAverageRating(review.averageRating) || 'N/A'}
      </td>

      <td className="card-text table-value-text">
        {' '}
        {review.like_to_hire_again}
        {' '}
      </td>
      <td className="card-text table-value-text">
        {' '}
        {review.feedback_for_tempmee}
        {' '}
      </td>
    </tr>
  );

  const renderTitle = () => (
    <tr
      style={{
        width: '100%',
        borderBottom: '1px solid #efefef',
        padding: '5px 10px',
        backgroundColor: 'lightblue',
      }}
    >
      <td className="table-col-10 ard-text table-title-text">
        Shift
      </td>
      <td className="table-col-10 card-text table-title-text">
        Shift Date
      </td>
      <td className="table-col-10 card-text table-title-text">
        Created Date
      </td>

      <td className="table-col-15 card-text table-title-text">
        Name
      </td>
      <td className="card-text table-title-text">
        Review (Old)
      </td>
      <td className="card-text table-title-text">
        Professional Skills
      </td>
      <td className="card-text table-title-text">
        Interpersonal Skills
      </td>
      <td className="card-text table-title-text">
        Time Management
      </td>
      <td className="card-text table-title-text">
        AVG Rating
      </td>
      <td className="card-text table-title-text">
        Will Hire Again
      </td>
      <td className="table-col-10 card-text table-title-text">
        Feedback
      </td>
    </tr>
  );

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        marginTop: 20,
        width: '90%',
      }}
    >
      {reviews.length !== 0 ? (
        <div
          className="card"
          style={{
            width: '100%', height: '100%', marginLeft: 20, overflowY: 'scroll', overflowX: 'scroll',
          }}
        >
          <table>
            {renderTitle()}
            {reviews.map((review) => renderReview(review))}
          </table>
        </div>
      ) : (
        'There are no reviews'
      )}
    </div>
  );
};
