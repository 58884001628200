import React, { useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import { useDispatch, useSelector } from 'react-redux';
import CustomPaymentMethodsList from '../../commonComponents/CustomPaymentMethodsList';
import { updateCurrentUserPayment } from '../../../actions/userActions';

import '../../../themes/ProfilePaymentDNT.scss'

export default function ProfilePaymentDNT ({
  accounts,
  isPaymentSelected,
  onPreApprovedChanged,
  preApproved,
  onReceiptEnabledChanged,
  receiptEnabled,
  preApproveUser,
}) {
  const currentUser = useSelector((state) => state.users.currentUser);
  const isPayoutUser = useSelector((state) => state.users.isPayoutUser);

  const [editProfile, setEditProfile] = useState(false);

  const [rateLimit, setRateLimit] = useState(currentUser.rate_limit);
  const [rateLimitDA, setRateLimitDA] = useState(currentUser?.rate_limit_da);
  const [rateLimitDN, setRateLimitDN] = useState(currentUser?.rate_limit_dn);
  const [rateLimitFD, setRateLimitFD] = useState(currentUser?.rate_limit_fd);

  const [creditLimit, setCreditLimit] = useState(currentUser.credit_limit);
  const [bookingFee, setBookingFee] = useState(currentUser.booking_fee_ratio * 100);
  const [stripeEmailValue, setStripeEmail] = useState(currentUser.stripe_billing_email || '');

  const dispatch = useDispatch();

  /**
   * @param title
   * @param {string | undefined} info
   * @param {'number' | 'text'} type
   * @param {function(string)} onDidChange
   * @returns {React.JSX.Element}
   */
  const renderLine = ({
    title, info, type, onDidChange,
  }) => {
    const isNumberInput = type === 'number';
    const inputValue = isNumberInput && typeof info === 'string'
      ? parseInt(info.replace(/\D/g, ''), 10) ?? 0
      : info;

    return (
      <div
        className="container"
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="col-8 semi-bold">
          {title}
        </p>
        {editProfile ? (
          <input
            className={type ? 'form-control disabled-arrow-input' : 'form-control'}
            value={inputValue}
            type={type || 'text'}
            onChange={(e) => onDidChange(e.target.value)}
          />
        ) : (
          <p className="col-4" style={{ textAlign: 'right' }}>
            {info}
          </p>
        )}
      </div>
    );
  };

  const renderDentistPaymentInfo = () => (
    <div style={{ width: '100%' }}>
      <p
        className="card-title text-center"
        style={{
          marginTop: 30,
        }}
      >
        <b>Dental Hygienist limit:</b>

      </p>

      {renderLine({
        type: 'number',
        onDidChange: (val) => setRateLimit(val),
        title: 'Rate limit for RDH for all child offices',
        info: editProfile ? rateLimit : currentUser.rate_limit ? `$ ${currentUser.rate_limit}` : '-',
      })}

      <div
        className="container"
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="col-8 semi-bold">
          Rate limit for RDH (setting from master DSO office)
        </p>
        <p className="col-4" style={{ textAlign: 'right' }}>
          {currentUser.rate_limit_descend ? `$ ${currentUser.rate_limit_descend}` : '-'}
        </p>
      </div>
      <p
        className="card-title text-center"
        style={{
          marginTop: 30,
        }}
      >
        <b>Dental Assistant limit:</b>

      </p>
      {renderLine({
        type: 'number',
        onDidChange: (val) => setRateLimitDA(val),
        title: 'Rate limit for DA for all child offices',
        info: editProfile ? rateLimitDA : currentUser.rate_limit_da ? `$ ${currentUser.rate_limit_da}` : '-',
      })}

      <div
        className="container"
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="col-8 semi-bold">
          Rate limit for DA (setting from master DSO office)
        </p>
        <p className="col-4" style={{ textAlign: 'right' }}>
          {currentUser.rate_limit_da_descend ? `$ ${currentUser.rate_limit_da_descend}` : '-'}
        </p>
      </div>
      <p
        className="card-title text-center"
        style={{
          marginTop: 30,
        }}
      >
        <b>Associate Dentist limit:</b>
      </p>
      {renderLine({
        type: 'number',
        onDidChange: (val) => setRateLimitDN(val),
        title: 'Rate limit for DN for all child offices',
        info: editProfile ? rateLimitDN : currentUser.rate_limit_dn ? `$ ${currentUser.rate_limit_dn}` : '-',
      })}
      <div
        className="container"
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="col-8 semi-bold">
          Rate limit for DN (setting from master DSO office)
        </p>
        <p className="col-4" style={{ textAlign: 'right' }}>
          {currentUser.rate_limit_dn_descend ? `$ ${currentUser.rate_limit_dn_descend}` : '-'}
        </p>
      </div>

      <p
        className="card-title text-center"
        style={{
          marginTop: 30,
        }}
      >
        <b>Front Desk limit:</b>
      </p>
      {renderLine({
        type: 'number',
        onDidChange: (val) => setRateLimitFD(val),
        title: 'Rate limit for FD for all child offices',
        info: editProfile ? rateLimitFD : currentUser.rate_limit_fd ? `$ ${currentUser.rate_limit_fd}` : '-',
      })}
      <div
        className="container"
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
          padding: 5,
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <p className="col-8 semi-bold">
          Rate limit for FD (setting from master DSO office)
        </p>
        <p className="col-4" style={{ textAlign: 'right' }}>
          {currentUser.rate_limit_fd_descend ? `$ ${currentUser.rate_limit_fd_descend}` : '-'}
        </p>
      </div>

      <p
        className="card-title text-center"
        style={{
          marginTop: 30,
        }}
      >
        <b>Payments Settings:</b>
      </p>
      {renderLine({
        type: 'number',
        onDidChange: (val) => setCreditLimit(val),
        title: 'Credit Limit',
        info: editProfile ? creditLimit : `$ 
    ${new Intl.NumberFormat('en-US').format(currentUser.credit_limit)}`
          || '-',
      })}

      {renderLine({
        type: 'number',
        onDidChange: (val) => setBookingFee(val),
        title: 'Booking fee rate',
        info: editProfile
          ? bookingFee
          : `${currentUser?.booking_fee_ratio * 100}%`

          || '-',
      })}

      {renderLine({
        type: 'text',
        onDidChange: (val) => setStripeEmail(val),
        title: 'Billing Email',
        info: editProfile ? stripeEmailValue : stripeEmailValue || '-',
      })}
    </div>
  );

  const enableEdit = () => {
    setEditProfile(true);
  };

  const saveEditProfile = () => {
    const newBillingEmail = stripeEmailValue !== (currentUser?.stripe_billing_email || '')
      ? { stripe_billing_email: stripeEmailValue } : {};
    const newCreditLimit = Number(creditLimit) !== Number(currentUser?.credit_limit)
      ? { credit_limit: Number(creditLimit) } : {};
    const newRateLimit = Number(rateLimit) !== Number(currentUser?.rate_limit) && rateLimit
      ? { rate_limit: Number(rateLimit) } : {};
    const newRateLimitDA = Number(rateLimitDA) !== Number(currentUser?.rate_limit_da) && rateLimitDA
      ? { rate_limit_da: Number(rateLimitDA) } : {};
    const newRateLimitDN = Number(rateLimitDN) !== Number(currentUser?.rate_limit_dn) && rateLimitDN
      ? { rate_limit_dn: Number(rateLimitDN) } : {};
    const newRateLimitFD = Number(rateLimitFD) !== Number(currentUser?.rate_limit_fd) && rateLimitFD
      ? { rate_limit_fd: Number(rateLimitFD) } : {};
    const newBookingFee = Number(bookingFee) !== (Number(currentUser?.booking_fee_ratio) * 100)
      ? { booking_fee_ratio: Number(bookingFee) } : {};

    dispatch(updateCurrentUserPayment({
      ...newBillingEmail,
      ...newCreditLimit,
      ...newRateLimit,
      ...newRateLimitDA,
      ...newRateLimitDN,
      ...newRateLimitFD,
      ...newBookingFee,
    }));

    setEditProfile(false);
  };

  return (
    <div className="card profile-payment-dnt">
      <div className="card-body profile-payment-dnt-body">
        <h3 className="card-title text-center">
          <b>Payment Methods</b>
        </h3>

        <CustomPaymentMethodsList accounts={accounts || []} isPaymentSelected={isPaymentSelected} />

        <div
          className="card border-0"
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            flexDirection: 'column',
            marginTop: '25px',
            width: '80%',
          }}
        >
          <h3
            className="card-title text-center"
          >
            <b>Pre Approve</b>
          </h3>
          <div
            style={{
              margin: 25,
              width: '60%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p className="card-text" style={{ marginRight: '12px' }}>
              Is Pre Approved user
            </p>

            <select
              className="custom-select custom-select-lg mb-3"
              value={preApproved}
              onChange={(val) => onPreApprovedChanged(val.target.value)}
              disabled={!preApproveUser}
            >
              <option className="dropdown-item" value>
                Yes
              </option>
              <option className="dropdown-item" value={false}>
                No
              </option>
            </select>
          </div>

          <div
            style={{
              margin: 25,
              width: '60%',
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
          >
            <p className="card-text" style={{ marginRight: '12px' }}>
              Is Receipt enabled
            </p>

            <select
              className="custom-select custom-select-lg mb-3"
              value={receiptEnabled}
              onChange={(val) => onReceiptEnabledChanged(val.target.value)}
            >
              <option className="dropdown-item" value>
                Yes
              </option>
              <option className="dropdown-item" value={false}>
                No
              </option>
            </select>
          </div>
          <h3 className="card-title text-center">
            <b>Payment settings</b>
          </h3>
          {renderDentistPaymentInfo()}

          {bookingFee > 100 && editProfile
            ?
            <p style={{ color: 'red' }}>Booking Fee must be between 0 and 100 as number!</p> : null}

          <div
            className="actions"
          >
            {!editProfile && isPayoutUser ? (
              <button
                className="btn btn-info"
                type="button"
                onClick={() => enableEdit()}
              >
                Edit
              </button>
            ) : (
              ''
            )}

            {!editProfile ? (
              ''
            ) : (
              <button
                className="btn btn-secondary"
                type="button"
                onClick={() => setEditProfile(false)}
              >
                Cancel
              </button>
            )}

            {!editProfile ? (
              ''
            ) : (
              <button
                className="btn btn-success"
                type="button"
                onClick={() => saveEditProfile()}
              >
                Save
              </button>
            )}
          </div>
        </div>
      </div>
      <div style={{ height: 20 }} />
    </div>
  );
};
