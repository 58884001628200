import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Spinner } from 'react-bootstrap';
import { fetchProfessionalBadges } from '../../actions/metricsAction';
import { createLoadingSelector } from '../../apis/selectors';

export function Badges() {
  const dispatch = useDispatch();

  const userProfile = useSelector((state) => state.users.currentUser);
  const badges = useSelector((state) => state.metrics.badges);

  const loadingSelector = createLoadingSelector(['FETCH_HYG_BADGES']);
  const isLoading = useSelector((state) => loadingSelector(state));

  const noBadges = !badges?.length;

  useEffect(() => {
    dispatch(fetchProfessionalBadges(userProfile?.id));
  }, [userProfile]);

  const renderBadge = (badge) => {
    const { imageUrl, label, badgeCount, type } = badge;

    return (
      <div
        key={type}
        style={{
          display: 'flex',
          flexDirection: 'row',
          gap: 12,
        }}
      >
        <div
          style={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <img src={imageUrl} alt={label} width={65} height={65} />
          {badgeCount > 0 && (
            <span
              style={{
                position: 'absolute',
                bottom: 0,
                right: -18,
                backgroundColor: '#1C6B93',
                color: '#fff',
                borderRadius: 27,
                paddingInline: 10,
                fontSize: 15,
              }}
            >
              {badgeCount}
            </span>
          )}
        </div>
        <h1
          className="global_font"
          style={{
            fontSize: 18,
            color: '#435963',
            textAlign: 'center',
          }}
        >
          {label}
        </h1>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div style={{ display: 'flex', justifyContent: 'center', marginTop: '50px' }}>
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 30,
            width: 30,
            marginLeft: 20,
            marginTop: 10,
          }}
        />
      </div>
    );
  }

  return (
    <div
      className="card mt-3 ml-4 mr-4 mb-3"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        padding: '0px 90px',
      }}
    >
      <h1
        className="global_font f-dark"
        style={{
          fontSize: 30,
          marginTop: 45,
          marginLeft: 30,
          textAlign: 'center',
        }}
      >
        Badges
      </h1>

      <div
        style={{
          marginTop: 32,
          width: '100%',
          display: 'flex',
          border: '1px solid #EDEDED',
          borderRadius: 8,
          padding: '50px 44px',
          marginBlockEnd: 117,
        }}
      >
        {noBadges ? (
          <h1
            style={{
              fontSize: 18,
              fontWeight: 'bold',
              color: '#435963',
              margin: 'auto',
            }}
          >
            This professional has not earned any badges.
          </h1>
        ) : (
          <div
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(3, 1fr)',
              gap: '80px 20px',
              width: '100%',
              justifyContent: 'center',
            }}
          >
            {badges?.map((badge) => renderBadge(badge))}
          </div>
        )}
      </div>
    </div>
  );
}
