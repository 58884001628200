export const ACCOUNT_STATUS = {
  ALL: 'all',
  VERIFIED: 'verified',
  BLOCKED: 'blocked',
  PAUSED: 'paused',
  RESTRICTED: 'restricted',
  REMOVED: 'removed',
};

export const RAM_VERIFIED_STATUS = {
  ALL: 'all',
  UNVERIFIED: 'unverified',
  FAKE: 'fake',
  VERIFIED: 'verified',
}