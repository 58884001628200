import React from 'react';
import { useSelector } from 'react-redux';

export default () => {
  const { legalIdentityInformation } = useSelector((state) => state.users.currentUser);

  const formatDate = (dateObject) => {
    if (!dateObject) return '';
    const { year, month, day } = dateObject;
    const formattedDate = `${year}-${String(month).padStart(2, '0')}-${String(day).padStart(2, '0')}`;
    return formattedDate;
  };

  const openInStripeClicked = () => {
    window.open(`${process.env.REACT_APP_STRIPE_IDENTITY_LINK}${legalIdentityInformation?.verificationSessionId || ''}`, '_blank');
  };

  const renderRow = (title, info) => (
    <div
      style={{
        width: '100%',
        borderBottom: '1px solid #efefef',
        padding: '5px 10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <p className="card-text" style={{ fontWeight: '600' }}>
        {title}
      </p>
      <p className="card-text">{info}</p>
    </div>
  );

  return (
    <div style={{ width: '100%', padding: '20px' }}>
      {renderRow('First Name', legalIdentityInformation?.firstName)}
      {renderRow('Last Name', legalIdentityInformation?.lastName)}
      {renderRow('Date of Birth', formatDate(legalIdentityInformation?.dob))}
      {renderRow('Status', legalIdentityInformation?.status)}
      {renderRow('Document Type', legalIdentityInformation?.documentType)}
      {renderRow('Document I.D.', legalIdentityInformation?.documentId)}
      {renderRow('Expiration Date', formatDate(legalIdentityInformation?.expirationDate))}

      <div
        style={{
          textAlign: 'center',
          paddingTop: '25px',
        }}
      >
        <button
          className="btn btn-success"
          style={{ height: 34 }}
          onClick={openInStripeClicked}
          type="button"
        >
          Open in Stripe
        </button>
      </div>
    </div>
  );
};
