import React, { useEffect, useState } from 'react';
import dateFormat from 'dateformat';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { Spinner } from 'react-bootstrap';
import { editCancellationPenalty, fetchProfessionalStatsByDay } from '../../../actions/userActions';
import Styles from '../../../themes/style.module.scss';
import { createLoadingSelector } from '../../../apis/selectors';
import { UserType } from '../../../enums/UserType';

const CancellationPenaltyRow = ({
  cancellation, penaltyEdit, setRestrictionsUpdates, restrictionsUpdates,
}) => {
  const [penalty, setPenalty] = useState();

  useEffect(() => {
    if (!penaltyEdit) setPenalty(cancellation.penalty);
    else { setPenalty(restrictionsUpdates?.find((item) => item.id === cancellation.id)?.penalty || cancellation.penalty); }
  }, [penaltyEdit]);

  const renderCancellationStatus = () => {
    if ((cancellation.previous_status && cancellation.penalty === 'waved')) {
      return (
        <span>
          <span style={{ textDecoration: 'line-through' }}>
            {cancellation.previous_status}
          </span>
          {' '}
          -Waived
        </span>
      );
    } if (cancellation?.restricted_until) {
      return (
        <span>
          Restricted Until
          {' '}
          {dateFormat(new Date(cancellation?.restricted_until), 'ddd, mmmm dS, yyyy', { utc: true })}
        </span>
      );
    } return <span>{cancellation?.status}</span>;
  };

  const onHandleEditPenalty = (event) => {
    const { value } = event.target;
    setPenalty(value);
    setRestrictionsUpdates((prevState) => {
      const newState = [...prevState];
      const restrictionIndex = newState.findIndex((item) => item.id === cancellation.id);
      if (restrictionIndex === -1) { newState.push({ penalty: value, id: cancellation.id }); } else {
        newState[restrictionIndex] = { penalty: value, id: cancellation.id };
      }
      return newState;
    });
  };

  const renderEditPenalty = () => {
    if (penaltyEdit) {
      return (
        <select
          className="custom-select custom-select-lg"
          onChange={(event) => onHandleEditPenalty(event)}
          value={penalty}
        >
          <option key="yes" value="yes">
            Yes
          </option>
          <option key="no" value="no">
            No
          </option>
          <option key="waved" value="waved">
            Waived
          </option>
          <option key="no-show" value="no-show">
            No Show
          </option>
          <option key="walked-out" value="walked-out">
            Walked-Out
          </option>
        </select>
      );
    } return cancellation?.penalty === 'waved' ? 'waived' : cancellation?.penalty;
  };

  return (

    <tr style={{ border: '1px solid #efefef' }}>
      <td style={{ padding: 15, fontSize: 15, fontWeight: 'bold' }}>
        {cancellation?.addedAt && dateFormat(new Date(cancellation?.addedAt), 'ddd, mmmm dS, yyyy', { utc: true })}
      </td>
      <td style={{ padding: 15, fontSize: 15 }}>{cancellation?.cancellation_reason || '-'}</td>
      <td style={{ padding: 15, fontSize: 15, color: cancellation?.restricted_until ? '#D00019' : 'black' }}>

        {renderCancellationStatus()}
      </td>
      <td style={{ padding: 15, fontSize: 15, color: cancellation?.type ? '#D00019' : 'black' }}>
        {cancellation?.type || '--'}
      </td>
      <td style={{ padding: 15, fontSize: 15 }}>

        {renderEditPenalty()}
      </td>
      <td style={{
        padding: 15, fontSize: 14, textDecoration: 'underline', cursor: 'pointer',
      }}
      >
        <Link style={{ color: 'black' }} to={`/job/${cancellation.job_id}`} className="nav-link">
          View Job
        </Link>
      </td>
    </tr>
  );
};

const NotesCard = ({
  notes, submitNote, userId, isForShift, isProfessional,
}) => {
  const [content, setContent] = useState('');
  const [penaltyEdit, setPenaltyEdit] = useState(false);

  const currentUser = useSelector((state) => state.users.currentUser);
  const totalNoShowCount = useSelector((state) => state.users.totalNoShowByDayCount);
  const totalCancellationCount = useSelector((state) => state.users.totalCancellationByDayCount);
  const totalLateCancellationCount = useSelector((state) => state.users.totalLateCancellationByDayCount);
  const loadingSelector = createLoadingSelector(['FETCH_PROFESSIONAL_STATS_BY_DAY']);
  const isLoading = useSelector((state) => loadingSelector(state));

  const [cancellationHistories, setCancellationHistories] = useState(currentUser?.cancellation_histories || []);
  const [restrictionsUpdates, setRestrictionsUpdates] = useState([]);
  const [filter, setFilter] = useState('60_days');
  const [restrictionDate, setRestrictionDate] = useState(currentUser?.restricted_until);
  const dispatch = useDispatch();

  useEffect(() => {
    if (currentUser?.user_type === UserType.HYG) {
      const daysCount = filter === '60_days' ? 60 : null;
      dispatch(fetchProfessionalStatsByDay(currentUser?.id, daysCount));
    }
  }, [filter]);

  const getRestrictionLabel = () => {
    if (currentUser?.account_standing === 'restricted' && restrictionDate) {
      return `Restricted until ${dateFormat(restrictionDate, 'ddd, mmmm dS, yyyy', { utc: true })}`;
    }
    return 'No Restrictions';
  };

  const getNotePostedBy = (note) => {
    if (!note?.posted_by) {
      return 'SYSTEM USER';
    };

    return [note.posted_by.first_name, note.posted_by.last_name].filter((item) => item).join(' ');
  }

  const filterCancellation = (item) => (filter === '60_days' ? moment().diff(moment(item.addedAt), 'days') <= 60 : item);
  const renderCancellations = () => cancellationHistories?.slice()?.reverse()?.filter((item) => filterCancellation(item));

  const renderFilterOptions = () => (
    <select
      className="custom-select custom-select-lg"
      onChange={(event) => {
        setFilter(event.target.value);
      }}
      value={filter}
    >
      <option key="all" value="all">
        All Time
      </option>

      <option key="60_days" value="60_days">
        Last 60 days
      </option>
    </select>
  );

  return (
    <div
      style={{
        width: '100%',
        display: 'flex',
        justifyContent: 'space-evenly',
        alignItems: 'center',
        marginTop: '25px',
        marginBottom: '100px',
        flexDirection: 'column',
      }}
    >
      {isProfessional && (
        <div
          className="card"
          style={{
            margin: 25,
            width: '60%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            padding: 20,
          }}
        >
          {isLoading && (
            <Spinner
              animation="border"
              role="status"
              variant="info"
              style={{
                height: 30,
                width: 30,
                marginLeft: 20,
                marginTop: 10,
              }}
            />
          )}
          <div style={{
            display: 'flex', justifyContent: 'space-between', width: '100%',
          }}
          >
            <div style={{ flex: 1, marginTop: 24 }}>
              <p style={{ fontSize: 18, color: '#D00019' }}>
                {getRestrictionLabel()}
              </p>
            </div>
            <h2 style={{ flex: 1, textAlign: 'center' }}>Cancellation History</h2>
            <div style={{
              flex: 1, display: 'flex', justifyContent: 'flex-end', gap: 10,
            }}
            >
              <table className={Styles.professional_stats_table}>
                <tr>
                  <td>
                    <strong>Cancellation(s):</strong>
                    {' '}
                    {totalCancellationCount}
                  </td>
                  <td>
                    {renderFilterOptions()}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong>Penalized:</strong>
                    {' '}
                    {totalLateCancellationCount}
                  </td>
                  <td>
                    <strong>No Show:</strong>
                    {' '}
                    {totalNoShowCount}
                  </td>
                </tr>
              </table>
            </div>
          </div>
          <table style={{ width: '100%', marginTop: 20 }}>
            <tr style={{ backgroundColor: '#ADD8E6' }}>
              <th style={{ padding: 15, fontSize: 16 }}>Date</th>
              <th style={{ padding: 15, fontSize: 16 }}>Reason</th>
              <th style={{ padding: 15, fontSize: 16 }}>Status</th>
              <th style={{ padding: 15, fontSize: 16 }}>Type</th>
              <th style={{ padding: 15, fontSize: 16 }}>Penalty</th>
              <th style={{ padding: 15, fontSize: 16 }}>Shift</th>
            </tr>

            {renderCancellations()?.map((cancellation) => (
              <CancellationPenaltyRow cancellation={cancellation} penaltyEdit={penaltyEdit} setRestrictionsUpdates={setRestrictionsUpdates} restrictionsUpdates={restrictionsUpdates} />
            ))}

          </table>
          {(!penaltyEdit && cancellationHistories?.length > 0) && (
            <button
              className="btn btn-info"
              style={{ width: 94, height: 34, marginTop: 10 }}
              onClick={() => { setPenaltyEdit(true); }}
              type="button"
            >
              Edit
            </button>
          )}

          {penaltyEdit
            && (
              <div style={{ display: 'flex', flexDirection: 'row', gap: 15 }}>
                <button
                  className="btn btn-secondary"
                  style={{ width: 94, height: 34, marginTop: 10 }}
                  onClick={() => {
                    setPenaltyEdit(false);
                    setRestrictionsUpdates([]);
                  }}
                  type="button"
                >
                  Cancel
                </button>

                <button
                  className="btn btn-success"
                  style={{ width: 94, height: 34, marginTop: 10 }}
                  onClick={async () => {
                    setPenaltyEdit(false);
                    for (const item of restrictionsUpdates) {
                      await dispatch(editCancellationPenalty(item.penalty, item.id))
                        .then((res) => {
                          setCancellationHistories(res?.cancellation_histories);
                          setRestrictionDate(res?.restricted_until);
                        });
                    }
                    setRestrictionsUpdates([]);
                  }}
                  type="button"
                >
                  Save
                </button>
              </div>
            )}
        </div>
      )}

      <div
        className="card"
        style={{
          margin: 25,
          width: '60%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h3
          className="card-title"
          style={{
            fontFamily: 'Nunito',
            fontWeight: 700,
            textAlign: 'center',
            color: '#435963',
          }}
        >
          Notes
        </h3>
        <div className="card-body" style={{ width: '90%' }}>
          <ul className="list-group list-group-flush">
            {notes.map((note, key) => (
              <li
                id={key}
                className="list-group-item d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto card-text ">
                  <div>
                    {' '}
                    <span style={{ fontWeight: '700' }}>
                      {getNotePostedBy(note)}
                    </span>
                    <span
                      className="text-muted"
                      style={{ fontWeight: '200', marginLeft: 30, fontSize: '0.9em' }}
                    >
                      Posted on
                      {' '}
                      {dateFormat(new Date(note.created_at), 'ddd, mmmm dS, yyyy', { utc: true })}
                    </span>
                  </div>
                  {note.content}
                </div>
              </li>
            ))}
            {notes.length === 0 ? (
              <span>
                No notes for this
                {isForShift ? ' shift' : ' user'}
              </span>
            ) : null}
          </ul>

          <div className="form-group" px>
            <label htmlFor="postTextArea">Add a new note:</label>
            <textarea
              className="form-control"
              id="postTextArea"
              rows="3"
              value={content}
              onChange={(e) => setContent(e.target.value)}
            />
          </div>
          <button
            type="button"
            className="btn btn-primary"
            onClick={() => {
              submitNote(userId, content);
              setContent('');
            }}
          >
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

NotesCard.propTypes = {
  notes: PropTypes.arrayOf(
    PropTypes.shape({
      poster_name: PropTypes.string.isRequired,
      posted_by: PropTypes.string.isRequired,
      created_at: PropTypes.number.isRequired,
      content: PropTypes.string.isRequired,
    }),
  ).isRequired,
  submitNote: PropTypes.func.isRequired,
  userId: PropTypes.string.isRequired,
};

export default NotesCard;
