import moment from 'moment';

/**
 * Populate the initial form values
 * @param {object} job - The job object
 * @param {boolean} populateBreak - Whether to populate the break details
 * @returns {AdjustHoursFormValues} Form values - The initial form values

 * @typedef {Object} AdjustHoursFormValues
 * @property {Date} originalStartHour - The original start hour
 * @property {Date} originalEndHour - The original end hour
 * @property {number} originalBreakTime - The original break time
 * @property {Date} adjustedStartHour - The adjusted start hour
 * @property {Date} adjustedEndHour - The adjusted end hour
 * @property {number} adjustedBreakTime - The adjusted break time
 * @property {Date} breakStart - The break start time
 * @property {Date} breakEnd - The break end time
 */
export const populateInitialFormValues = (job, populateBreak = false) => {
  // Original start and end time
  const start = moment(
    `${job?.local_date} ${job?.local_start_time}`,
    'ddd, MMM DD, YYYY hh:mm a',
  ).toDate();
  const end = moment(
    `${job?.local_date} ${job?.local_end_time}`,
    'ddd, MMM DD, YYYY hh:mm a',
  ).toDate();

  // Adjusted start and end time
  const adjustedStart = job?.clockInOut?.localTimeIn
    ? moment(
        `${job?.local_date} ${job?.clockInOut?.localTimeIn}`,
        'ddd, MMM DD, YYYY hh:mm a',
    ).toDate()
    : start;
  const adjustedEnd = job?.clockInOut?.localTimeOut
    ? moment(
        `${job?.local_date} ${job?.clockInOut?.localTimeOut}`,
        'ddd, MMM DD, YYYY hh:mm a',
    ).toDate()
    : end;

  let breakStart = null;
  let breakEnd = null;
  let breakTime = job?.lunch_final_break_time;

  if (populateBreak && job?.clockInOut?.localBreakStart) {
    breakStart = moment(
      `${job?.local_date} ${job?.clockInOut?.localBreakStart}`,
      'ddd, MMM DD, YYYY hh:mm a',
    ).toDate();

    if (job?.clockInOut?.localBreakEnd) {
      breakEnd = moment(
        `${job?.local_date} ${job?.clockInOut?.localBreakEnd}`,
        'ddd, MMM DD, YYYY hh:mm a',
      ).toDate();
    } else {
      // If break end time is not available, then manually estimate the break end time
      const estimatedEnd = moment(job?.clockInOut?.breakStart).add(
        isEmptyBreakTime(job?.lunch_final_break_time, 60),
        'minutes',
      );
      breakEnd = estimatedEnd.isBefore(adjustedEnd) ? estimatedEnd.toDate() : adjustedEnd;
    }

    breakTime = moment(breakEnd)
      .startOf('minute')
      .diff(moment(breakStart).startOf('minute'), 'minutes');
  }

  return {
    originalStartHour: start,
    originalEndHour: end,
    originalBreakTime: job?.lunch_final_break_time,
    adjustedStartHour: adjustedStart,
    adjustedEndHour: adjustedEnd,
    adjustedBreakTime: breakTime,
    breakStart,
    breakEnd,
  };
};

/**
 * Formats the break time.
 * @param {number} breakTime - The break time in minutes.
 * @returns {string} - Formatted break time.
 */
export const formatBreak = (breakTime) => {
  if (breakTime == null) {
    return '--';
  }

  let formattedBreakTime = '';
  if (breakTime < 60) {
    formattedBreakTime = `${breakTime} minutes`;
  } else {
    const formattedHours = `${Math.floor(breakTime / 60)} hours`;
    const formattedMinutes = breakTime % 60 > 0 ? `${breakTime % 60} minutes` : '';
    formattedBreakTime = [formattedHours, formattedMinutes].filter(Boolean).join(' ');
  }

  return formattedBreakTime;
};

/**
 * Check if the break time is empty
 * @param {number} breakTime - The break time
 * @param {number} defaultBreakTime - The default break time if the break time is empty
 * @returns {number} - The break time
 */
export const isEmptyBreakTime = (breakTime, defaultBreakTime) => {
  if (!breakTime || breakTime === '00') {
    return defaultBreakTime;
  }

  return breakTime;
};
