import { GET_ALL_STATS_SUCCESS } from '../actions/actionTypes';

const initialState = {
  statsInfo: {},
  tableauInfo: null,
};
export default (state = initialState, action) => {
  switch (action.type) {
  case GET_ALL_STATS_SUCCESS:
    const { shiftsPerWeek } = action.payload;
    return {
      ...state,
      statsInfo: {
        ...action.payload,
        shiftsPerWeek: Object.keys(shiftsPerWeek)
          .map((key) => [key, shiftsPerWeek[key]])
          .reverse(),
      },
    };
  case 'GET_TABLEAU_SUCCESS':
    return {
      ...state,
      tableauInfo: action.payload,
    };
  default:
    return state;
  }
};
