import React from 'react';
import { CSVLink } from 'react-csv';
import momentTZ from 'moment-timezone';
import removeIcon from '../../../images/close-cross.png';
import history from '../../../history';
import { InlineLinkButton } from '../../commonComponents/InlineLinkButton';

const styles = {
  tableTextComponents: {
    fontFamily: 'Nunito',
    margin: 5,
    textAlign: 'center',
  },
};

export default function CustomHygienistList ({
  array,
  title,
  currentJob,
  redirectToProfile,
  declineCounterOffer,
  confirmCounterOffer,
  redirectToSms,
  onRemoveClicks,
}) {
  const availableHygienistChart = () => {
    let arr = array;
    const notViewed = title === 'Not Viewed';

    if (notViewed) {
      const ignoredUserId = currentJob.offer_ignored.map((offer) => offer._id);
      arr = array ? array.filter((el) => !ignoredUserId.includes(el._id)) : [];
    }

    return (
      <div style={{ minHeight: 200, maxHeight: 500, overflowY: 'auto', borderBottom: '1px solid #efefef' }}>
        {arr.length > 0 ? (
          arr.map((hyg) => (
            <div
              key={hyg._id}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyItems: 'center',
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  width: !notViewed ? 550 : 480,
                  border: '1px solid #efefef',
                  borderRadius: 25,
                  marginTop: 10,
                  flex: 1,
                }}
                onClick={() => redirectToProfile(hyg._id)}
              >
                <p
                  style={{
                    fontFamily: 'Nunito',
                    margin: 0,
                    padding: '10px 10px',
                    flex: 3,
                  }}
                >
                  {`${hyg.first_name} ${hyg.last_name}`}
                </p>
                <p
                  style={{
                    fontFamily: 'Nunito',
                    margin: 0,
                    padding: '10px 5px',

                    flex: 4,
                  }}
                >
                  {hyg.email_id}
                </p>
                {onRemoveClicks && (
                  <div style={{ paddingInline: '15px' }}>
                    <img
                      onClick={(e) => {
                        e.stopPropagation();
                        onRemoveClicks(hyg._id);
                      }}
                      src={removeIcon}
                      style={{ width: 15, height: 15, cursor: 'pointer' }}
                      className='hover-enlarge'
                      alt="remove_icon"
                    />
                  </div>
                )}
              </div>
            </div>
          ))
        ) : (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <p style={{ fontFamily: 'Nunito', fontSize: 18, alignText: 'center' }}>No Activity</p>
          </div>
        )}
      </div>
    );
  };

  const renderCounterTable = (hyg) => (
    <div className="card" style={{ alignSelf: 'center' }}>
      <div
        className="card-body"
        style={{
          padding: 0,
          flex: 1,
          width: '100%',
          alignItems: 'center',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          borderBottom: '1px solid #efefef',
          justifyItems: 'center',
        }}
        >
          <strong style={{ ...styles.tableTextComponents, width: '30%' }}>
            Rate
          </strong>
          <strong style={{ ...styles.tableTextComponents, width: '70%' }}>
            Hours
          </strong>
        </div>
        <div style={{
          flex: 1,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyItems: 'center',
          borderBottom: '1px solid #efefef',
        }}
        >
          <div style={{ ...styles.tableTextComponents, width: '30%', color: !hyg.rate ? '#aeea00' : '#f4511d' }}>
            $
            {hyg?.rate || currentJob.rate}
          </div>
          <div style={{ ...styles.tableTextComponents, width: '70%', color: (hyg?.local_begin && hyg?.local_end) ? '#f4511d' : '#aeea00' }}>
            {
              (hyg?.local_begin && hyg?.local_end)
                ? `${hyg.local_begin} - ${hyg.local_end}`
                : `${currentJob.local_start_time} - ${currentJob.local_end_time}`
            }
          </div>
        </div>
      </div>
    </div>
  );

  const renderCounterAcceptedList = () => {
    const counterUser = currentJob.offer_counters;
    return (
      <div
        className="card mt-5"
        style={{
          width: '80%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          className="card-body"
        >
          <h2
            className="card-title"
            style={{ marginLeft: 10, fontFamily: 'Nunito', color: '#494963' }}
          >
            Accepted & Counters
          </h2>
          <div
            style={{
              backgroundColor: '#aeea00',
              height: 40,
              width: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 20,
              marginLeft: 10,
              marginRight: 10,
            }}
          >

            <h3
              className="card-title"
              style={{
                fontWeight: 700,
                color: '#fff',
                margin: 0,
                padding: 0,
              }}
            >
              {counterUser.length}
            </h3>

          </div>
          {
            redirectToSms ? (
              <button
                type="button"
                className="btn btn-success"
                onClick={redirectToSms}
                style={{
                  width: 150,
                  height: 30,
                  borderRadius: 5,
                  marginTop: 5,
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                  boxShadow: '1px 1px 4px',
                }}
              >
                SMS Blast
              </button>
            ) : <div style={{ minWidth: 100 }} />
          }
        </div>
        <div
          style={{
            display: 'flex',
            borderBottom: '1px solid #ededed',
            flex: 1,
            width: '90%',
            marginLeft: 20,
            marginRight: 20,
          }}
        >
          <div
            style={{
              width: '10%',
              fontFamily: 'Nunito',
              fontWeight: 700,
            }}
          >
            Status
          </div>
          <div
            style={{
              width: '25%',
              fontFamily: 'Nunito',
              fontWeight: 700,
            }}
          >
            Name
          </div>
          <div style={{ width: '25%', fontFamily: 'Nunito', fontWeight: 700 }}>Email</div>
          <div style={{ width: '25%', fontFamily: 'Nunito', fontWeight: 700 }}>Created At</div>
          <div
            style={{
              width: '40%',
              fontFamily: 'Nunito',
              fontWeight: 700,
            }}
          >
            Counter
          </div>
          <div
            style={{
              width: '25%',
              fontFamily: 'Nunito',
              fontWeight: 700,
            }}
          >
            Expiration
          </div>
          <div style={{ width: '10%', fontFamily: 'Nunito', fontWeight: 700 }}>Action</div>
        </div>
        <div style={{ minHeight: 200, maxHeight: 500, overflowY: 'auto', borderBottom: '1px solid #efefef' }}>
          {counterUser.length > 0 ? (
            counterUser.map((hyg) => (
              <div
                key={hyg.requestor?.id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyItems: 'center',
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    border: '1px solid #efefef',
                    borderRadius: 25,
                    marginTop: 10,
                    flex: 1,
                    width: '95%',
                    justifyContent: 'center',
                  }}
                  onClick={() => redirectToProfile(hyg.requestor?.id)}
                >
                  <div
                    style={{
                      fontFamily: 'Nunito',
                      margin: 0,
                      padding: 10,
                      width: '10%',
                      color:
                        hyg.status === 'declined' || hyg.status === 'expired' ? 'red' : 'green',
                      fontWeight: 'bold',
                    }}
                  >
                    {hyg.status}
                  </div>
                  <div
                    style={{
                      fontFamily: 'Nunito',
                      margin: 0,
                      padding: 10,
                      width: '25%',
                    }}
                  >
                    {`${hyg.requestor?.first_name} ${hyg.requestor?.last_name}`}
                  </div>
                  <div
                    style={{
                      fontFamily: 'Nunito',
                      margin: 0,
                      padding: 10,
                      width: '25%',
                    }}
                  >
                    {hyg.requestor?.email_id}
                  </div>
                  <div
                    style={{
                      fontFamily: 'Nunito',
                      margin: 0,
                      padding: 10,
                      width: '25%',
                    }}
                  >
                    {momentTZ
                      .tz(hyg?.created_at, currentJob?.offer_owner?.time_zone)
                      .format('YYYY-MM-DD HH:mm A') || 'N/A'}
                  </div>
                  <div
                    style={{
                      fontFamily: 'Nunito',
                      margin: 0,
                      padding: 10,
                      fontWeight: 700,
                      width: '40%',
                    }}
                  >
                    {renderCounterTable(hyg)}
                  </div>

                  <div
                    style={{
                      fontFamily: 'Nunito',
                      margin: 0,
                      padding: 10,
                      width: '25%',
                    }}
                  >
                    {momentTZ
                      .tz(hyg?.expiration, currentJob?.offer_owner?.time_zone)
                      .format('YYYY-MM-DD HH:mm A') || 'N/A'}
                  </div>
                </div>
                {currentJob.status === 'open'
                  || currentJob.status === 'counter'
                  || currentJob.status === 'pending' ? (
                    <div
                      style={{
                        width: '10%',
                        alignContent: 'center',
                        display: 'flex',
                        justifyContent: 'center',
                        padding: 5,
                        flexDirection: 'row',
                        marginLeft: 20,
                      }}
                    >
                      <button
                        className="btn btn-success mr-1"
                        onClick={() => confirmCounterOffer(hyg)}
                      >
                        Confirm
                      </button>
                      {hyg.status === 'active' ? (
                        <button
                          className="btn btn-danger mr-1"
                          onClick={() => declineCounterOffer(hyg)}
                        >
                          Decline
                        </button>
                      ) : (
                        <div />
                      )}
                    </div>
                  ) : (
                    <div style={{ width: '20%' }} />
                  )}
              </div>
            ))
          ) : (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <p style={{ fontFamily: 'Nunito', fontSize: 18, alignText: 'center' }}>No Activity</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderFavoriteInvitesList = () => {
    const invites = currentJob.favoriteInvites;
    return (
      <div
        className="card mt-5"
        style={{
          width: '80%',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
          className="card-body"
        >
          <h2
            className="card-title"
            style={{ marginLeft: 10, fontFamily: 'Nunito', color: '#494963' }}
          >
            {title}
          </h2>
          <div
            style={{
              backgroundColor: '#aeea00',
              height: 40,
              width: 40,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              borderRadius: 20,
              marginLeft: 10,
              marginRight: 10,
            }}
          >

            <h3
              className="card-title"
              style={{
                fontWeight: 700,
                color: '#fff',
                margin: 0,
                padding: 0,
              }}
            >
              {invites.length}
            </h3>

          </div>
          <div style={{ minWidth: 100 }} />
        </div>
        <div
          style={{
            display: 'flex',
            borderBottom: '1px solid #ededed',
            flex: 1,
            width: '95%',
            marginLeft: 20,
            marginRight: 20,
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              width: '10%',
              fontFamily: 'Nunito',
              fontWeight: 700,
            }}
          >
            Status
          </div>
          <div
            style={{
              width: '40%',
              fontFamily: 'Nunito',
              fontWeight: 700,
            }}
          >
            Name
          </div>
          <div style={{ width: '40%', fontFamily: 'Nunito', fontWeight: 700 }}>Email</div>
          <div style={{ width: '40%', fontFamily: 'Nunito', fontWeight: 700 }}>Created At</div>
        </div>
        <div style={{
          minHeight: 200, maxHeight: 500, width: '95%', overflowY: 'auto', borderBottom: '1px solid #efefef',
        }}
        >
          {invites.length > 0 ? (
            invites.map((invite) => (
              <div
                key={invite.professional?.id}
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  alignItems: 'center',
                  justifyItems: 'center',
                  marginLeft: 10,
                  marginRight: 10,
                }}
              >
                <div
                  style={{
                    display: 'flex',
                    border: '1px solid #efefef',
                    borderRadius: 25,
                    marginTop: 10,
                    flex: 1,
                    // justifyContent: 'center',
                  }}
                  onClick={() => redirectToProfile(invite.professional?.id)}
                >
                  <div
                    style={{
                      fontFamily: 'Nunito',
                      margin: 0,
                      padding: 10,
                      width: '10%',
                      color:
                        invite.status === 'declined' || invite.status === 'expired' ? 'red' : 'green',
                      fontWeight: 'bold',
                    }}
                  >
                    {invite.status}
                  </div>
                  <div
                    style={{
                      fontFamily: 'Nunito',
                      margin: 0,
                      padding: 10,
                      width: '40%',
                    }}
                  >
                    {`${invite.professional?.first_name} ${invite.professional?.last_name}`}
                  </div>
                  <div
                    style={{
                      fontFamily: 'Nunito',
                      margin: 0,
                      padding: 10,
                      width: '40%',
                    }}
                  >
                    {invite.professional?.email_id}
                  </div>
                  <div
                    style={{
                      fontFamily: 'Nunito',
                      margin: 0,
                      padding: 10,
                      width: '40%',
                    }}
                  >
                    {momentTZ
                      .tz(invite?.created_at, currentJob?.offer_owner?.time_zone)
                      .format('YYYY-MM-DD HH:mm A') || 'N/A'}
                  </div>
                </div>
                {/* <div style={{ width: '20%' }} /> */}
              </div>
            ))
          ) : (
            <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
              <p style={{ fontFamily: 'Nunito', fontSize: 18, alignText: 'center' }}>No Activity</p>
            </div>
          )}
        </div>
      </div>
    );
  };

  const renderClickViews = (jobViews) => (
    <div
      className="card mt-5"
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        justifyContent: 'center',
      }}
    >
      <div
        className="card-body"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2
          className="card-title"
          style={{ marginLeft: 10, fontFamily: 'Nunito', color: '#494963' }}
        >
          {title}
        </h2>
        <div
          style={{
            backgroundColor: '#aeea00',
            height: 40,
            width: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 20,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <h3
            className="card-title"
            style={{
              fontWeight: 700,
              color: '#fff',
              margin: 0,
              padding: 0,
            }}
          >
            {jobViews.length}
          </h3>
        </div>
        {
          redirectToSms ? (
            <button
              type="button"
              className="btn btn-success"
              onClick={redirectToSms}
              style={{
                width: 150,
                height: 30,
                borderRadius: 5,
                marginTop: 5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '1px 1px 4px',
              }}
            >
              SMS Blast
            </button>
          ) : <div style={{ minWidth: 100 }} />
        }
      </div>
      <div
        style={{
          display: 'grid',
          gridTemplateColumns: 'repeat(3, 1fr)',
          borderBottom: '1px solid #ededed',
          flex: 1,
          marginInline: 20,
          fontFamily: 'Nunito',
          fontWeight: 'bold',
        }}
      >
        <p>Professional Name</p>
        <p>Office Name</p>
        <p>Created At</p>
      </div>

      <div style={{
        minHeight: 200,
        maxHeight: 500,
        overflowY: 'auto',
        borderBottom: '1px solid #efefef',
      }}>
        {jobViews.length > 0 ? (
          jobViews.map(({
            job_id: jobId,
            professional_id: professionalId,
            professional_name: professionalName,
            office_id: officeId,
            office_name: officeName,
            created_at: seenAt
          }) => (
            <div
              key={jobId}
              style={{
                display: 'flex',
                flexDirection: 'row',
                alignItems: 'center',
                justifyItems: 'center',
                marginLeft: 10,
                marginRight: 10,
              }}
            >
              <div
                style={{
                  display: 'grid',
                  gridTemplateColumns: 'repeat(3, 1fr)',
                  width: '100%',
                  border: '1px solid #efefef',
                  borderRadius: 25,
                  marginTop: 10,
                  fontFamily: 'Nunito',
                }}
              >
                <InlineLinkButton onClicked={() => redirectToProfile(professionalId)}>
                  {professionalName}
                </InlineLinkButton>

                <InlineLinkButton onClicked={() => {
                  history.push(`/user/office/${officeId}`);
                }}>
                  {officeName}
                </InlineLinkButton>

                <p style={{ margin: 0, padding: '10px 5px' }}>
                  {seenAt}
                </p>
              </div>
            </div>
          ))
        ) : (
          <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
            <p style={{ fontFamily: 'Nunito', fontSize: 18, alignText: 'center' }}>No Activity</p>
          </div>
        )}
      </div>
    </div>
  );

  if (array && title === 'Clicked') {
    return renderClickViews(array);
  }

  if (!array && !title) {
    return renderCounterAcceptedList();
  }

  if (!array && title === 'Invites') {
    return renderFavoriteInvitesList();
  }

  const notViewed = title === 'Not Viewed';
  const headers = [
    { label: 'First Name', key: 'first_name' },
    { label: 'Last Name', key: 'last_name' },
    { label: 'Email', key: 'email_id' },
    { label: 'Contact', key: 'contact_number' },
  ];
  let counter = array?.length || 0;
  if (notViewed) {
    counter -= currentJob.offer_ignored.length;
  }

  return (
    <div
      className="card mt-5"
      style={{
        display: 'flex',
        flexDirection: 'column',
        width: '80%',
        justifyContent: 'center',
      }}
    >
      <div
        className="card-body"
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <h2
          className="card-title"
          style={{ marginLeft: 10, fontFamily: 'Nunito', color: '#494963' }}
        >
          {title}
        </h2>
        <div
          style={{
            backgroundColor: title === 'Unavailable' ? 'red' : notViewed ? 'orange' : '#aeea00',
            height: 40,
            width: 40,
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            borderRadius: 20,
            marginLeft: 10,
            marginRight: 10,
          }}
        >
          <h3
            className="card-title"
            style={{
              fontWeight: 700,
              color: '#fff',
              margin: 0,
              padding: 0,
            }}
          >
            {counter}
          </h3>
        </div>
        {
          redirectToSms ? (
            <button
              type="button"
              className="btn btn-success"
              onClick={redirectToSms}
              style={{
                width: 150,
                height: 30,
                borderRadius: 5,
                marginTop: 5,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                boxShadow: '1px 1px 4px',
              }}
            >
              SMS Blast
            </button>
          ) : <div style={{ minWidth: 100 }} />
        }
        {notViewed ? (
          <CSVLink
            className="btn btn-success"
            data={array}
            headers={headers}
            filename="not_viewed.csv"
            style={{
              width: 150,
              height: 30,
              borderRadius: 5,
              marginTop: 5,
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
              boxShadow: '1px 1px 4px',
            }}
            target="_blank"
          >
            <p
              style={{
                fontFamily: 'Nunito',
                fontWeight: 700,
                padding: 0,
                margin: 0,
              }}
            >
              Export to CSV
            </p>
          </CSVLink>
        ) : (
          ''
        )}
      </div>
      <div
        style={{
          display: 'flex',
          borderBottom: '1px solid #ededed',
          flex: 1,
          marginLeft: 20,
          marginRight: 20,
        }}
      >
        <div style={{ flex: 3 }}>
          <p style={{ fontFamily: 'Nunito', fontWeight: 700 }}>Name</p>
        </div>
        <div style={{ flex: 4 }}>
          <p style={{ fontFamily: 'Nunito', fontWeight: 700 }}>Email</p>
        </div>
      </div>
      {availableHygienistChart()}
    </div>
  );
};

