import React, { useState } from 'react';
import moment from 'moment';
import { breakTimes } from '../../../constants';
import closeIcon from '../../../images/close-blue.png';
import AdjustmentRowItem from './Adjustments/AdjustmentRowItem';
import { showClockInStatesW2 } from '../../../growthbook';
import { formatBreak, populateInitialFormValues } from './Adjustments/utils';

export default ({ isShow, closeButtonAction, confirmButtonAction, currentJob }) => {
  const [state, setState] = useState({});

  const clockInStates = showClockInStatesW2()?.split(',');
  const isNewBreakFlowEnabled =
    currentJob?.workerClassification === 'employee' &&
    clockInStates?.includes(currentJob?.offer_owner?.address?.[0]?.state);

  useState(() => {
    if (currentJob) {
      const { adjustedStartHour, adjustedEndHour, breakStart, breakEnd, adjustedBreakTime } =
        populateInitialFormValues(currentJob, isNewBreakFlowEnabled);

      setState({
        submitBy: '',
        localStart: moment(adjustedStartHour).format('HH:mm'),
        localEnd: moment(adjustedEndHour).format('HH:mm'),
        breakStart: breakStart ? moment(breakStart).format('HH:mm') : '',
        breakEnd: breakEnd ? moment(breakEnd).format('HH:mm') : '',
        break: adjustedBreakTime,
      });
    }
  }, [currentJob]);

  if (!isShow) {
    return <div />;
  }

  const disabled =
    !state.submitBy ||
    !state.localStart ||
    !state.localEnd ||
    !state.break ||
    (state.breakStart && !state.breakEnd) ||
    (state.breakEnd && !state.breakStart);

  const handleBreakChange = (newFormValue) => {
    const { breakStart, breakEnd } = newFormValue;

    if (breakStart && breakEnd) {
      const breakTime = moment(breakEnd, 'HH:mm').diff(moment(breakStart, 'HH:mm'), 'minutes');
      setState({ ...newFormValue, break: String(breakTime) });
    } else {
      setState({ ...newFormValue, break: null });
    }
  };

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        width: isNewBreakFlowEnabled ? '535px' : '280px',
      }}
      className="card border-0"
    >
      <img
        onClick={closeButtonAction}
        src={closeIcon}
        style={{
          width: 24,
          height: 24,
          cursor: 'pointer',
          alignSelf: 'flex-end',
        }}
        alt="remove_icon"
      />
      <h2
        className="card-title"
        style={{
          color: '#494963',
          fontSize: 24,
          paddingBottom: 20,
          paddingInline: 30,
        }}
      >
        Submit Adjustment
      </h2>
      <AdjustmentRowItem
        direction={isNewBreakFlowEnabled ? 'row' : 'column'}
        leftComponent={
          <div
            style={{
              padding: '5px 10px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <p className="card-text" style={{ fontWeight: '600', alignSelf: 'end' }}>
              Submit by:
            </p>
            <select
              className="form-control"
              style={{ width: 130 }}
              value={state.submitBy}
              onChange={(e) => setState({ ...state, submitBy: e.target.value })}
            >
              <option value="" disabled>
                Select One
              </option>
              <option value="Professional">Professional</option>
              <option value="Office">Office</option>
            </select>
          </div>
        }
      />
      <AdjustmentRowItem
        direction={isNewBreakFlowEnabled ? 'row' : 'column'}
        leftComponent={
          <div
            style={{
              width: '100%',
              padding: '5px 10px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <p className="card-text" style={{ fontWeight: '600', alignSelf: 'end' }}>
              Start Time:
            </p>
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
              }}
            >
              <input
                type="time"
                className="form-control"
                style={{ width: 130 }}
                value={state.localStart}
                onChange={(e) => setState({ ...state, localStart: e.target.value })}
              />
            </div>
          </div>
        }
        rightComponent={
          <div
            style={{
              width: '100%',
              padding: '5px 10px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <p className="card-text" style={{ fontWeight: '600', alignSelf: 'end' }}>
              End Time:
            </p>
            <div
              style={{
                flexDirection: 'row',
                display: 'flex',
              }}
            >
              <input
                type="time"
                className="form-control"
                style={{ width: 130 }}
                value={state.localEnd}
                onChange={(e) => setState({ ...state, localEnd: e.target.value })}
              />
            </div>
          </div>
        }
      />
      {isNewBreakFlowEnabled && (
        <AdjustmentRowItem
          leftComponent={
            <div
              style={{
                width: '100%',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600', alignSelf: 'end' }}>
                Break Start:
              </p>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                }}
              >
                <input
                  type="time"
                  className="form-control"
                  style={{ width: 130 }}
                  value={state.breakStart}
                  onChange={(e) => handleBreakChange({ ...state, breakStart: e.target.value })}
                />
              </div>
            </div>
          }
          rightComponent={
            <div
              style={{
                width: '100%',
                padding: '5px 10px',
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <p className="card-text" style={{ fontWeight: '600', alignSelf: 'end' }}>
                Break End:
              </p>
              <div
                style={{
                  flexDirection: 'row',
                  display: 'flex',
                }}
              >
                <input
                  type="time"
                  className="form-control"
                  style={{ width: 130 }}
                  value={state.breakEnd}
                  onChange={(e) => handleBreakChange({ ...state, breakEnd: e.target.value })}
                />
              </div>
            </div>
          }
        />
      )}

      {isNewBreakFlowEnabled ? (
        <div
          className="f-16"
          style={{
            padding: '20px 10px',
          }}
        >
          <strong>Break Duration:</strong> {formatBreak(state.break)}
        </div>
      ) : (
        <div
          style={{
            width: '100%',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p className="card-text" style={{ fontWeight: '600', alignSelf: 'end' }}>
            Break Time:
          </p>

          <select
            className="form-control"
            style={{ width: 130 }}
            value={state.break}
            onChange={(e) => setState({ ...state, break: e.target.value })}
          >
            {breakTimes.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>
        </div>
      )}

      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 20,
        }}
      >
        <button
          disabled={disabled}
          className="btn btn-info"
          style={{ marginLeft: '12px', width: 150 }}
          onClick={() => confirmButtonAction(state)}
          type="submit"
        >
          Confirm
        </button>
      </div>
    </div>
  );
};
