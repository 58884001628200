import moment from 'moment';

import {
  AUTH_SIGN_IN_SUCCESS,
  AUTH_REFRESH_SUCCESS,
  AUTH_REFRESH_FAILURE,
  AUTH_SIGN_IN_GOOGLE_SUCCESS,
  AUTH_TOKEN_EXPIRED,
} from '../actions/actionTypes';

const initialState = {
  access_token: '',
  expirationDate: '',
  is_refresh_done: false,
  refreshToken: '',

};
export default (state = initialState, action) => {
  switch (action.type) {
  case AUTH_SIGN_IN_SUCCESS:
  case AUTH_SIGN_IN_GOOGLE_SUCCESS:
  case AUTH_REFRESH_SUCCESS:
    const newRefreshToken = action.payload?.refresh_token || '';
    const tokenDuration = parseInt(action.payload.expires_in);
    const expirationDate = moment().add(tokenDuration - 10, 'seconds').toDate();

    localStorage.setItem('refresh_token', newRefreshToken);

    const validTokenUuid = process.env.REACT_APP_VALID_TOKEN_UUID;
    if (validTokenUuid) {
      localStorage.setItem('validTokenUuid', validTokenUuid);
    }

    return {
      ...state,
      access_token: action.payload.access_token,
      expirationDate,
      is_refresh_done: true,
      refreshToken: action.payload.refresh_token,
    };

  case AUTH_REFRESH_FAILURE:
    return { is_refresh_done: true };
  case AUTH_TOKEN_EXPIRED:
    localStorage.removeItem('refresh_token');
    return { ...initialState, is_refresh_done: true };
  default:
    return state;
  }
};
