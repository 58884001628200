import React, { useState } from 'react';
import dateFormat from 'dateformat';
import { useSelector } from 'react-redux';

export default ({ jobDetail, onCancel, onSave }) => {
  const [profession, setProfession] = useState(jobDetail?.profession);
  const [date, setDate] = useState(jobDetail?.start_date);
  const [content, setContent] = useState(jobDetail.content);
  const [benefits, setBenefits] = useState(jobDetail.benefits);
  const [rate, setRate] = useState(jobDetail.rate);
  const [position, setPosition] = useState(jobDetail.position);
  const [status, setStatus] = useState(jobDetail.status);
  const [recruiter, setRecruiter] = useState(jobDetail?.recruiter);
  const [calendlyUrl, setCalendlyUrl] = useState(jobDetail.calendly_host);
  const [visibleForOffice, setVisible] = useState(jobDetail?.visible_for_office);

  const allPPRecruiters = useSelector((state) => state.pp.allPPRecruiters);
  const allProfessions = useSelector((state) => state.jobs.allProfessions);

  const onSaveClicked = () => {
    onSave({
      start_date: date,
      content,
      benefits,
      rate,
      position,
      status,
      recruiter,
      calendly_host: calendlyUrl,
      visible_for_office: visibleForOffice,
      profession,
    });
    onCancel();
  };
  const visibility = visibleForOffice ? 'visible' : 'invisible';
  return (
    <div
      className="card mt-4 mb-4"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        width: '80%',
        maxWidth: 800,
        marginTop: 50,
      }}
    >
      <div className="card-body" style={{ width: '100%' }}>
        <div>
          <h3 className="card-title" style={{ textAlign: 'center' }}>
            Job Details
          </h3>
        </div>
        <div
          style={{
            width: '100%',
            borderBottom: '1px solid #efefef',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p className="card-text" style={{ fontWeight: '600', width: '30%' }}>
            Status
          </p>

          <select
            className="form-control"
            style={{ width: 150 }}
            value={status}
            onChange={(e) => {
              setStatus(e.target.value);
            }}
          >
            <option value="active">Active</option>
            <option value="draft">Draft</option>
            <option value="complete">Completed</option>
            <option value="closed">Closed</option>
          </select>
        </div>
        <div
          style={{
            width: '100%',
            borderBottom: '1px solid #efefef',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p className="card-text" style={{ fontWeight: '600', width: '30%' }}>
            Job ID
          </p>
          <p className="card-text">{jobDetail?.id || ''}</p>
        </div>

        <div
          style={{
            width: '100%',
            borderBottom: '1px solid #efefef',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p className="card-text" style={{ fontWeight: '600', width: '30%' }}>
            Created
          </p>
          <p className="card-text">
            {jobDetail ? dateFormat(jobDetail?.createdAt, 'dddd, mmmm dS, yyyy, h:MM:ss TT') : ''}
          </p>
        </div>
        <div
          style={{
            width: '100%',
            borderBottom: '1px solid #efefef',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p className="card-text" style={{ fontWeight: '600', width: '30%' }}>
            Updated At
          </p>
          <p className="card-text">
            {jobDetail?.updatedAt
              ? dateFormat(jobDetail?.updatedAt, 'dddd, mmmm dS, yyyy, h:MM:ss TT')
              : '-'}
          </p>
        </div>

        <div
          style={{
            width: '100%',
            borderBottom: '1px solid #efefef',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p className="card-text" style={{ fontWeight: '600', width: '30%' }}>
            Profession Type
          </p>

          <select
            className="custom-select custom-select-lg"
            style={{ width: '100%' }}
            value={profession}
            onChange={(e) => setProfession(e.target.value)}
          >
            {allProfessions.map((professionType) => (
              <option key={professionType.code} value={professionType.code}>{professionType.name}</option>
            ))}
          </select>
        </div>
        <div
          style={{
            width: '100%',
            borderBottom: '1px solid #efefef',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p className="card-text" style={{ fontWeight: '600', width: '30%' }}>
            Start Date
          </p>

          <select
            className="custom-select custom-select-lg"
            style={{ width: '100%' }}
            value={date}
            onChange={(e) => setDate(e.target.value)}
          >
            <option value="ASAP">ASAP</option>
            <option value="1-2 Weeks">1-2 Weeks</option>
            <option value="2-4 Weeks">2-4 Weeks</option>
            <option value="1-2 Months">1-2 Months</option>
            <option value="3+ Months">3+ Months</option>
          </select>
        </div>
        <div
          style={{
            width: '100%',
            borderBottom: '1px solid #efefef',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p className="card-text" style={{ fontWeight: '600', width: '30%' }}>
            Schedule Type:
          </p>

          <select
            className="custom-select custom-select-lg"
            style={{ width: '100%' }}
            value={position}
            onChange={(e) => setPosition(e.target.value)}
          >
            <option value="Full Time Preferred">Full Time Preferred </option>
            <option value="Part Time Preferred">Part Time Preferred</option>
            <option value="Flexible (Full or Part Time)">Flexible (Full or Part Time)</option>
          </select>
        </div>

        <div
          style={{
            width: '100%',
            borderBottom: '1px solid #efefef',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p className="card-text" style={{ fontWeight: '600', width: '30%' }}>
            Rate Range:
          </p>
          <input
            className="form-control"
            value={rate}
            onChange={(e) => setRate(e.target.value)}
            style={{ width: '100%' }}
          />
        </div>

        <div
          style={{
            width: '100%',
            borderBottom: '1px solid #efefef',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p className="card-text" style={{ fontWeight: '600', width: '30%' }}>
            Benefit Package:
          </p>

          <select
            className="custom-select custom-select-lg"
            style={{ width: '100%' }}
            value={benefits}
            onChange={(e) => setBenefits(e.target.value)}
          >
            <option value="Full Benefits">Full Benefits</option>
            <option value="Some Benefits">Some Benefits</option>
            <option value="No Benefits">No Benefits</option>
            <option value="Negotiable Benefit">Negotiable Benefit</option>
          </select>
        </div>
        <div
          style={{
            width: '100%',
            borderBottom: '1px solid #efefef',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p className="card-text" style={{ fontWeight: '600', width: '30%' }}>
            Job Description:
          </p>
          <textarea
            className="form-control"
            value={content}
            style={{ width: '100%', minHeight: 100 }}
            type="text"
            onChange={(e) => setContent(e.target.value)}
          />
        </div>
        <div
          style={{
            width: '100%',
            borderBottom: '1px solid #efefef',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p className="card-text" style={{ fontWeight: '600', width: '30%' }}>
            Calendly link:
          </p>
          <input
            className="form-control"
            value={calendlyUrl}
            style={{ width: '100%' }}
            type="text"
            onChange={(e) => setCalendlyUrl(e.target.value)}
          />
        </div>

        <div
          style={{
            width: '100%',
            borderBottom: '1px solid #efefef',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p className="card-text" style={{ fontWeight: '600', width: '30%' }}>
            Recruiter
          </p>

          <select
            className="custom-select custom-select-lg"
            style={{ width: '100%' }}
            value={recruiter}
            onChange={(e) => setRecruiter(e.target.value)}
          >
            {['', ...allPPRecruiters]?.map((r) => <option value={r}>{r}</option>)}
          </select>
        </div>

        <div
          style={{
            width: '100%',
            borderBottom: '1px solid #efefef',
            padding: '5px 10px',
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <p className="card-text" style={{ fontWeight: '600', width: '30%' }}>
            Visibility
          </p>

          <select
            className="custom-select custom-select-lg"
            style={{ width: '100%' }}
            onChange={(e) => {
              setVisible(e.target.value === 'true');
            }}
          >
            {['visible', 'invisible'].map((r) => <option selected={r === visibility ? 'selected' : ''} value={r === 'visible'}>{r}</option>)}
          </select>
        </div>
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-around',
          marginBottom: 20,
        }}
      >
        <button
          onClick={(_) => onCancel()}
          className="btn btn-secondary"
          style={{ marginTop: '12px', marginRight: '6px', width: 100 }}
          type="button"
        >
          Cancel
        </button>

        <button
          onClick={() => onSaveClicked()}
          className="btn btn-danger"
          style={{ marginTop: '12px', marginLeft: '6px', width: 100 }}
          type="button"
        >
          Save
        </button>
      </div>
    </div>
  );
};
