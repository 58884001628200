import React, { useEffect } from 'react';

import moment from 'moment';

const EntryFields = ({
  credential,
  license,
  setLicenses,
  credentialType,
  editMode,

  onReferenceEntryChanged,

  isCredentialCheckboxChecked,
  onCredentialCheckboxCheckChanged,
}) => {
  const entryKey = `${license?.id}-${credential?.id}-${credentialType}`;
  const isReference = credentialType === 'reference';


  useEffect(() => {
    if (!credential) {
      setLicenses((prevState) => {
        const licenseIndex = prevState.findIndex((data) => data.id === license.id);

        if (!prevState[licenseIndex].credentials) {
          prevState[licenseIndex].credentials = [];
        }

        prevState[licenseIndex].credentials.push({
          credential_type: credentialType,
          credential_input_type: 'entry_field',
          entry_field: {
            license: null,
            issue_date: null,
            expiration_date: null,
          },
        });

        return [...prevState];
      });
    }
  }, [credential]);

  return (
    <>
      {isReference && (
        <>
          <EntryField
            style={{ marginTop: 20 }}
            label="Office Name"
            editMode={editMode}
            // adding fallback to avoid controlling uncontrolled input error
            value={credential?.officeName || ''}
            onValueChanged={(value) => {
              onReferenceEntryChanged(({
                ...credential,
                officeName: value,
              }));
            }}
          />

          <EntryField
            style={{ marginBlock: 20 }}
            label="Contact Email"
            inputType="email"
            editMode={editMode}
            // adding fallback to avoid controlling uncontrolled input error
            value={credential?.contactEmail || ''}
            onValueChanged={(value) => {
              onReferenceEntryChanged(({
                ...credential,
                contactEmail: value,
              }));
            }}
          />

          <ReferenceDatePicker
            title="Start Date"
            editMode={editMode}
            value={credential?.employmentStart ? moment(credential?.employmentStart).format('YYYY-MM-DD') : null}
            onValueChanged={(date) => {
              onReferenceEntryChanged(({
                ...credential,
                employmentStart: moment(date).toISOString(),
              }));
            }}
          />

          <ReferenceDatePicker
            title="End Date"
            editMode={editMode}
            value={credential?.employmentEnd ? moment(credential?.employmentEnd).format('YYYY-MM-DD') : null}
            onValueChanged={(date) => {
              onReferenceEntryChanged(({
                ...credential,
                employmentEnd: moment(date).toISOString(),
              }));
            }}
          />
        </>
      )}

      {!isReference && (
        <>
          <div
            style={{
              width: '50%',
              height: 40,
              border: '1px solid #efefef',
              display: 'flex',
              borderRadius: 5,
              padding: editMode ? 0 : 10,
              marginTop: 20,
              marginBottom: 20,
            }}
          >
            {!editMode && <p>{credential?.entry_field?.license || '-'}</p>}
            {editMode && (
              <input
                style={{ width: '100%' }}
                value={credential?.entry_field?.license}
                onChange={(e) => {
                  const { value } = e.target;

                  setLicenses((prevState) => {
                    const licenseIndex = prevState.findIndex((data) => data.id === license.id);
                    const credentialIndex = prevState[licenseIndex].credentials
                      .findIndex((c) => c && c.credential_type === credentialType);

                    if (prevState[licenseIndex].credentials[credentialIndex]) {
                      prevState[licenseIndex]
                        .credentials[credentialIndex].entry_field.license = value;
                    }

                    return [...prevState];
                  });
                }}
              />
            )}
          </div>

          {editMode &&
            <label
              htmlFor={`credential-checkbox-${entryKey}`}
              style={{ display: 'flex', marginInline: '32px' }}
            >

              <input
                style={{ boxShadow: 'none', marginRight: 16 }}
                id={`credential-checkbox-${entryKey}`}
                name="Not verified"
                type="checkbox"
                checked={isCredentialCheckboxChecked}
                onChange={(val) => {
                  onCredentialCheckboxCheckChanged(val.target.checked);
                }}
              />

              The credentials provided belong to the same
              professional that is listed on the government ID of this account.
            </label>
          }
          <div
            style={{
              width: '50%',
              borderBottom: '1px solid #efefef',
              padding: '5px 10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p className="card-text" style={{ fontWeight: '600' }}>
              Issued Date
            </p>
            {!editMode &&
              <p className="card-text">
                {credential?.entry_field?.issue_date ? moment.utc(credential?.entry_field?.issue_date).format('YYYY-MM-DD') : '-'}
              </p>
            }

            {editMode && (
              <input
                style={{ width: '70%', height: 40, maxWidth: '70%' }}
                className="form-controll"
                value={moment(credential?.entry_field?.issue_date).format('YYYY-MM-DD')}
                type="date"
                onChange={(e) => {
                  const { value } = e.target;

                  setLicenses((prevState) => {
                    const licenseIndex = prevState.findIndex((data) => data.id === license.id);
                    const credentialIndex = prevState[licenseIndex].credentials
                      .findIndex((c) => c && c.credential_type === credentialType);

                    if (prevState[licenseIndex].credentials[credentialIndex]) {
                      prevState[licenseIndex]
                        .credentials[credentialIndex].entry_field.issue_date = value;
                    }

                    return [...prevState];
                  });
                }}
              />
            )}

          </div>

          <div
            style={{
              width: '50%',
              borderBottom: '1px solid #efefef',
              padding: '5px 10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p className="card-text" style={{ fontWeight: '600' }}>
              Expiration Date
            </p>

            {!editMode && <p
              className="card-text">{credential?.entry_field?.expiration_date ? moment.utc(credential?.entry_field?.expiration_date).format('YYYY-MM-DD') : '-'}</p>}
            {editMode && (
              <input
                style={{ width: '70%', height: 40, maxWidth: '70%' }}
                className="form-controll"
                value={moment(credential?.entry_field?.expiration_date).format('YYYY-MM-DD')}
                type="date"
                onChange={(e) => {
                  const { value } = e.target;

                  setLicenses((prevState) => {
                    const licenseIndex = prevState.findIndex((data) => data.id === license.id);
                    const credentialIndex = prevState[licenseIndex].credentials
                      .findIndex((c) => c && c.credential_type === credentialType);

                    if (prevState[licenseIndex].credentials[credentialIndex]) {
                      prevState[licenseIndex]
                        .credentials[credentialIndex].entry_field.expiration_date = value;
                    }

                    return [...prevState];
                  });
                }}
              />
            )}
          </div>
        </>
      )}

    </>
  );
};

export default EntryFields;

function EntryField({ editMode, label, value, onValueChanged, style, inputType }) {
  return (
    <div
      style={{
        border: '1px solid #efefef',
        width: '50%',
        borderRadius: 5,
        padding: editMode ? 0 : 10,
        ...style,
      }}
    >
      <p className="card-text" style={{ fontWeight: '600' }}>
        {label}
      </p>

      <div style={{ display: 'flex' }}>
        {!editMode && <p>{value || '-'}</p>}

        {editMode && (
          <input
            inputMode={inputType}
            style={{ width: '100%' }}
            value={value}
            onChange={(e) => {
              const { value } = e.target;

              onValueChanged(value);
            }}
          />
        )}
      </div>
    </div>
  );
}

/**
 * @param {boolean} editMode
 * @param {string} title
 * @param {function(string)} onValueChanged
 * @param {object | undefined} value
 *
 * @returns {React.JSX.Element}
 */
function ReferenceDatePicker({ editMode, title, onValueChanged, value }) {
  return (
    <div
      style={{
        width: '50%',
        borderBottom: '1px solid #efefef',
        padding: '5px 10px',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <p className="card-text" style={{ fontWeight: '600' }}>
        {title}
      </p>

      {
        !editMode && (
          <p
            className="card-text">{value ? moment.utc(value).format('YYYY-MM-DD') : '-'}
          </p>
        )
      }

      {
        editMode && (
          <input
            style={{ width: '70%', height: 40 }}
            className="form-controll"
            value={moment(value).format('YYYY-MM-DD')}
            type="date"
            onChange={(e) => {
              const { value } = e.target;

              onValueChanged(value);
            }}
          />
        )
      }
    </div>
  );
}
