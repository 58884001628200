import React, { useEffect } from 'react';
import { Spinner } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { USER_ACTIONS } from '../../../actions/actionTypes';
import { getBlockedUsers } from '../../../actions/userActions';
import { createLoadingSelector } from '../../../apis/selectors';
import history from '../../../history';

export default () => {
  const actions = [USER_ACTIONS.GET_OFFICES_BLOCKED_REQUEST];

  const loadingSelector = createLoadingSelector(actions);

  const isLoading = useSelector((state) => loadingSelector(state));
  const blockedForOffices = useSelector((state) => state.users.blockedForOffices);
  const currentUser = useSelector((state) => state.users.currentUser);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getBlockedUsers(currentUser.id));
  }, []);

  const openOfficeProfile = (office) => {
    history.push(`/user/dentist/${office.id}`, {
      user_id: office.id,
      profile: 'DENTIST',
    });
  };

  const styles = {
    width20alignCenter: { width: '20%', textAlign: 'center' },
  };

  const renderOffice = (office) => (
    <div
      key={office.id}
      style={{
        width: '100%',
        borderBottom: '1px solid #efefef',
        padding: '5px 10px',
        display: 'flex',
        justifyContent: 'space-between',
      }}
    >
      <p className="card-text" style={styles.width20alignCenter}>
        {office.office_name}
      </p>
      <p className="card-text" style={styles.width20alignCenter}>
        {office.first_name}
      </p>
      <p className="card-text" style={styles.width20alignCenter}>
        {office.last_name}
      </p>
      <p
        className="card-text"
        style={{
          width: '50%',
          textAlign: 'center',
          textDecoration: 'underline',
          cursor: 'pointer',
        }}
        onClick={() => openOfficeProfile(office)}
      >
        {office.email_id}
      </p>

    </div>
  );

  const renderTitle = () => (
    <div
      style={{
        width: '100%',
        borderBottom: '1px solid #efefef',
        padding: '5px 10px',
        display: 'flex',
        justifyContent: 'space-between',
        backgroundColor: 'lightblue',
      }}
    >
      <p className="card-text font-weight-bold" style={styles.width20alignCenter}>
        Office Name
      </p>
      <p className="card-text font-weight-bold" style={styles.width20alignCenter}>
        First Name
      </p>
      <p className="card-text font-weight-bold" style={styles.width20alignCenter}>
        Last Name
      </p>
      <p className="card-text font-weight-bold" style={{ textAlign: 'center', width: '47%' }}>
        Email
      </p>

    </div>
  );

  if (!currentUser || isLoading) {
    return (

      <Spinner
        animation="border"
        role="status"
        variant="info"
        style={{ height: 30, width: 30, margin: 'auto' }}
      />
    );
  }
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        height: '100%',
        marginTop: 20,
        width: '90%',
        justifyContent: 'center',
      }}
    >
      {blockedForOffices.length !== 0 ? (
        <div className="card" style={{ width: '70%', height: '100%', marginLeft: 20 }}>
          {renderTitle()}
          {blockedForOffices.map((hyg) => renderOffice(hyg))}
        </div>
      ) : (
        <div
          className="card"
          style={{
            width: '70%', height: '100%', marginLeft: 20, minHeight: 200,
          }}
        >
          <div className="card-body">
            <div syle={{ paddingTop: 20, textAlign: 'center' }}>
              There are no blocked offices
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
