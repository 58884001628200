import tempMee from '../apis/tempMee';

import {
  PAYMENT_METHODS_ACTIONS,
  SHOW_MESSAGE
} from './actionTypes';
import { getAuthHeaders } from './authActions';
import { fetchJob, requestPaymentService } from './jobsAction';

export const getAllPayment = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.GET_ALL_PAYMENT_REQUEST });
    const userId = getState().users.currentUser._id;

    const headers = await getAuthHeaders(dispatch, getState);
    const response = await tempMee.get(
      `/payment/admin/account/payment-method/${userId}`, 
      { headers }
    );

    dispatch({
      type: PAYMENT_METHODS_ACTIONS.GET_ALL_PAYMENT_SUCCESS,
      payload: response?.data,
    });
  } catch (err) {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.GET_ALL_PAYMENT_FAILURE, payload: err });
  }
};
export const getReceivingAccounts = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.HYG_GET_ALL_PAYMENT_REQUEST });
    const userId = getState().users.currentUser._id;

    const headers = await getAuthHeaders(dispatch, getState);
    const response = await tempMee.get(
      `/payment/admin/account/payment-method/${userId}`, 
      { headers }
    );

    dispatch({
      type: PAYMENT_METHODS_ACTIONS.HYG_GET_ALL_PAYMENT_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.HYG_GET_ALL_PAYMENT_FAILURE, payload: err });
  }
};
export const selectDNTPaymentMethod = (cardId) => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.SELECT_DNT_PAYMENT_REQUEST });
    const userId = getState().users.currentUser._id;

    const headers = await getAuthHeaders(dispatch, getState);
    await tempMee.patch(
        `/payment/admin/account/payment-method/${cardId}`,
        {user_id: userId, user_type:"DNT"},
        {
          headers,
        },
    );

    dispatch({
      type: PAYMENT_METHODS_ACTIONS.SELECT_DNT_PAYMENT_SUCCESS,
      payload: cardId,
    });
  } catch (err) {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.SELECT_DNT_PAYMENT_FAILURE, payload: err });
  }
};

export const selectHygPaymentMethod = (cardId) => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.SELECT_HYG_PAYMENT_REQUEST });
    const userId = getState().users.currentUser._id;

    const headers = await getAuthHeaders(dispatch, getState);

    await tempMee.patch(
        `/payment/admin/account/payment-method/${cardId}`,
        {user_id: userId, user_type:"HYG"},
        {
          headers,
        },
    );

    dispatch({
      type: PAYMENT_METHODS_ACTIONS.SELECT_HYG_PAYMENT_SUCCESS,
      payload: cardId,
    });
  } catch (err) {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.SELECT_HYG_PAYMENT_FAILURE, payload: err });
  }
};

export const selectHygMarketingPaymentMethod = (cardId) => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.SELECT_HYG_MARKETING_PAYMENT_REQUEST });
    const userId = getState().users.currentUser._id;

    const headers = await getAuthHeaders(dispatch, getState);
    await tempMee.post(
      `admin/users/${userId}/receiving-accounts-for-referral/${cardId}/select`,
      {},
      {
        headers,
      },
    );

    dispatch({
      type: PAYMENT_METHODS_ACTIONS.SELECT_HYG_MARKETING_PAYMENT_SUCCESS,
      payload: cardId,
    });
  } catch (err) {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.SELECT_HYG_MARKETING_PAYMENT_FAILURE, payload: err });
  }
};

export const markInvoiceAsPaid = (invoiceId) => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.MARK_INVOICE_AS_PAID_REQUEST });
    const jobId = getState().jobs.currentJob.id;
    const headers = await getAuthHeaders(dispatch, getState);
    const response = await tempMee.put(
      `admin/jobs/${jobId}/invoice/${invoiceId}`,
      {},
      {
        headers,
      },
    );
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The invoice has been marked as paid.' },
    });
    dispatch({
      type: PAYMENT_METHODS_ACTIONS.MARK_INVOICE_AS_PAID_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.MARK_INVOICE_AS_PAID_FAILURE, payload: err });
  }
};

export const instantPayout = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.CREATE_INSTANT_PAYOUT_REQUEST });
    const jobId = getState().jobs.currentJob.id;
    const headers = await getAuthHeaders(dispatch, getState);
    const response = await tempMee.post(
      `admin/instant-payout/${jobId}`,
      {},
      {
        headers,
      },
    );

    const responseOrder = await requestPaymentService(jobId);
    dispatch({
      type: PAYMENT_METHODS_ACTIONS.CREATE_INSTANT_PAYOUT_SUCCESS,
      payload: { ...response.data, transactionsPaymentService: responseOrder?.data || [] },
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The instant payout has been requested successfully.' },
    });
  } catch (err) {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.CREATE_INSTANT_PAYOUT_FAILURE, payload: err });
  }
};

export const markJobAsPaidOut = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.CREATE_MARK_JOB_PAID_OUT_REQUEST });
    const jobId = getState().jobs.currentJob.id;
    const headers = await getAuthHeaders(dispatch, getState);
    const response = await tempMee.patch(
      `admin/jobs/${jobId}/mark-paid-out`,
      {},
      {
        headers,
      },
    );

    const responseOrder = await requestPaymentService(jobId);
    dispatch({
      type: PAYMENT_METHODS_ACTIONS.CREATE_MARK_JOB_PAID_OUT_SUCCESS,
      payload: { ...response.data, transactionsPaymentService: responseOrder?.data || [] },
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The job marked as paid out successfully' },
    });
  } catch (err) {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.CREATE_MARK_JOB_PAID_OUT_FAILURE, payload: err });
  }
};

export const sendProfessionalPayout = (transactionId) => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.SEND_PROFESSIONAL_PAYOUT_REQUEST });
    const headers = await getAuthHeaders(dispatch, getState);
    const jobId = getState().jobs.currentJob.id;

    await tempMee.post(
      `/payment/admin/order/${transactionId}/payout`,
      {},
      {
        headers,
      },
    );

    dispatch(fetchJob(jobId));

    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'Payout sent to professional' },
    });
  } catch (err) {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.SEND_PROFESSIONAL_PAYOUT_FAILURE, payload: err });
  }
};

export const waivePendingInstantPayout = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.WAIVE_INSTANT_PAYOUT_REQUEST });
    const jobId = getState().jobs.currentJob.id;
    const headers = await getAuthHeaders(dispatch, getState);
    const response = await tempMee.patch(
      `admin/instant-payout/${jobId}/waive`,
      {},
      {
        headers,
      },
    );

    const responseOrder = await requestPaymentService(jobId);
    dispatch({
      type: PAYMENT_METHODS_ACTIONS.WAIVE_INSTANT_PAYOUT_SUCCESS,
      payload: { ...response.data, transactionsPaymentService: responseOrder?.data || [] },
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The instant payout fee has been waived successfully.' },
    });
  } catch (err) {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.WAIVE_INSTANT_PAYOUT_FAILURE, payload: err });
  }
};

export const updateTransactionStatus = (transactionId, status) => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.UPDATE_TRANSACTION_STATUS_REQUEST });

    const headers = await getAuthHeaders(dispatch, getState);

    await tempMee.patch(`/payment/admin/order/status/${transactionId}`,
      {status
      } ,{ headers });
        
    dispatch({
      type:  PAYMENT_METHODS_ACTIONS.UPDATE_TRANSACTION_STATUS_SUCCESS,
      payload: transactionId,
    });
  } catch (err) {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.UPDATE_TRANSACTION_STATUS_FAILURE, payload: err });
  }
};

export const deleteInstantTransaction = () => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.DELETE_INSTANT_TRANSACTION_REQUEST });
    const jobId = getState().jobs.currentJob.id;
    const headers = await getAuthHeaders(dispatch, getState);
    const response = await tempMee.delete(
      `admin/instant-payout/${jobId}/pending`,
      {
        headers,
      },
    );

    const responseOrder = await requestPaymentService(jobId);
    dispatch({
      type: PAYMENT_METHODS_ACTIONS.DELETE_INSTANT_TRANSACTION_SUCCESS,
      payload: { ...response.data, transactionsPaymentService: responseOrder?.data || [] },
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The instant payout tansaction has been removed.' },
    });
  } catch (err) {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.DELETE_INSTANT_TRANSACTION_FAILURE, payload: err });
  }
};

export const deleteInvoice = (invoiceId) => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.DELETE_INVOICE_REQUEST });

    const jobId = getState().jobs.currentJob.id;
    const headers = await getAuthHeaders(dispatch, getState);

    await tempMee.delete(
      `admin/jobs/${jobId}/invoice/${invoiceId}`,
      {
        headers,
      },
    );

    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The invoice has been removed.' },
    });

    dispatch({
      type: PAYMENT_METHODS_ACTIONS.DELETE_INVOICE_SUCCESS,
      payload: invoiceId,
    });
  } catch (err) {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.DELETE_INVOICE_FAILURE, payload: err });
  }
};

export const sendJobCounterCover = (jobId, amount) => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.SEND_JOB_COUNTER_COVER_REQUEST });

    const headers = await getAuthHeaders(dispatch, getState);
    const response = await tempMee.post(
      `/admin/jobs/${jobId}/counter-covers`,
      {
        amount,
      },
      {
        headers,
      },
    );

    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The counter cover was added.' },
    });
    dispatch({
      type: PAYMENT_METHODS_ACTIONS.SEND_JOB_COUNTER_COVER_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.SEND_JOB_COUNTER_COVER_FAILURE, payload: err });
  }
};

export const removeJobCounterCover = (jobId) => async (dispatch, getState) => {
  try {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.REMOVE_JOB_COUNTER_COVER_REQUEST });

    const headers = await getAuthHeaders(dispatch, getState);
    await tempMee.delete(
      `/admin/jobs/${jobId}/counter-covers`,
      {
        headers,
      },
    );

    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The counter cover was removed.' },
    });
    dispatch({
      type: PAYMENT_METHODS_ACTIONS.REMOVE_JOB_COUNTER_COVER_SUCCESS,
    });
  } catch (err) {
    dispatch({ type: PAYMENT_METHODS_ACTIONS.REMOVE_JOB_COUNTER_COVER_FAILURE, payload: err });
  }
};
