import React, { Component } from 'react';
import { connect } from 'react-redux';
import Styles from '../../themes/style.module.scss';
import { signIn } from '../../actions/authActions';
import { createLoadingSelector } from '../../apis/selectors';
import GoogleLoginButton from '../commonComponents/GoogleLoginButton';
import logoIcon from '../../images/logo-icon.svg';

class Login extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: '',
      password: '',
      enabledProviders: process.env.REACT_APP_ENABLED_AUTH_PROVIDERS?.split(',') || [],
    };
  }

  onSubmit = (event) => {
    event.preventDefault();
    const { signIn } = this.props;
    const { email, password } = this.state;
    signIn({ email, password });
  };

  renderForm = () => {
    const { isLoading } = this.props;
    const { email, password, enabledProviders } = this.state;
    const isCredentialEnabled =
      !enabledProviders ||
      enabledProviders.length === 0 ||
      enabledProviders.includes('credential');

    if (!isCredentialEnabled) {
      return null;
    }

    return (
      <div
        style={{
          background: 'rgba(255, 255, 255, 0.7)',
          paddingRight: '12px',
          paddingLeft: '12px',
        }}
      >
        <form onSubmit={this.onSubmit}>
          <div className="mb-3">
          <label htmlFor="imputEmail" className="form-label">Email</label>
            <input
              id="imputEmail"
              className="form-control"
              onChange={(e) => this.setState({ email: e.target.value })}
              type="email"
              name="email"
              placeholder="email@sample.com"
              required
              autoComplete="false"
              value={email}
            />
          </div>

          <div className="mb-3">
            <label htmlFor="imputPassword" className="form-label">
              Password
            </label>
            <input
              id="imputPassword"
              className="form-control"
              onChange={(e) => this.setState({ password: e.target.value })}
              type="password"
              name="password"
              required
              autoComplete="false"
              value={password}
            />
          </div>
          <div className="mb-3" style={{ paddingTop: '12px' }}>
            {isLoading ? (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <div className="spinner-border text-info d-flex justify-content-center bg-transparent" />
              </div>
            ) : (
              <button
                className="btn btn-primary"
                type="submit"
                style={{ width: '100%' }}
              >
                Login
              </button>
            )}
          </div>
        </form>
      </div>
    );
  };

  renderGoogleLoginButton = () => {
    const { enabledProviders } = this.state;
    const isGoogleEnabled = enabledProviders.includes('google');

    if (!isGoogleEnabled) {
      return null;
    }

    return (
      <div style={{ paddingTop: '12px' }}>
        <GoogleLoginButton
          onClick={() => {
            window.location.href = process.env.REACT_APP_GOOGLE_SSO_URL;
          }}
        />
      </div>
    );
  };

  render() {
    const { isRefeshLoading } = this.props;
    return (
      <div className={Styles.screenView}>
        {isRefeshLoading ? (
          <div className={Styles.ModalTransparent}>
            <div className="spinner-border text-info d-flex justify-content-center bg-transparent" />
          </div>
        ) : (
          <div className={Styles.Modal}>
            <img
              className="mb-3"
              src={logoIcon}
              alt="gotu_logo"
              style={{ width: 50, height: 50 }}
            />
            {this.renderForm()}
            {this.renderGoogleLoginButton()}
          </div>
        )}
      </div>
    );
  }
}

const refreshLoadingSelector = createLoadingSelector(['AUTH_REFRESH']);
const loadingSelector = createLoadingSelector(['AUTH_SIGN_IN']);
const mapStateToProps = (store) => ({
  isRefeshLoading: refreshLoadingSelector(store),
  isLoading: loadingSelector(store),
});
export default connect(mapStateToProps, { signIn })(Login);
