import React, { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import moment from 'moment';
import { getUserInvoices } from '../../../actions/userActions';
import history from '../../../history';

const LIMIT_VALUE = 25;

const normalText = {
  fontFamily: 'Nunito',
  fontSize: 16,
  color: '#435963',
  textTransform: 'capitalize',
  width: '20%',
  margin: 10,
  textAlign: 'center',
};

const titleText = {
  fontFamily: 'Nunito',
  fontWeight: 'bold',
  fontSize: '18px',
  color: '#3EB0CA',
  textTransform: 'capitalize',
  width: '20%',
  textAlign: 'center',
};

export default function InvoiceList () {
  const dispatch = useDispatch();

  const {
    invoices, invoicesPagination, isFinalPage, isActiveRequest, totalUnpaidInvoices,
  } = useSelector((state) => state.users.officeInvoices);

  useEffect(() => {
    dispatch(getUserInvoices({ page: 1, limit: LIMIT_VALUE }));

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    const profileContentSection = document.getElementById('profile-content');

    if (profileContentSection != null) {
      profileContentSection.addEventListener('scroll', handleReceiptScrolling);
    }

    return () => {
      if (profileContentSection != null) {
        profileContentSection.removeEventListener('scroll', handleReceiptScrolling);
      }
    };

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesPagination, invoices]);

  const handleReceiptScrolling = useCallback((event) => {
    // tolerance is now set to height of navbar when it's on bigger size state
    // need to dynamically get from `.navbar` component later
    const tolerance = 102
    const sectionHeight = event.target.getBoundingClientRect().height;
    const elementMaxScrollExtent = event.target.scrollHeight - tolerance;
    const scrollProgress = event.target.scrollTop + sectionHeight;

    if (scrollProgress >= elementMaxScrollExtent && !isActiveRequest && !isFinalPage) {
      dispatch(getUserInvoices({ page: invoicesPagination?.page + 1, limit: LIMIT_VALUE }));
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [invoicesPagination, invoices]);


  if (invoices.length === 0) {
    return (
      <div className="component_container">
        <div className="floating_container">
          <div
            style={{
              width: '100%',
              display: 'flex',
              flexDirection: 'column',
              margin: 55,
            }}
          >
            <p
              style={{
                fontSize: 16,
                color: '#546e7a',
                textAlign: 'center',
                marginTop: 20,
              }}
            >
              There are no invoices.
            </p>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="component_container">
      <div
        className="floating_container"
        style={{
          flexDirection: 'column',
          justifyContent: 'flex-start',
          alignItems: 'center',
          padding: 40,
        }}
      >
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            marginBottom: 20,
            overflowY: 'auto',
          }}
        >
          <div style={{ display: 'grid', gridTemplateColumns: '30% auto 30%' }}>
            <div style={{ gridColumnStart: 2, gridColumnEnd: 3 }}>
              <h1 className="global_font f-dark" style={{ textAlign: 'center' }}>
                Invoices
              </h1>
            </div>

            <div style={{ marginTop: '20px' }}>
              <div
                className="f-dark"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  fontFamily: 'Nunito',
                  fontSize: 16,
                  fontWeight: 'bold',
                }}
              >
                {`Unpaid Invoices - ${totalUnpaidInvoices.count}`}
              </div>
              <div
                className="f-dark"
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  fontFamily: 'Nunito',
                  fontSize: 16,
                  fontWeight: 'bold',
                }}
              >
                {`Total of Unpaid Invoices - $${totalUnpaidInvoices.sum.toFixed(2)}`}
              </div>
            </div>
          </div>
          <div
            style={{ overflowY: 'scroll', marginTop: '30px' }}
          >
            <div
              style={{
                flex: 1,
                margin: 10,
                display: 'flex',
              }}
            >
              <span style={titleText}>Shift</span>
              <span style={titleText}>Office</span>
              <span style={titleText}>Amount</span>
              <span style={titleText}>Invoice Date</span>
              <span style={titleText}>Job Date</span>
              <span style={titleText}>Status</span>
            </div>
            <div style={{ height: 1, backgroundColor: '#F6F6F6' }} />
            {invoices?.map((invoice, index) => (
              <div
                role="presentation"
                key={invoice.transaction_id}
                style={{
                  flex: 1,
                  margin: 10,
                  display: 'flex',
                  backgroundColor: index % 2 === 0 ? '#F6F6F6' : '#FAFAFA',
                  cursor: 'pointer',
                  justifyContent: 'center',
                  alignItems: 'center',
                  borderRadius: '30px',
                }}
                onClick={() => history.push(`/job/${invoice?.job_id}`, {
                  job_id: invoice?.job_id,
                  jobId: invoice?.job_id,
                })}
              >
                <span
                  style={{
                    fontFamily: 'Nunito',
                    fontSize: 16,
                    color: '#435963',
                    textTransform: 'capitalize',
                    width: '20%',
                    margin: 10,
                    textAlign: 'center',
                    textDecoration: 'underline',
                  }}
                >
                  View Shift
                </span>
                <span style={normalText}>{invoice.office_name}</span>
                <span
                  style={{
                    fontFamily: 'Nunito',
                    fontSize: 16,
                    color: '#435963',
                    textTransform: 'capitalize',
                    width: '20%',
                    margin: 10,
                    fontWeight: 'bold',
                    textAlign: 'center',
                  }}
                >
                  {`$${invoice.amount.toFixed(2)}`}
                </span>
                <span style={normalText}>
                  {moment(invoice.date).format('ddd, MMM DD, YYYY')}
                </span>
                <span style={normalText}>
                  {`${moment(invoice.jobDate).format('MM-DD-YYYY HH:mm')}`}
                </span>
                <span
                  style={{
                    fontFamily: 'Nunito',
                    fontSize: 16,
                    color: !invoice.paid ? '#F4511E' : '#15ab7b',
                    textTransform: 'capitalize',
                    width: '20%',
                    margin: 10,
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  {invoice.paid ? 'Paid' : 'Unpaid'}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};
