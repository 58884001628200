import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ReactSwitch from 'react-switch';

import { fetchNotificationSettingsHYG, updateUserNotificationSettingsHYG } from '../../actions/userActions';
import { notificationTypeMapHYG } from '../../constants';

export default function NotificationSettingsHYG() {
  const dispatch = useDispatch();

  const [currentSettings, setCurrentSettings] = useState({});

  const notificationSettings = useSelector(
    (state) => state.users.currentUser?.notificationSettingsHYG,
  );

  useEffect(() => {
    dispatch(fetchNotificationSettingsHYG());
  }, []);

  useEffect(() => {
    if (notificationSettings) {
      setCurrentSettings(notificationSettings);
    }
  }, [notificationSettings]);

  const formChanged = JSON.stringify(notificationSettings) !== JSON.stringify(currentSettings);

  const renderSwitch = (key, checked, title) => (
    <div key={key} style={{ display: 'flex', alignItems: 'center', columnGap: 20 }}>
      <ReactSwitch
        checked={checked}
        onChange={() => {
          setCurrentSettings({ ...currentSettings, [key]: !checked });
        }}
        onColor="#34C9EB"
        onHandleColor="#fff"
        handleDiameter={20}
        uncheckedIcon={false}
        checkedIcon={false}
        boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
        activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
        height={25}
        width={50}
        className="react-switch"
        id="material-switch"
      />

      <h5 className="global_font bold" style={{ color: '#303F46', margin: 0, textTransform: 'capitalize' }}>
        {title}
      </h5>
    </div>
  );

  const renderWorkingDaySection = () => (
    <div
      style={{
        width: '100%',
        display: 'flex',
        borderBottom: '1px solid #EDEDED',
        padding: '20px 0px',
      }}
    >
      <div style={{ width: '50%', marginLeft: 20 }}>
        <div style={{ display: 'flex' }}>
          <h5
            className="global_font bold"
            style={{
              fontSize: 16,
              color: '#303F46',
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            Working Days (Push Notification)
          </h5>
        </div>

        <p className="global_font" style={{ color: '#435963' }}>
          Days for which Professional wishes to be notified about new shifts.
        </p>
      </div>

      <div
        style={{
          marginLeft: 120,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          rowGap: 18,
        }}
      >
        {
          Object.entries(currentSettings)
            .map(([settingKey, settingValue]) => {
              const notification = notificationTypeMapHYG.WORKING_DAYS.find(({ key }) => key === settingKey);
              if (notification) {
                return renderSwitch(settingKey, settingValue, notification.title);
              }
              return null;
            })
        }
      </div>
    </div>
  );

  const renderShiftTypeSection = () => (
    <div
      style={{
        width: '100%',
        display: 'flex',
        borderBottom: '1px solid #EDEDED',
        padding: '20px 0px',
      }}
    >
      <div style={{ width: '50%', marginLeft: 20 }}>
        <div style={{ display: 'flex' }}>
          <h5
            className="global_font bold"
            style={{
              fontSize: 16,
              color: '#303F46',
              marginBottom: 10,
              marginRight: 10,
            }}
          >
            Shift Types (Push Notification)
          </h5>
        </div>

        <p className="global_font" style={{ color: '#435963' }}>
          Shift types which Professional wishes to be notified about.
        </p>
      </div>

      <div
        style={{
          marginLeft: 120,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          rowGap: 18,
        }}
      >
        {
          Object.entries(currentSettings)
            .map(([settingKey, settingValue]) => {
              const notification = notificationTypeMapHYG.SHIFT_TYPES.find(({ key }) => key === settingKey);
              if (notification) {
                return renderSwitch(settingKey, settingValue, notification.title);
              }
              return null;
            })
        }
      </div>
    </div>
  );

  return (
    <div
      className="card mt-3 ml-4 mr-4 mb-3"
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        width: '80%',
        padding: '0px 90px',
      }}
    >
      <h1
        className="global_font f-dark"
        style={{
          fontSize: 30,
          marginTop: 45,
          marginLeft: 30,
          textAlign: 'center',
        }}
      >
        Notification Settings
      </h1>

      <div style={{ width: '100%', borderBottom: '1px solid #EDEDED' }}>
        <h5
          className="global_font bold"
          style={{ fontSize: 16, color: '#3EB0CA', marginLeft: 20 }}
        >
          Notification Type
        </h5>
      </div>

      {renderWorkingDaySection()}
      {renderShiftTypeSection()}

      <button
        type="button"
        className="btn btn-info mr-1 ml-1"
        style={{ width: 100, margin: '80px 0px 45px 0px' }}
        onClick={() => dispatch(updateUserNotificationSettingsHYG(currentSettings))}
        disabled={!formChanged}
      >
        Save
      </button>
    </div>
  );
}
