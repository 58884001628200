import tempMee from '../apis/tempMee';
import { MAP_TYPES } from './actionTypes';
import { getAuthHeaders } from './authActions';

// eslint-disable-line import/prefer-default-export
export const fetchUsersForMap = () => async (dispatch) => {
  try {
    dispatch({ type: MAP_TYPES.FETCH_USER_LIST_MAP_REQUEST });

    const headers = await getAuthHeaders();
    const response = await tempMee.get('/admin/mapUsers', { headers });
    dispatch({ type: MAP_TYPES.FETCH_USER_LIST_MAP_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: MAP_TYPES.FETCH_USER_LIST_MAP_FAILURE, payload: err });
  }
};
