import React, { useEffect, useState } from 'react';
import ImageZoom from 'react-medium-image-zoom';

import { useDispatch, useSelector } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import StatePicker from '../../commonComponents/StatePicker';
import CustomPortal from '../../commonComponents/CustomPortal';
import {
  addLicense,
  deleteLicense,
  updateCurrentUser,

} from '../../../actions/userActions';

import { showErrorMessage } from '../../../utils';
import StateCredential from './credentialComponents/StateCredential';
import { createLoadingSelector } from '../../../apis/selectors';
import StripeLegalInfo from './licenses/StripeLegalInfo';
import TaxDocument from './TaxDocument';
import PlusIcon from '../../../images/plus.svg';

function LicenseCredentialTab({
  updateUserPicture, newStateId, onSaveClicked, stateCredentials, allProfessions,
}) {
  const dispatch = useDispatch();

  const currentUser = useSelector((state) => state.users.currentUser);

  const actions = ['UPDATE_CURRENT_USER'];
  const loadingSelector = createLoadingSelector(actions);
  const isLoading = useSelector((state) => loadingSelector(state));

  const [firstName, setFirstName] = useState(currentUser.first_name || '');
  const [lastName, setLastName] = useState(currentUser.last_name || '');
  const [stateId, setStateId] = useState(currentUser?.professional_info?.government_id?.identification || '');
  const [stateIdIssued, setStateIdIssued] = useState(currentUser?.professional_info?.government_id?.state || '');
  const [stateIdStatus, setStateIdStatus] = useState(currentUser?.professional_info?.government_id?.status || '');
  const [licenses, setLicenses] = useState(currentUser.licenses);
  const [addLicenseMode, setAddLicenseMode] = useState(false);
  const [editProfile, setEditProfile] = useState(false);
  const [showDeletePopup, setShowDeletePopup] = useState(false);
  const [deletedId, setDeleteId] = useState();
  const [stateIdFile, setStateIdFile] = useState();
  const [stateIdFileUrl, setStateIdFileUrl] = useState(currentUser?.professional_info?.government_id?.photo || '');

  const hyg_ident_photo = newStateId || currentUser?.professional_info?.government_id?.photo || '';
  const userProfile = currentUser?.professional_info?.government_id?.photo || '';

  const uploadStateId = (file) => {
    updateUserPicture(file);
  };

  const handleFileChange = (file) => {
    setStateIdFile(file);
    setStateIdFileUrl(URL.createObjectURL(file));

    uploadStateId(file);
  };

  useEffect(() => {
    setLicenses([...currentUser.licenses]);
  }, [currentUser.licenses]);

  const saveEditProfile = () => {
    if (!stateIdFileUrl) {
      showErrorMessage({
        message: 'Please upload a photo!'
      });
      return;
    }

    if (
      !firstName.trim()
      || !lastName.trim()
      || !stateId.trim()
      || !stateIdIssued.trim()
      || !stateIdStatus.trim()
    ) {
      showErrorMessage({
        message: 'Please enter all fields!'
      });
      return;
    }

    onSaveClicked({
      firstName,
      lastName,
      stateId,
      stateIdIssued,
      stateIdStatus,
    });
    setEditProfile(false);
  };

  const showStateIdStatusOptions = () => (
    <select
      className="custom-select custom-select-lg"
      style={{ height: 32, width: '60%' }}
      value={stateIdStatus}
      onChange={(e) => setStateIdStatus(e.target.value)}
    >
      <option className="dropdown-item" value="unverified">
        Unverified
      </option>
      <option className="dropdown-item" value="verified">
        Verified
      </option>
      <option className="dropdown-item" value="invalid">
        Invalid
      </option>
    </select>
  );

  const renderFields = () => (
    isLoading
      ? (
        <Spinner
          animation="border"
          role="status"
          variant="info"
          style={{
            height: 30,
            width: 30,
            marginLeft: 20,
            marginTop: 10,
          }}
        />
      )
      : (
        <div style={{ width: '100%' }}>
          <div
            style={{
              width: '100%',
              borderBottom: '1px solid #efefef',
              padding: '5px 10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p className="card-text" style={{ fontWeight: '600' }}>
              State I.D.
            </p>
            {editProfile ? (
              <input
                style={{ width: '60%' }}
                className="form-control"
                value={stateId}
                type="text"
                onChange={(e) => setStateId(e.target.value)}
              />
            ) : (
              <p
                className="card-text">{currentUser?.professional_info?.government_id?.identification}</p>
            )}
          </div>

          <div
            style={{
              width: '100%',
              borderBottom: '1px solid #efefef',
              padding: '5px 10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p className="card-text" style={{ fontWeight: '600' }}>
              First Name
            </p>

            {editProfile ? (
              <input
                style={{ width: '60%' }}
                className="form-control"
                value={firstName}
                type="text"
                onChange={(e) => setFirstName(e.target.value)}
              />
            ) : (
              <p className="card-text">{currentUser.first_name}</p>
            )}
          </div>
          <div
            style={{
              width: '100%',
              borderBottom: '1px solid #efefef',
              padding: '5px 10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p className="card-text" style={{ fontWeight: '600' }}>
              Last Name
            </p>
            {editProfile ? (
              <input
                className="form-control"
                style={{ width: '60%' }}
                value={lastName}
                type="text"
                onChange={(e) => setLastName(e.target.value)}
              />
            ) : (
              <p className="card-text">{currentUser.last_name}</p>
            )}
          </div>

          <div
            style={{
              width: '100%',
              borderBottom: '1px solid #efefef',
              padding: '5px 10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p className="card-text" style={{ fontWeight: '600' }}>
              State I.D. Issued
            </p>
            {editProfile ? (
              <StatePicker
                style={{
                  height: 32,
                  width: '60%',
                }}
                value={stateIdIssued}
                didSelect={(state) => setStateIdIssued(state)}
              />
            ) : (
              <p className="card-text">{currentUser?.professional_info?.government_id?.state}</p>
            )}
          </div>

          <div
            style={{
              width: '100%',
              borderBottom: '1px solid #efefef',
              padding: '5px 10px',
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
            }}
          >
            <p className="card-text" style={{ fontWeight: '600' }}>
              Status
            </p>
            {editProfile ? (
              showStateIdStatusOptions()
            ) : (
              <p className="card-text" style={{ textTransform: 'capitalize' }}>
                {currentUser?.professional_info?.government_id?.status}
              </p>
            )}
          </div>
        </div>
      )

  );
  const getStatusColor = (status) => {
    if (status === 'new') {
      return 'black';
    }
    if (status === 'verified') {
      return 'green';
    }
    return 'red';
  };

  const showBackgroundCheckOptions = () => (
    <select
      className="custom-select custom-select-lg mb-3"
      style={{ marginTop: '5px', position: 'relative' }}
      value={currentUser?.backgroundCheck?.status}
      onChange={(e) => dispatch(updateCurrentUser({ backgroundCheck: { status: e.target.value } }))}
    >
      <option className="dropdown-item" value="not started">
        Not started
      </option>
      <option className="dropdown-item" value="pending">
        Pending
      </option>
      <option className="dropdown-item" value="consider">
        Consider
      </option>
      <option className="dropdown-item" value="clear">
        Clear
      </option>
      <option className="dropdown-item" value="failed">
        Failed
      </option>
    </select>
  );

  const cancelEditProfile = () => {
    setFirstName(currentUser.first_name || '');
    setLastName(currentUser.last_name || '');
    setStateId(currentUser?.professional_info?.government_id?.identification || '');
    setStateIdIssued(currentUser?.professional_info?.government_id?.state || '');
    setStateIdStatus(currentUser?.professional_info?.government_id?.status || '');
    setEditProfile(false);
  };
  return (
    <>
      <div style={{
        width: '85%',
        padding: 20,
        border: '1px solid #e5e5e5',
        marginTop: 15,
        backgroundColor: 'white',
      }}
      >
        {showDeletePopup && deletedId ? (
          <CustomPortal
            title="Are you sure you want to delete this license?"
            submitText="Delete"
            cancelText="Cancel"
            submitPressed={() => {
              dispatch(deleteLicense(deletedId));

              setShowDeletePopup(false);
              setDeleteId(null);
            }}
            cancelPressed={() => {
              setShowDeletePopup(false);
              setDeleteId(null);
            }}
          />
        ) : null}
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              alignItems: 'center',
              justifyContent: 'center',
              width: 180,
              marginLeft: 25,
            }}
          >
            <h4 className="card-title">Background Check: </h4>
            {showBackgroundCheckOptions()}
          </div>
        </div>
        <h3>Licenses</h3>
        <div
          style={{
            display: 'flex', backgroundColor: 'white',
          }}
        >
          <div
            className="card mt-5"
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              overflowY: 'auto',
              marginBottom: 50,
            }}
          >

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
              }}
            >
              <div
                className="card-body"
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  flexDirection: 'column',
                }}
              >
                <h4
                  className="card-title"
                  style={{ marginLeft: 40, fontFamily: 'Nunito', color: '#494963' }}
                >
                  Government I.D.
                  {' '}
                  <span style={{ color: '#899197', fontWeight: '300' }}>Required(*)</span>
                </h4>
                <div style={{ color: '#899197', fontStyle: 'italic' }}>Example: Driver’s License,
                  Government Issued ID
                </div>
              </div>
              <div
                style={{
                  padding: 10,
                  display: 'flex',
                  justifyContent: 'space-between',
                }}
              >
                {editProfile ? null : (
                  <button
                    className="btn btn-info"
                    style={{ width: 94, height: 34 }}
                    onClick={() => setEditProfile(true)}
                    type="button"
                  >
                    Edit
                  </button>
                )}
                {editProfile ? (
                  <button
                    className="btn btn-success"
                    style={{ width: 94, height: 34, marginRight: 10 }}
                    onClick={() => saveEditProfile()}
                    type="button"
                  >
                    Save
                  </button>
                ) : null}
                {editProfile ? (
                  <button
                    className="btn btn-secondary"
                    style={{ width: 94, height: 34 }}
                    onClick={() => cancelEditProfile()}
                    type="button"
                  >
                    Cancel
                  </button>
                ) : null}
              </div>

            </div>

            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                flexDirection: 'row',
                padding: '0 0 16px 15px',
                color: '#899197',
              }}
            >
              Enter License Number and/or upload a photo
            </div>
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                borderBottom: '1px solid #ededed',
                gap: 10,
                flex: 1,
                marginLeft: 20,
                marginRight: 20,
                paddingBottom: 40,
              }}
            >
              <div
                style={{
                  display: 'flex',
                  height: '100%',
                  flexDirection: 'column',
                  alignItems: 'center',
                  flex: 1,
                }}
              >
                {renderFields()}
              </div>

              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-start',
                  flexDirection: 'column',
                  alignItems: 'center',
                  width: '60%',
                  border: '1px dashed #cecece',
                  paddingTop: 10,
                }}
              >

                {stateIdFileUrl || hyg_ident_photo
                  ? (
                    <ImageZoom
                      image={{
                        src: stateIdFileUrl || hyg_ident_photo,
                        alt: 'stateid_photo',
                        className: 'img',
                        style: {
                          maxHeight: 800,
                          borderRadius: 10,
                          marginBottom: 20,
                          objectFit: 'contain',
                          width: '80%',
                        },
                      }}
                      zoomImage={{
                        src: userProfile,
                        alt: 'stateid_photo',
                        style: {
                          height: 'auto',
                          maxWidth: 500,
                          width: 'auto',
                        },
                      }}
                    />
                  )
                  : <p style={{ fontStyle: 'italic', marginBottom: 30, color: 'red' }}>No State I.D.
                    provided</p>}

                <h4
                  className="card-text"
                  style={{
                    textAlign: 'center',
                    fontSize: '16px',
                    marginTop: 20,
                  }}
                >
                  <p style={{ color: '#899197' }}>Drag and drop files here or</p>
                </h4>

                <div>
                  <label htmlFor="file_state_id" className="btn btn-default">Browse</label>
                  <input
                    onChange={(event) => {
                      handleFileChange(event.target.files[0]);
                    }}
                    type="file"
                    id="file_state_id"
                    style={{
                      opacity: 0,
                      padding: 20,
                      position: 'absolute',
                      bottom: 20,
                      right: 0,
                      padding: 70,
                    }}
                  />
                </div>

              </div>
            </div>
          </div>

          <div style={{ width: 20 }} />

          <div
            className="card mt-5"
            style={{
              flex: 1,
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              overflowY: 'auto',
              marginBottom: 50,
            }}
          >
            <StripeLegalInfo />
          </div>

        </div>

        <h3>Professional Credentials</h3>

        {licenses.map((license) => {
          const stateLicense = license.credentials.find((credential) => credential && credential.credential_type === 'state_license');
          const daCertificate = license.credentials.find((credential) => credential && credential.credential_type === 'da_certificate');
          const cprBls = license.credentials.find((credential) => credential && credential.credential_type === 'cpr/bls');
          const radiology = license.credentials.find((credential) => credential && credential.credential_type === 'radiology');
          const reference = license.credentials.find((credential) => credential && credential.credential_type === 'reference');

          const professionCredential = stateCredentials?.[license.state]?.[license.profession];

          const hasStateLicense = professionCredential?.state_license;
          const hasDACertificate = professionCredential?.da_certificate;
          const hasCPRBLS = professionCredential?.['cpr/bls'];
          const hasRadiology = professionCredential?.radiology;
          const hasReference = professionCredential?.reference;

          const hasOnlyOneCredential = (license?.state && license?.profession)
            && stateCredentials
            && stateCredentials[license.state]
            && professionCredential
            && Object.keys(professionCredential).length === 1;

          return (
            <StateCredential
              license={license}
              stateLicense={stateLicense}
              reference={reference}
              daCertificate={daCertificate}
              cprBls={cprBls}
              radiology={radiology}
              hasStateLicense={hasStateLicense}
              hasDACertificate={hasDACertificate}
              hasCPRBLS={hasCPRBLS}
              hasReference={hasReference}
              hasRadiology={hasRadiology}
              hasOnlyOneCredential={hasOnlyOneCredential}
              getStatusColor={getStatusColor}
              stateCredentials={stateCredentials}
              setLicenses={setLicenses}
              allProfessions={allProfessions}
              licenses={licenses}
              setShowDeletePopup={setShowDeletePopup}
              setDeleteId={setDeleteId}
              dispatch={dispatch}
            />
          );
        })}

        <div style={{
          display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: 10,
        }}
        >

          <button
            className="btn btn-success"
            style={{ width: 94, height: 34 }}
            onClick={() => {
              setAddLicenseMode(true);

              const newObject = {
                status: 'new',
                state: 'AL',
                profession: 'DA',
                credentials: [],
                professionName: 'Dental Assistant',
              };

              dispatch(addLicense(newObject));
            }}
            type="button"
          >
            <img style={{ marginRight: 4 }} src={PlusIcon} alt="Plus icon" />
            License
          </button>
        </div>
      </div>

      <TaxDocument />
    </>
  );
}

export default LicenseCredentialTab;
