export const emailTemplate1 = `<p><strong style="background-color: rgba(255, 255, 255, 0);">Congrats {0},&nbsp;</strong></p>
<p><strong style="background-color: rgba(255, 255, 255, 0);">Welcome to TempMee! We are so excited to get you started.</strong></p>
<p><strong style="background-color: rgba(255, 255, 255, 0);">Now that you have been approved, what should you expect?</strong></p>
<ol>
        <li><span style="background-color: rgba(255, 255, 255, 0);">We have just launched in South Florida, so you will see jobs starting to get posted soon!&nbsp;They will pick up quickly over the next&nbsp;few weeks.</span></li>
        <li><span style="background-color: rgba(255, 255, 255, 0);">If you don't see job posts immediately, don't worry, the app is always working for you in the background. When jobs go live, you will get an alert on your phone!</span></li>
</ol>
<p><strong style="background-color: rgba(255, 255, 255, 0);">So, what should I do now?</strong></p>
<ol>
        <li><span style="background-color: rgba(255, 255, 255, 0);">Open the app and create your payment account.&nbsp;You’ll need this to accept jobs &amp; get paid.</span></li>
        <li><span style="background-color: rgba(255, 255, 255, 0);">Add a photo to your account so the dental offices know who to look for.</span></li>
        <li><span style="background-color: rgba(255, 255, 255, 0);">Remember, you can add multiple territories if you want jobs in other areas.</span></li>
        <li><span style="background-color: rgba(255, 255, 255, 0);">Lastly, if you ever need help, click the help (?) button in the upper right hand side of the app to sms chat with support.</span></li>
</ol>
<p><strong style="background-color: rgba(255, 255, 255, 0);">Let's change the way the world works!</strong></p>
<p><strong style="background-color: rgba(255, 255, 255, 0);">Best Regards,</strong></p>
<p><span style="background-color: rgba(255, 255, 255, 0);">The TempMee Team!</span></p>`;

export const emailTemplate2 = `<p><strong style="background-color: rgba(255, 255, 255, 0);">Congrats {0},</strong></p>
<div dir="ltr">
<div>
<p>Welcome to the TempMee Community! We are so excited to have you on board.</p>
<p><strong><u>Important:</u></strong></p>
<ul>
    <li><strong>To get started, please close and then re-open the app to see possible active offers in your territory</strong></li>
    <li><strong>Go into your phone's settings and turn the TempMee notifications&nbsp;<u>ON,</u>&nbsp;so you never miss an opportunity.</strong></li>
</ul>
<p>If you don't see job posts immediately, don't worry, the app is always working for you in the background. When jobs go live, you will get an alert on your phone!</p>
<p>So, what should I do now?</p>
<ol>
    <li>Set up your payment account. You won't need this to accept jobs, but you do to&nbsp;get paid.</li>
    <li>Remember, you can add multiple territories if you want jobs in other areas (Text us for a quick tutorial: 786-460-2170).</li>
    <li>Lastly, if you ever need help, click the help (?) button in the upper right hand side of the app to sms chat with support.</li>
</ol>
<p>Let's change the way the world works!</p>
<p>Best Regards,</p>
<p>The TempMee Team!</p>
</div>
</div>`;
