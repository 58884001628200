import dateFormat from 'dateformat';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { sendProfessionalPayout, updateTransactionStatus } from '../../../actions/paymnetsAction';
import { EntriesView } from './EntriesView';
import { getTransactionType } from './utils';

const StripeRedirection = ({ transaction }) => {
  const transactionId = transaction.metadata?.payment?.transaction_id;
  const link = transaction?.label=== 'invoice'   ? `https://dashboard.stripe.com/invoices/${transactionId}` : `https://dashboard.stripe.com/payments/${transactionId}`;

  if (transaction?.status !== 'open') {
    return (
      <a
        href={link}
        target="_blank"
        rel="noopener noreferrer"
        className="btn btn-info"
        type="submit"
      >
        Open Transaction
      </a>
    );
  }

  return <></>;
};


const RefundSection = ({ transaction, handleShowRefundPopup }) => {
  const currentJob = useSelector((state) =>  state.jobs.currentJob)

  const showRefundSection = transaction?.label !== 'refund' && transaction?.status !== 'open' && transaction?.label !== 'invoice' 
  if (showRefundSection) {
    return (
      <>
        {currentJob?.is_cancelled_by_user_type === "HYG" && transaction.label === 'charge' &&
        <p
          style={{
            fontFamily: 'Nunito',
            marginTop: 10,
            textAlign: 'center',
            fontSize: 12,
          }}
        >
          This shift was cancelled by the professional. Refund must be processed from the reposted shift.
        </p>
        }
        <button
          className="btn btn-success"
          onClick={() => handleShowRefundPopup(transaction)}
          type="submit"
          style={{marginTop: 0}}
        >
          Refund
        </button>
      </>
    )
  }


  return <></>;
};

export const Table = ({
  transactionList,
  handleShowRefundPopup
}) => {
  const dispatch = useDispatch();
  
  const handleSendProfessionalPayout = async (transaction) => {
    dispatch(sendProfessionalPayout(transaction?.id));
  }
  const markAsSucceeded = (transaction) => {
    const isInvoice = transaction?.label === 'invoice'  

    if (isInvoice) {
      return <></>
    }

    if ( transaction?.status === 'failed' || transaction?.status === 'pending' ) {
      return <button
        onClick={() => dispatch(updateTransactionStatus(transaction.id, 'paid'))}
        type="button"
        className="btn btn-primary mt-1"
      >
        Mark as succeeded
      </button>
    }
  }

  return (
    <div
      style={{
        minHeight: 200,
        overflowY: 'auto',
        borderBottom: '1px solid #efefef',
      }}
    >
      {transactionList?.map((transaction) => (
        <div 
          key={transaction.id}
          style={{
            display: 'flex',
            flexDirection: 'column',
            padding: '0px 10px',
            margin: 10,
            border: '1px solid #efefef',
            borderRadius: 25,
            flex: 1,
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyItems: 'center',
            }}
          >
            <div
              key={transaction.id}
              style={{
                display: 'flex',
                marginTop: 10,
                flex: 1,
              }}
            >
              <span
                style={{
                  margin: 10,
                  fontFamily: 'Nunito',
                  textAlign: 'center',
                  width: '30%',
                }}
              >
                {dateFormat(transaction.date, 'dddd, mmmm dS, yyyy, h:MM:ss TT') || ''}
              </span>
              <span
                style={{
                  fontFamily: 'Nunito',
                  margin: 10,
                  textAlign: 'center',
                  width: '20%',
                }}
              >
                {getTransactionType(transaction.metadata?.payment?.payment_type)}
              </span>

              <span
                style={{
                  fontFamily: 'Nunito',
                  margin: 10,
                  textAlign: 'center',
                  width: '20%',
                }}
              >
                {transaction.metadata?.payment?.brand && transaction.metadata?.payment?.last_4
                  ? `${transaction.metadata?.payment?.brand} *** ${transaction.metadata?.payment?.last_4}` : '-'}
              </span>

              <span
                style={{
                  fontFamily: 'Nunito',
                  margin: 10,
                  textAlign: 'center',
                  width: '20%',
                }}
              >
                {transaction.label}
              </span>
              <span
                style={{
                  fontFamily: 'Nunito',
                  width: '40%',
                  textAlign: 'center',
                }}
              >
                <EntriesView transaction={transaction} />
              </span>

              <span
                style={{
                  fontFamily: 'Nunito',
                  margin: 10,
                  width: '20%',
                  textAlign: 'center',
                  fontWeight: 'bold',
                }}
              >
                {transaction.status}
              </span>

              <div style={{ width: '20%' }}>
                <div style={{ width: '100%', display: 'flex', flexDirection: 'column', gap: 10, paddingTop: 10, paddingBottom: 10 }}>
                  {transaction.label !== 'revenue' && 
                  <>
                    <RefundSection transaction={transaction} handleShowRefundPopup={handleShowRefundPopup} />
                    <StripeRedirection transaction={transaction} />
                    {transaction.label === 'payout' && transaction.status === 'failed' && (
                      <button
                        className="btn btn-success"
                        onClick={() => handleSendProfessionalPayout(transaction)}
                        type="submit"
                      >
                        Payout to Professional
                      </button>
                    )}
                    {markAsSucceeded(transaction)}
                  </>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  )}
