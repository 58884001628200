import React from 'react';
import dateFormat from 'dateformat';

export default ({ npsInfo }) => (
  <div
    className="card mt-5"
    style={{
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
      justifyContent: 'center',
    }}
  >
    <div
      className="card-body"
      style={{
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
      }}
    >
      <h2 className="card-title" style={{ marginLeft: 10, fontFamily: 'Nunito', color: '#494963' }}>
        NPS
      </h2>
      <div
        style={{
          backgroundColor: 'gray',
          height: 40,
          width: 40,
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          borderRadius: 20,
          marginLeft: 10,
          marginRight: 10,
        }}
      >
        <h3
          className="card-title"
          style={{
            color: '#fff',
            margin: 0,
            padding: 0,
          }}
        >
          {npsInfo.length}
        </h3>
      </div>
    </div>
    <div
      style={{
        display: 'flex',
        borderBottom: '1px solid #ededed',
        flex: 1,
        marginLeft: 20,
        marginRight: 20,
      }}
    >
      <div style={{ flex: 5 }}>
        <p style={{ fontFamily: 'Nunito', fontWeight: 700 }}>Date</p>
      </div>
      <div style={{ flex: 3 }}>
        <p style={{ fontFamily: 'Nunito', fontWeight: 700 }}>Score</p>
      </div>
    </div>

    {npsInfo.length === 0 ? (
      <div
        style={{
          minHeight: 200,
          overflowY: 'auto',
          borderBottom: '1px solid #efefef',
        }}
      >
        <div style={{ display: 'flex', width: '100%', justifyContent: 'center' }}>
          <p
            style={{
              fontFamily: 'Nunito',
              fontSize: 18,
              alignText: 'center',
            }}
          >
            No NPS
          </p>
        </div>
      </div>
    ) : (
      <div
        style={{
          minHeight: 200,
          overflowY: 'auto',
          borderBottom: '1px solid #efefef',
        }}
      >
        {npsInfo.map((nps) => (
          <div
            key={nps.id}
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
              justifyItems: 'center',
              marginLeft: 10,
              marginRight: 10,
              paddingLeft: 10,
              paddingRight: 10,
              paddingBottom: 10,
            }}
          >
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                border: '1px solid #efefef',
                borderRadius: 25,
                marginTop: 10,
                flex: 1,
              }}
            >
              <p
                style={{
                  fontFamily: 'Nunito',
                  margin: 0,
                  padding: '10px 10px',
                  flex: 5,
                }}
              >
                {dateFormat(nps.date, 'dddd, mmmm dS, yyyy, h:MM:ss TT') || ''}
              </p>
              <p
                style={{
                  fontFamily: 'Nunito',
                  margin: 0,
                  padding: '10px 10px',
                  flex: 3,
                }}
              >
                {nps.score}
              </p>
            </div>
          </div>
        ))}
      </div>
    )}
  </div>
);
