import { CHAT_TYPES } from '../actions/actionTypes';

const defaultState = {
  conversations: [], // messages
  jobsWithMessages: [], // jobs
  currJobId: '',
  total: 0,
  searchParams: {
    page: 1,
    limit: 10,
  },
  isFinalPage: false,
  selectedJob: {},
};

export default (state = defaultState, action) => {
  switch (action.type) {
    case CHAT_TYPES.GET_JOBS_WITH_CONVERSATIONS_REQUEST:
      return {
        ...state,
        searchParams: action.payload,
      };

    case CHAT_TYPES.GET_JOBS_WITH_CONVERSATIONS_SUCCESS:
      const mergedJobs = [...state.jobsWithMessages, ...action.payload.result];
      return {
        ...state,
        jobsWithMessages: action.payload.page === 1 ? action.payload.result : mergedJobs,
        isFinalPage:
          action.payload.result.length === 0
          || action.payload.result.length < state.searchParams.limit,
      };
    case CHAT_TYPES.CHANGE_STATUS_SUCCESS: {
      return {
        ...state,
        selectedJob: action.payload,
      };
    }
    case CHAT_TYPES.GET_ALL_MESSAGES_SUCCESS:
      return {
        ...state,
        conversations: action.payload.messages,
        currJobId: action.payload.jobId,
        selectedJob: state.jobsWithMessages.filter((conv) => conv.id === action.payload.jobId)[0],
      };
    case CHAT_TYPES.POST_MESSAGE_SUCCESS:
      const msgCount = state.conversations.length;
      if (msgCount !== 0 && state.conversations[msgCount - 1].id === action.payload.id) return state;
      return {
        ...state,
        conversations: [...state.conversations, action.payload],
      };
    case CHAT_TYPES.NEW_MESSAGE_SUCCESS:
      if (state.currJobId === action.payload.job.id) {
        if (state.conversations.findIndex((j) => j.id === action.payload.message.id) !== -1) return state;

        return {
          ...state,
          conversations: [...state.conversations, action.payload.message],
        };
      }

      const index = state.jobsWithMessages.findIndex((j) => j.id === action.payload.job.id); // eslint-disable-line no-case-declarations
      if (index === -1) {
        return {
          ...state,
          jobsWithMessages: [action.payload.job, ...state.jobsWithMessages],
        };
      }
      return {
        ...state,
        jobsWithMessages: [
          state.jobsWithMessages[index],
          ...state.jobsWithMessages.slice(0, index),
          ...state.jobsWithMessages.slice(index + 1, state.jobsWithMessages.length),
        ],
      };

    default:
      return state;
  }
};
