import {
  GET_ALL_USER_SMS_SUCCESS,
} from '../actions/actionTypes';

const defaultState = {
  customerNotes: '',
  allSMSforPhone: [],
  reachable: false,
  smsCampaignAllowed: false,
  adminNotes: '',
};

export default (state = defaultState, action) => {
  switch (action.type) {
  case GET_ALL_USER_SMS_SUCCESS:
    const customerNotes = action.payload.conversation
      ? action.payload.conversation.customer.notes
      : '';
    const allSMSforPhone = action.payload.posts ? action.payload.posts : [];
    const reachable = action.payload.conversation
      ? action.payload.conversation.reachable
      : false;
    const smsCampaignAllowed = action.payload.sms_allowed || false;
    const adminNotes = action.payload.note || '';
    return {
      ...state,
      customerNotes,
      allSMSforPhone,
      reachable,
      smsCampaignAllowed,
      adminNotes,
    };


  default:
    return state;
  }
};
