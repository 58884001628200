import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import Spinner from 'react-bootstrap/Spinner';
import { createLoadingSelector } from '../../../../apis/selectors';
import { postPPJob } from '../../../../actions/ppJobsAction';

export default () => {
  const actions = ['POST_PP_JOB'];
  const [date, setDate] = useState('ASAP');
  const [description, setDescription] = useState('');
  const [schedule, setSchedule] = useState('Full Time Preferred');
  const [rateFrom, setRateFrom] = useState('');
  const [rateTo, setRateTo] = useState('');
  const [benefits, setBenefits] = useState('Full Benefits');
  const [calendlyUrl, setCalendlyUrl] = useState('');

  const dispatch = useDispatch();
  const loadingSelector = createLoadingSelector(actions);
  const isLoading = useSelector((state) => loadingSelector(state));

  const submitHasBeenPressed = () => {
    const rate = `$${rateFrom} - $${rateTo}/hour`;
    const data = {
      start_date: date,
      content: description,
      position: schedule,
      rate,
      benefits,
      calendly_host: calendlyUrl,
    };
    dispatch(postPPJob(data));
  };

  return (
    <div
      style={{
        height: '100vh',
        overflowY: 'scroll',
        width: '100vw',
      }}
    >
      <div className="card border-0">
        <div
          className="card-body"
          style={{
            display: 'flex',
            flexDirection: 'column',
            marginTop: 10,
            alignItems: 'center',
            width: '100%',
            height: '100%',
            marginBottom: 100,
          }}
        >
          <h3
            className="card-title"
            style={{ fontSize: '24px', textAlign: 'center', marginBottom: 30 }}
          >
            Post Permanent Job
          </h3>
          <div className="card border-0" style={{ width: '60%', marginBottom: 10 }}>
            <div className="card-body" style={styles.cardBodyStyle}>
              <h4 style={{ width: '20%' }}>Start Date:</h4>

              <select
                className="custom-select custom-select-lg"
                style={styles.selectStyle}
                value={date}
                onChange={(e) => setDate(e.target.value)}
              >
                <option value="ASAP">ASAP</option>
                <option value="1-2 Weeks">1-2 Weeks</option>
                <option value="2-4 Weeks">2-4 Weeks</option>
                <option value="1-2 Months">1-2 Months</option>
                <option value="3+ Months">3+ Months</option>
              </select>
            </div>
          </div>
          <div className="card border-0" style={{ width: '60%', marginBottom: 10 }}>
            <div
              className="card-body"
              style={{
                display: 'flex',
                flexDirection: 'row',
                width: '100%',
                alignItems: 'center',
              }}
            >
              <h4 style={{ width: '20%' }}>Schedule Type:</h4>

              <select
                className="custom-select custom-select-lg"
                style={{ height: 45, fontSize: 16 }}
                value={schedule}
                onChange={(e) => setSchedule(e.target.value)}
              >
                <option value="Full Time Preferred">Full Time Preferred </option>
                <option value="Part Time Preferred">Part Time Preferred</option>
                <option value="Flexible (Full or Part Time)">Flexible (Full or Part Time)</option>
              </select>
            </div>
          </div>
          <div className="card border-0" style={{ width: '60%', marginBottom: 10 }}>
            <div className="card-body" style={styles.cardBodyStyle}>
              <h4 style={{ width: '20%' }}>Pay Range:</h4>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  width: '90%',
                  alignItems: 'center',
                  justifyContent: 'center',
                }}
              >
                <h5>$</h5>
                <input
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    height: 45,
                    fontSize: 16,
                  }}
                  className="form-control"
                  value={rateFrom}
                  type="number"
                  onChange={(e) => setRateFrom(e.target.value)}
                />
                <h5>-$</h5>
                <input
                  style={{
                    marginLeft: 10,
                    marginRight: 10,
                    height: 45,
                    fontSize: 16,
                  }}
                  className="form-control"
                  value={rateTo}
                  type="number"
                  onChange={(e) => setRateTo(e.target.value)}
                />
                <h5>/hour</h5>
              </div>
            </div>
          </div>

          <div className="card border-0" style={{ width: '60%', marginBottom: 10 }}>
            <div className="card-body" style={styles.cardBodyStyle}>
              <h4 style={{ width: '20%' }}>Benefit Package:</h4>

              <select
                className="custom-select custom-select-lg"
                style={styles.selectStyle}
                value={benefits}
                onChange={(e) => setBenefits(e.target.value)}
              >
                <option value="Full Benefits">Full Benefits</option>
                <option value="Some Benefits">Some Benefits</option>
                <option value="No Benefits">No Benefits</option>
                <option value="Negotiable Benefit">Negotiable Benefit</option>
              </select>
            </div>
          </div>

          <div className="card border-0" style={{ width: '60%', marginBottom: 40 }}>
            <div className="card-body" style={styles.cardBodyStyle}>
              <h4 style={{ width: '20%' }}>Job Description: </h4>
              <textarea
                style={{ width: '100%', minHeight: 100, fontSize: 16 }}
                className="form-control"
                value={description}
                type="text"
                onChange={(e) => setDescription(e.target.value)}
              />
            </div>
          </div>

          <div className="card border-0" style={{ width: '60%', marginBottom: 40 }}>
            <div className="card-body" style={styles.cardBodyStyle}>
              <h4 style={{ width: '20%' }}>Calendly link (optional) :</h4>
              <textarea
                style={{ width: '100%', minHeight: 100, fontSize: 16 }}
                className="form-control"
                value={calendlyUrl}
                type="text"
                onChange={(e) => setCalendlyUrl(e.target.value)}
              />
            </div>
          </div>
          {isLoading ? (
            <div>
              <Spinner
                animation="border"
                role="status"
                variant="info"
                style={{
                  height: 30,
                  width: 30,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              />
            </div>
          ) : (
            <button
              type="button"
              className="btn btn-success"
              onClick={() => submitHasBeenPressed()}
              style={{
                width: 200,
                height: 50,
                borderRadius: 25,
                fontSize: 16,
                fontFamily: 'Nunito-Bold',
              }}
            >
              Submit
            </button>
          )}
        </div>
      </div>
    </div>
  );
};

const styles = {
  cardBodyStyle: {
    display: 'flex',
    flexDirection: 'row',
    width: '100%',
    alignItems: 'center',
  },
  selectStyle: { height: 45, fontSize: 16 },
};
