// eslint-disable-next-line import/prefer-default-export
export const EditUserFields = {
  title: 'title',
  first_name: 'First name',
  last_name: 'Last name',
  email_id: 'Email',
  office_notes: 'Office notes',
  admin_notes: 'Admin Notes',
  date_of_birth: 'Date of birth',
  office_name: 'Office name',
  main_office_number: 'Main office number',
  contact_number: 'Contact number',
  is_profile_complete: 'Is profile complete',
  receipt_enabled: 'Receipt enabled',
  hyg_ident_state_issued_id: 'State Id',
  hyg_ident_state_issued: 'State Id: State',
  hyg_ident_first_issue_date: 'State Id: date issued',
  hyg_ident_expiration_date: 'State Id: expiration date',
  hyg_ident_status: 'State Id: status',
  web_site: 'Web Site',
  linkedin_url: 'Linkedin Url',
  current_employer: 'Current Employer',
  notes_for_temp: 'Notes for temp',
  user_type: 'User type',
  after_hours_number: 'After hours number',
  covid_tc_acceptance: 'Covid tc acceptance',
  billing_banner_acceptance: 'billing banner acceptance',
  is_pre_approved: 'Pre Approved',
  account_standing: 'Account Standing',
  // legacy license
  hyg_licen_Id: '',
  hyg_licen_state_issued: '',
  hyg_licen_first_issue_date: '',
  hyg_licen_expiration_date: '',
  hyg_licen_school_attended: '',
  hyg_licen_graduation_date: '',
  // dentist admin's info
  account_admin_title: 'Account admin: title',
  account_admin_first_name: 'Account admin: First Name',
  account_admin_last_name: 'Account admin: Last Name',
  account_admin_sms_update: 'Account admin:  SMS update',
  account_admin_contact_number: 'Account admin: contact number',
  account_admin_position: 'Account admin: position',
  'office_info.is_dso': 'Office info: is DSO',
  'office_info.patients_per_hour': 'Office info: patients per hour',
  'office_info.software': 'Office info: software',
  'office_info.is_info_asked': 'Office info: info asked',
  adjustment_start: 'Adjustment Applied: Start Time',
  adjustment_finish: 'Adjustment Applied: End Time',
  adjustment_break: 'Adjustment Applied: Break',
  adjustment_status: 'Adjustment Status',
  'licenses.graduationDate': 'License: Graduation Date',
  'licenses.expirationDate': 'License: Expiration Date',
  'licenses.firstIssued': 'License: First Issued date',
  'licenses.school': 'License: School',
  'licenses.profession': 'License: Profession Type',
  'licenses.state': 'License: State',
  'licenses.license': 'License: #',
  'licenses.status': 'License: Status',
  'licenses.expectedDate': 'License: Expected Date',
  paid_out: 'Paid out',
  payout: 'Payout',
  'allowedRequest.locumTenensDentist': 'Allowed Request: Locum Tenens Dentist',
  'allowedRequest.ppJob': 'Allowed Request: PP Jobs',
  'allowedRequest.tempOrMdbShifts': 'Allowed Request: Temp or MDB Shifts',
};
