import tempMee from '../apis/tempMee';
import { CHAT_TYPES } from './actionTypes';
import { getAuthHeaders } from './authActions';
import getSocketConnection from '../apis/socket';

export const fetchJobsWithMessages = (params) => async (dispatch, getState) => {
  const newSearchParams = {
    page: params.page,
    limit: params.limit || 15,
  };
  const { searchParams } = getState().conversations;
  if (JSON.stringify(searchParams) === JSON.stringify(newSearchParams)) {
    return;
  }

  dispatch({
    type: CHAT_TYPES.GET_JOBS_WITH_CONVERSATIONS_REQUEST,
    payload: newSearchParams,
  });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.get('admin/conversations', {
      params: newSearchParams,
      headers,
    });
    dispatch({ type: CHAT_TYPES.GET_JOBS_WITH_CONVERSATIONS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: CHAT_TYPES.GET_JOBS_WITH_CONVERSATIONS_FAILURE, payload: err });
  }
};

export const fetchMessages = (jobId) => async (dispatch) => {
  dispatch({
    type: CHAT_TYPES.GET_ALL_MESSAGES_REQUEST,
  });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.get(`/admin/jobs/${jobId}/messages`, {
      headers,
    });
    dispatch({
      type: CHAT_TYPES.GET_ALL_MESSAGES_SUCCESS,
      payload: { messages: response.data, jobId },
    });
  } catch (err) {
    dispatch({ type: CHAT_TYPES.GET_ALL_MESSAGES_FAILURE, payload: err });
  }
};

export const postMessage = (jobId, text) => async (dispatch, getState) => {
  dispatch({
    type: CHAT_TYPES.POST_MESSAGE_REQUEST,
  });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post(
      `/admin/jobs/${jobId}/messages`,
      { text },
      {
        headers,
      },
    );
    dispatch({ type: CHAT_TYPES.POST_MESSAGE_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: CHAT_TYPES.POST_MESSAGE_FAILURE, payload: err });
  }
};

export const changeConversationStatus = (jobId, status) => async (dispatch, getState) => {
  dispatch({
    type: CHAT_TYPES.CHANGE_STATUS_REQUEST,
  });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `/admin/jobs/${jobId}/conversation`,
      { status },
      {
        headers,
      },
    );
    dispatch({ type: CHAT_TYPES.CHANGE_STATUS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: CHAT_TYPES.CHANGE_STATUS_FAILURE, payload: err });
  }
};

export const listenForhNewMessages = () => async (dispatch) => {
  try {
    const headers = await getAuthHeaders();
    const socket = getSocketConnection(headers);
    socket.on('new_message', ({ message, job }) => {
      dispatch({ type: CHAT_TYPES.NEW_MESSAGE_SUCCESS, payload: { message, job } });
    });
  } catch (error) {
    dispatch({ type: CHAT_TYPES.NEW_MESSAGE_FAILURE, payload: error });
  }
};

export const stopListeningNewMessages = () => ({ type: 'void' });
