import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import MultiSelect from '../../../commonComponents/MultiSelect';
import { getAdminRamUser } from '../../../../actions/userActions';

export const DsoRAMFilter = ({ filter, handleFilterChange, customStyles = {} }) => {
  const dispatch = useDispatch();
  const dsoRAMManagers = useSelector((state) => state.users.dsoRAMManagers);


  const dsoRamManagerOptions = [
    { label: 'Unassigned', value: 'unassigned' },
    ...dsoRAMManagers.map((el) => ({
      label: `${el?.first_name} ${el?.last_name}` || '',
      value: el?.id || '',
    })),
  ];

  useEffect(() => {
    if (!dsoRAMManagers) {
      dispatch(getAdminRamUser());
    }
  }, [dispatch, dsoRAMManagers]);

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        minWidth: 140,
        ...customStyles,
      }}
    >
      <p>DSO RAM Manager:</p>
      <MultiSelect
        className="custom-select custom-select-lg"
        style={{ position: 'relative' }}
        optionsStyle={{ width: 250 }}
        value={filter?.dsoRamIds ? filter?.dsoRamIds?.split(',') : dsoRamManagerOptions?.map((el) => el?.value)}
        options={dsoRamManagerOptions}
        onChange={(values) => {
          // if all options are selected, then we don't need to send the filter to backend
          handleFilterChange(
            'dsoRamIds',
            values.length === dsoRamManagerOptions.length ? null : values.join(','),
          );
        }}
        placeholder="All"
        showAllButton
        autoApply={false}
      />
    </div>
  );
};
