import React, { useEffect, useState } from 'react';
import Spinner from 'react-bootstrap/Spinner';
import { useDispatch, useSelector } from 'react-redux';
import { fetchReferrals } from '../../../actions/referralAction';
import { createLoadingSelector } from '../../../apis/selectors';
import SynchronizeIcon from '../../../images/synchronize.png';
import Styles from '../../../themes/style.module.scss';
import { Table } from '../../commonComponents/Table/Table';
import { referralColumns, referralRowColorStyles } from '../../commonComponents/jobs-column';
import { CustomHeader } from './CustomHeader';
import { ActionComponent } from './ActionComponent';
import { handleFilterChange } from '../../commonComponents/Table/utils';

const ROWS_PER_PAGE = 15;

const actions = ['GET_REFERRAL', 'PAYOUT_REFERRAL'];
const loadingSelector = createLoadingSelector(actions);

export const ReferralList = () => {
  const dispatch = useDispatch();
  const isLoading = useSelector((state) => loadingSelector(state));
  const referrals = useSelector((state) => state.referral.referrals);
  const searchParams = useSelector((state) => state.referral.searchParams);
  const totalFiltered = useSelector((state) => state.referral.totalReferrals);
  const [filter, setFilter] = useState({
    searchText: searchParams?.searchText || '',
    payFilter: searchParams?.payFilter || 'all',
    page: searchParams?.page || 1,
    sortField: searchParams?.sortField || '',
    order: searchParams?.order || 'desc',
  });
  const totalPages = Math.ceil(totalFiltered / ROWS_PER_PAGE);

  const handleTableChange = (page) => {
    setFilter((prevState) => ({ ...prevState, page }));
  };

  const reloadReferrals = () => {
    dispatch(
      fetchReferrals({
        ...searchParams,
        refresh: 1,
      }),
    );
  };

  useEffect(() => () => {
    // cleanup
    dispatch(
      fetchReferrals({
        page: 1,
        limit: 15
      }),
    );
  }, []);

  useEffect(() => {
    dispatch(
      fetchReferrals({
        ...filter,
      }),
    );
  }, [filter, dispatch]);

  const getUserUrl = (userId, userType) => `/user/${userType.toLowerCase()}/${userId}`;

  const getColumn = () =>
    referralColumns({
      getUserUrl,
      ActionComponent: (row) => <ActionComponent row={row} />,
    });

  return (
    <div
      style={{
        display: 'flex',
        height: '100vh',
        overflowY: 'auto',
        flexDirection: 'column',
        position: 'relative',
        alignItems: 'center',
        paddingTop: 24,
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          marginTop: 20,
          paddingTop: 8,
          alignItems: 'center',
          justifyContent: 'flex-end',
          width: '100%',
          position: 'absolute',
        }}
      >
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
            position: 'absolute',
            left: '50%',
          }}
        >
          <h3 className={Styles.h3} style={{ fontSize: '24px', textAlign: 'center' }}>
            Referral
          </h3>
          {isLoading ? (
            <div>
              <Spinner
                animation="border"
                role="status"
                variant="info"
                style={{
                  height: 30,
                  width: 30,
                  marginLeft: 20,
                  marginTop: 10,
                }}
              />
            </div>
          ) : (
            <div>
              <img
                src={SynchronizeIcon}
                width="38px"
                height="38px"
                className={Styles.dashboardUserIcon}
                onClick={reloadReferrals}
                alt="reload action"
              />
            </div>
          )}
        </div>
      </div>
      <div style={{ width: '90%', paddingTop: 60 }}>
        <CustomHeader 
          filter={filter} 
          handleFilterChange={(type, value) => handleFilterChange(type, value, setFilter)}
        />
        <Table
          keyField="referralList.id"
          initialPage={filter.page}
          columns={getColumn()}
          data={referrals}
          noDataComponent="There are no referrals"
          isLoading={isLoading}
          totalPages={totalPages}
          showColumnsToHide={false}
          paginationPerPage={ROWS_PER_PAGE}
          paginationTotalRows={totalFiltered}
          onPageChange={handleTableChange}
          conditionalRowStyles={referralRowColorStyles}
        />
      </div>
    </div>
  );
};
