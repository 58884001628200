import { useDispatch, useSelector } from "react-redux";
import React, {useState} from 'react';
import { updateDsoRamManager } from "../../../actions/userActions";



export const DSORamManagerSelector = () => {
  const dsoRAMManagers = useSelector((state) => state.users.dsoRAMManagers);
  const currentUser = useSelector((state) => state.users.currentUser);
  const [dsoRamManager, setDsoRamManager] = useState( currentUser?.dsoRamId === null ? 'unassigned' : currentUser?.dsoRamId);

  const dispatch = useDispatch();

  return (
    <div className="card mb-2">
      <div
        className="card-body"
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignContent: 'center',
        }}
      >
        <h3 className="card-title" style={{ textAlign: 'center' }}>
          DSO RAM Manager
        </h3>
        <select
          className="form-control"
          value={ dsoRamManager }
          onChange={(e) =>{
            setDsoRamManager(e.target.value)
            dispatch(updateDsoRamManager(e.target.value))}}
        >
          <option value="unassigned">None</option>
          {dsoRAMManagers?.map((manager) => (
            <option value={manager.id}>
              {manager.first_name}
              {' '}
              {manager.last_name}
            </option>
          ))}
        </select>
      </div>
    </div>
  );
}