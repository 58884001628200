import axios from 'axios';
import tempMee from '../apis/tempMee';
import {
  FETCH_PP_JOB_FAILURE,
  FETCH_PP_JOB_REQUEST,
  FETCH_PP_JOB_SUCCESS,
  FETCH_PP_JOBS_SUCCESS,
  FETCH_PP_JOBS_REQUEST,
  FETCH_PP_JOBS_FAILURE,
  EDIT_PP_JOB_SUCCESS,
  EDIT_PP_JOB_REQUEST,
  EDIT_PP_JOB_FAILURE,
  ASSIGN_OFFICE_PP_JOB_SUCCESS,
  ASSIGN_OFFICE_PP_JOB_REQUEST,
  ASSIGN_OFFICE_PP_JOB_FAILURE,
  POST_PP_JOB_SUCCESS,
  POST_PP_JOB_REQUEST,
  POST_PP_JOB_FAILURE,
  SHOW_MESSAGE,
  PP_RECRUITER_ACTIONS,
  PP_JOB_ACTIONS,
} from './actionTypes';
import history from '../history';
import { getAuthHeaders } from './authActions';

export const fetchPPJobs = (state) => async (dispatch, getState) => {
  const newSearchParams = {
    page: state.page,
    limit: state.sizePerPage || 15,
    status: state.status && state?.status !== 'all' ? state.status : '',
    officeName: state.officeName || '',
    state: state.state || '',
    zip: state.zipCode || '',
    recruiter: state.recruiter && state?.recruiter !== 'All' ? state.recruiter : '',
  };
  const { searchParams } = getState().jobs;
  if (JSON.stringify(searchParams) === JSON.stringify(newSearchParams)) {
    return;
  }
  dispatch({ type: FETCH_PP_JOBS_REQUEST, payload: newSearchParams });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.get('pp/admin/jobs', {
      params: newSearchParams,
      headers,
    });
    dispatch({ type: FETCH_PP_JOBS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: FETCH_PP_JOBS_FAILURE, payload: err });
  }
};

export const postPPJob = (data) => async (dispatch, getState) => {
  dispatch({ type: POST_PP_JOB_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post('pp/admin/jobs', data, {
      headers,
    });
    dispatch({ type: POST_PP_JOB_SUCCESS, payload: response.data });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The job has been created successfully.' },
    });
    history.push(`/pp-job/${response.data.id}`);
  } catch (err) {
    dispatch({ type: POST_PP_JOB_FAILURE, payload: err });
  }
};

export const editPPJob = (jobId, data) => async (dispatch, getState) => {
  dispatch({ type: EDIT_PP_JOB_REQUEST });
  try {
    const headers = await getAuthHeaders();

    const response = await tempMee.put(
      `pp/admin/jobs/${jobId}`,
      { ...data, timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone },
      {
        headers,
      },
    );
    dispatch({ type: EDIT_PP_JOB_SUCCESS, payload: response.data });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The job has been updated successfully.' },
    });
  } catch (err) {
    dispatch({ type: EDIT_PP_JOB_FAILURE, payload: err });
  }
};

export const fetchPPJob = (jobId) => async (dispatch, getState) => {
  dispatch({ type: FETCH_PP_JOB_REQUEST });

  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.get(`pp/admin/jobs/${jobId}`, {
      headers,
    });
    dispatch({
      type: FETCH_PP_JOB_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({ type: FETCH_PP_JOB_FAILURE, payload: err });
  }
};

export const assignOfficePPJob = ({ jobId, officeId }) => async (dispatch, getState) => {
  dispatch({ type: ASSIGN_OFFICE_PP_JOB_REQUEST });

  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.patch(
      `pp/admin/jobs/${jobId}/office/${officeId}`,
      {},
      {
        headers,
      },
    );
    dispatch({
      type: ASSIGN_OFFICE_PP_JOB_SUCCESS,
      payload: response.data,
    });
  } catch (err) {
    dispatch({ type: ASSIGN_OFFICE_PP_JOB_FAILURE, payload: err });
  }
};

export const getUsersPPJobs = (state) => async (dispatch, getState) => {
  const currUserId = getState().users.currentUser.id;

  const newSearchParams = {
    page: state.page,
    limit: state.limit || 15,
    searchText: state.searchText,
    currUserId,
    sortOrder: state.sortOrder,
    sortField: state.sortField,
  };

  const { searchParamsPPJobs } = getState().users;
  if (JSON.stringify(searchParamsPPJobs) === JSON.stringify(newSearchParams)) {
    return;
  }

  try {
    dispatch({
      type: PP_JOB_ACTIONS.FETCH_USER_PP_JOBS_REQUEST,
      payload: newSearchParams,
    });

    const headers = await getAuthHeaders();
    const response = await tempMee.get(`pp/admin/offices/${currUserId}/jobs`, {
      params: newSearchParams,
      headers,
    });

    dispatch({ type: PP_JOB_ACTIONS.FETCH_USER_PP_JOBS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: PP_JOB_ACTIONS.FETCH_USER_PP_JOBS_FAILURE, payload: err });
  }
};

export const fetchPPRecruiter = () => async (dispatch, getState) => {
  dispatch({ type: PP_RECRUITER_ACTIONS.FETCH_PP_RECRUITER_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.get('pp/recruiter/list', {

      headers,
    });
    dispatch({ type: PP_RECRUITER_ACTIONS.FETCH_PP_RECRUITER_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: PP_RECRUITER_ACTIONS.FETCH_PP_RECRUITER_FAILURE, payload: err });
  }
};

export const applyForPPJob = (jobId, data) => async (dispatch, getState) => {
  dispatch({ type: PP_JOB_ACTIONS.APPLY_FOR_JOB_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post(`pp/admin/jobs/${jobId}/apply`, data,
      {
        headers,
      });
    dispatch({ type: PP_JOB_ACTIONS.APPLY_FOR_JOB_SUCCESS, payload: response.data });
    dispatch({
      type: SHOW_MESSAGE,
      payload: { message: 'The job application has been sent successfully.' },
    });
  } catch (err) {
    dispatch({ type: PP_JOB_ACTIONS.APPLY_FOR_JOB_FAILURE, payload: err });
  }
};

export const createPPAction = (jobId, applicatioId, actionType, notes) => async (dispatch, getState) => {
  dispatch({ type: PP_JOB_ACTIONS.CREATE_ACTION_PP_JOBS_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.post(`pp/admin/jobs/${jobId}/applications/${applicatioId}/action`, { action: actionType, notes },
      {
        headers,
      });

    dispatch({ type: PP_JOB_ACTIONS.CREATE_ACTION_PP_JOBS_SUCCESS, payload: { newAction: response.data, applicatioId } });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: `Application was updated successfully. The ${actionType} type was applied.`,
      },
    });
  } catch (err) {
    dispatch({ type: PP_JOB_ACTIONS.CREATE_ACTION_PP_JOBS_FAILURE, payload: err });
  }
};

export const getAllPPApplications = (jobId, applicatioId) => async (dispatch, getState) => {
  dispatch({ type: PP_JOB_ACTIONS.GET_PP_APPLICATIONS_JOBS_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.get(`pp/admin/jobs/${jobId}/detail`,
      {

        headers,
      });
    dispatch({ type: PP_JOB_ACTIONS.GET_PP_APPLICATIONS_JOBS_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: PP_JOB_ACTIONS.GET_PP_APPLICATIONS_JOBS_FAILURE, payload: err });
  }
};

export const getApplicationDetail = (jobId, applicatioId) => async (dispatch, getState) => {
  dispatch({ type: PP_JOB_ACTIONS.GET_PP_APPLICATION_REQUEST });
  try {
    const headers = await getAuthHeaders();
    const response = await tempMee.get(`pp/admin/jobs/${jobId}/applications/${applicatioId}`,
      {

        headers,
      });
    dispatch({ type: PP_JOB_ACTIONS.GET_PP_APPLICATION_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: PP_JOB_ACTIONS.GET_PP_APPLICATION_FAILURE, payload: err });
  }
};

export const hideSelectedApplication = () => ({
  type: PP_JOB_ACTIONS.HIDE_SELECTED_APPLICATION,
});

export const uploadApplicationResume = (jobId, applicatioId, resume) => async (dispatch, getState) => {
  dispatch({ type: PP_JOB_ACTIONS.UPLOAD_APPLICATION_RESUME_JOBS_REQUEST });
  try {
    const headers = await getAuthHeaders();

    const resWithS3Link = await tempMee.get(`/pp/admin/jobs/${jobId}/applications/${applicatioId}/resume-upload`,
      {
        headers,
      });

    const formData = new FormData();
    formData.append(
      'myFile',
      resume,
      resume.name,
    );

    axios.put(resWithS3Link.data.url, resume, {
      headers: {
        'Content-Type': 'application/pdf',
      },
    });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Resume was updated successfully.',
      },
    });

    dispatch({ type: PP_JOB_ACTIONS.UPLOAD_APPLICATION_RESUME_JOBS_SUCCESS, payload: { jobId, applicatioId } });
  } catch (err) {
    dispatch({ type: PP_JOB_ACTIONS.UPLOAD_APPLICATION_RESUME_JOBS_FAILURE, payload: err });
  }
};

export const setInterviewApplication = (jobId, applicatioId, interviewDate) => async (dispatch, getState) => {
  try {
    dispatch({ type: PP_JOB_ACTIONS.SET_INTERVIEW_TIME_REQUEST });
    const headers = await getAuthHeaders();

    const response = await tempMee.post(`/pp/admin/jobs/${jobId}/applications/${applicatioId}/set-time`, { time: interviewDate },
      {
        headers,
      });
    dispatch({ type: PP_JOB_ACTIONS.SET_INTERVIEW_TIME_SUCCESS, payload: { interviewInfo: response.data, applicatioId } });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Interview time was updated successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: PP_JOB_ACTIONS.SET_INTERVIEW_TIME_FAILURE, payload: err });
  }
};

export const editResumeDetails = (jobId, applicationId, resumeDetails) => async (dispatch, getState) => {
  try {
    dispatch({ type: PP_JOB_ACTIONS.SET_RESUME_DETAILS_REQUEST });
    const headers = await getAuthHeaders();

    const response = await tempMee.patch(`/pp/admin/jobs/${jobId}/applications/${applicationId}/resume`, resumeDetails,
      {
        headers,
      });
    dispatch({ type: PP_JOB_ACTIONS.SET_RESUME_DETAILS_SUCCESS, payload: { resumeDetails: response.data, applicationId } });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Resume edited successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: PP_JOB_ACTIONS.SET_RESUME_DETAILS_FAILURE, payload: err });
  }
};

export const fetchApplicationHistory = (jobId, applicatioId) => async (dispatch, getState) => {
  try {
    dispatch({ type: PP_JOB_ACTIONS.FETCH_APPLICATION_HISTORY_REQUEST });
    const headers = await getAuthHeaders();

    const response = await tempMee.get(`/pp/admin/jobs/${jobId}/applications/${applicatioId}/history`,
      {
        headers,
      });
    dispatch({ type: PP_JOB_ACTIONS.FETCH_APPLICATION_HISTORY_SUCCESS, payload: response.data });
  } catch (err) {
    dispatch({ type: PP_JOB_ACTIONS.FETCH_APPLICATION_HISTORY_FAILURE, payload: err });
  }
};

export const updatePPApplication = (jobId, applicatioId, updateData) => async (dispatch, getState) => {
  try {
    dispatch({ type: PP_JOB_ACTIONS.UPDATE_PP_APPLICATION_REQUEST });
    const headers = await getAuthHeaders();

    const response = await tempMee.patch(`/pp/admin/jobs/${jobId}/applications/${applicatioId}`, updateData,
      {
        headers,
      });
    dispatch({ type: PP_JOB_ACTIONS.UPDATE_PP_APPLICATION_SUCCESS, payload: response.data });

    dispatch({
      type: SHOW_MESSAGE,
      payload: {
        message: 'Application was updated successfully.',
      },
    });
  } catch (err) {
    dispatch({ type: PP_JOB_ACTIONS.UPDATE_PP_APPLICATION_FAILURE, payload: err });
  }
};
