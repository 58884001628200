import { InputNumber } from 'antd';
import React, { useRef } from 'react';
import CustomPortal from '../../commonComponents/CustomPortal';

export default ({ onClose, onSubmit }) => {
  const amountRef = useRef(0);

  const handleInputChange = (value) => {
    amountRef.current = value;
  };

  return (
    <CustomPortal
      title="Counter Cover (Gotu Contribution)"
      containerStyle={{
        width: '800px',
      }}
      text={
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: 16,
            padding: '0 16px',
            textAlign: 'left',
          }}
        >
          <span>
            Use this field to input a counter cover discount for this shift. Any applied discount
            will be tracked and removed from this shift&apos;s revenue. Check with your manager for
            budgets and limitations.
          </span>

          <span>How does it work:</span>
          <ul>
            <li>Enter the total counter cover amount ($ counter cover per hour * # hours)</li>
            <li>Maximum counter cover is $32.00</li>
            <li>
              Counter covers will only apply to shifts that have a counter offer (discounts will not
              apply if an office accepts a candidate at the listed rate)
            </li>
            <li>
              If a discount is applied before candidate confirmation, the office will see the
              discount.
            </li>
            <li>If after candidate confirmation, the office will be issued a refund.</li>
            <li>
              If a refund is applied and a candidate cancels the shift, the discount will be removed
              and will not apply to the re-filled professional unless the discount is re-applied.
            </li>
          </ul>

          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              gap: 8,
            }}
          >
            <b>Total Amount:</b>
            <InputNumber
              min={0}
              formatter={(value) =>
                `$ ${Number(value).toFixed(2)}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')
              }
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              onChange={handleInputChange}
              defaultValue={0}
              size="large"
              style={{ width: '120px' }}
            />
          </div>
        </div>
      }
      textStyle={{ textAlign: 'center' }}
      submitText="Apply Counter Cover"
      cancelText="Go Back"
      submitPressed={() => onSubmit(amountRef.current)}
      cancelPressed={onClose}
    />
  );
};
