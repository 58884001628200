import React, { Component } from 'react';
import { connect } from 'react-redux';
import Modal from 'react-modal';
import Spinner from 'react-bootstrap/Spinner';
import CustomButton from '../../commonComponents/buttons';

import LocationSearchInput from '../../commonComponents/LocationSearchInput';
import { addAddress, deleteAddress } from '../../../actions/userActions';
import {
  createLoadingSelector,
  createErrorMessageSelector,
} from '../../../apis/selectors';
import 'react-s-alert/dist/s-alert-default.css';
import 'react-s-alert/dist/s-alert-css-effects/slide.css';

class ProfileAddressInfo extends Component {
  constructor(props) {
    super(props);
    this.state = {
      editProfile: false,
      newDistance: 25,
      newAddress: '',
      allAddressArray: [],
      isShowDeleteAddressModal: false,
      deletedAddress: {},
    };
  }

  lineInfo(title, info, miles, isOfficeProfile) {
    return (
      <div
        className="container"
        style={{
          width: '100%',
          borderBottom: '1px solid #efefef',
        }}
      >
        <div className="row">
          <p>
            {' '}
            {title}
            {' '}
          </p>
          <p className="col-lg-8">{info}</p>
          {!isOfficeProfile && (
            <p style={{ fontWeight: 'bold', textAlign: 'right' }}>
              {miles}
              {' '}
              miles
            </p>
          )}

        </div>
      </div>
    );
  }

  addressForCreating(address) {
    return {
      formatted_address: address.address.formatted_address,
      latitude: address.coords.lat,
      longitude: address.coords.lng,
      place_id: address.address.place_id,
      miles: address.miles,
    };
  }

  addressForCreatingT(address) {
    return {
      formatted_address: address.formatted_address,
      latitude: address.coords.lat,
      longitude: address.coords.lng,
      place_id: address.place_id,
      miles: address.miles,
    };
  }

  deleteAddress = (index) => {
    this.setState({
      isShowDeleteAddressModal: true,
      deletedAddress:
        this.props.userProfile.address[index]
        || this.props.userProfile.address[index],
    });
  };

  cancelHasBeenClicked = () => {
    this.setState({
      isShowDeleteAddressModal: false,
      deletedAddress: '',
    });
  };

  showDeleteAddress() {
    return (
      <div
        className="card border-0"
        style={{
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <h1 className="card-title" style={{ fontSize: 36, marginTop: 40 }}>
          Delete Address?
        </h1>

        <div style={{ width: '80%' }}>
          <h3
            className="card-text"
            style={{ fontSize: '20px', textAlign: 'center' }}
          >
            Are you sure you want to delete this address
            {' '}
            {this.props.deletedAddress}
            ?
          </h3>
        </div>
        <div
          style={{
            display: 'flex',
            width: '60%',
            justifyContent: 'space-between',
            marginTop: '20px',
          }}
        >
          <CustomButton
            width={200}
            name="Cancel"
            fontSize={16}
            className="btn btn-secondary mr-3"
            click={() => this.cancelHasBeenClicked()}
          />
          <CustomButton
            width={200}
            name="Confirm"
            fontSize={16}
            color="#F4511E"
            className="btn btn-danger"
            class_name="button"
            click={() => this.deleteAddressConfirmed()}
          />
        </div>
      </div>
    );
  }

  deleteAddressConfirmed = () => {
    this.setState({
      isShowDeleteAddressModal: false,
      deletedAddress: '',
    });

    if (this.props.userProfile.address.length === 1) {
      alert("You can't remove your single address");
    } else {
      this.props.deleteAddress(this.state.deletedAddress.id);
    }
  };

  addressAppend = (address) => {
    this.setState({ newAddress: address });
  };

  saveNewAddress = () => {
    if (this.state.newAddress !== '') {
      this.props.addAddress(
        this.addressForCreating(this.state.newAddress),
        this.state.newDistance,
      );
      this.setState({ editProfile: false });
    }
  };

  render() {
    if (!this.props.userProfile) {
      return <div />;
    }
    return (
      <div>
        <div className="card border-0">
          <div className="card-body">
            <h3 className="card-title" style={{ textAlign: 'center' }}>
              Addresses
            </h3>

            {this.props.userProfile.address?.map((address, index) => (
              <div key={address._id}>
                <div
                  className="card-text"
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    minHeight: 44,
                    alignItems: 'center',
                  }}
                >
                  {this.lineInfo(
                    `${index + 1}`,
                    address.formatted_address,
                    address.miles,
                    this.props.userProfile.user_type === 'DNT',
                  )}

                  <img
                    className="card-img-right"
                    src={require('../../../images/close-cross.png')}
                    alt="remove"
                    onClick={(_) => this.deleteAddress(index)}
                    style={{
                      width: '24px',
                      height: '24px',
                    }}
                  />
                </div>
              </div>
            ))}
          </div>
        </div>
        {this.props.isLoading ? (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <Spinner
              animation="border"
              role="status"
              variant="info"
              style={{
                height: 30,
                width: 30,
                marginLeft: 20,
                marginTop: 10,
              }}
            />
          </div>
        ) : null}
        {this.state.editProfile ? (
          <div className="card border-0">
            <div className="card-body">
              <div
                style={{
                  borderBottom: '1px solid #efefef',

                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <p
                  className="card-text mr-2 ml-1"
                  style={{ fontWeight: '600', textAlign: 'center' }}
                >
                  New Address
                </p>
                <LocationSearchInput onSelect={this.addressAppend} />
              </div>
              {this.props.userProfile.user_type !== 'DNT' && (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',

                    marginTop: 5,
                  }}
                >
                  <p
                    className="card-text mr-2  ml-1"
                    style={{
                      fontWeight: '600',
                      padding: 10,
                      textAlign: 'center',
                    }}
                  >
                    Distance
                  </p>
                  <input
                    className="form-control"
                    type="text"
                    value={this.state.newDistance}
                    style={{
                      width: '100%',
                      height: 35,
                    }}
                    onChange={(e) => this.setState({
                      newDistance: e.target.value,
                    })}
                  />
                </div>
              )}

            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-evenly',
                marginTop: '10px',
                marginBottom: '10px',
              }}
            >
              <button
                className="btn btn-success"
                style={{
                  width: '100px',
                }}
                onClick={() => this.saveNewAddress()}
                type="submit"
              >
                Save
              </button>
              <button
                className="btn btn-secondary"
                style={{
                  width: '100px',
                }}
                onClick={() => this.setState({ editProfile: false })}
                type="button"
              >
                Cancel
              </button>
            </div>
          </div>
        ) : (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: '10px',
              marginBottom: '10px',
              justifyContent: 'space-evenly',
            }}
          >
            <button
              className="btn btn-info mt-2"
              style={{ width: '150px' }}
              onClick={() => this.setState({
                editProfile: true,
                allAddressArray: this.props.userProfile?.address || [],
              })}
              type="button"
            >
              Add Address
            </button>
          </div>
        )}
        <Modal
          isOpen={this.state.isShowDeleteAddressModal}
          onRequestClose={this.cancelHasBeenClicked}
          style={customStyles}
        >
          {this.showDeleteAddress()}
        </Modal>
      </div>
    );
  }
}
const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    width: '50%',
    height: '40%',
  },
};

const loadingSelector = createLoadingSelector([
  'ADD_NEW_ADDRESS',
  'DELETE_USER_ADDESS',
]);
const errorSelector = createErrorMessageSelector([
  'ADD_NEW_ADDRESS',
  'DELETE_USER_ADDESS',
]);
const mapStateToProps = (state) => ({
  userProfile: state.users.currentUser,
  isLoading: loadingSelector(state),
  error: errorSelector(state),
});

export default connect(mapStateToProps, {
  addAddress,
  deleteAddress,
})(ProfileAddressInfo);
