import React, { useState } from 'react';

export default ({
  isShow,
  title,
  input,
  leftButtonTitle,
  rightButtonTitle,
  leftButtonAction,
  rightButtonAction,
}) => {
  const [refundAmount, setRefundAmount] = useState('');
  const [refundDescription, setRefundDescription] = useState('');

  if (!isShow) {
    return <div />;
  }
  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
      }}
      className="card border-0"
    >
      <h2 className="card-title">{title}</h2>
      {input ? (
        <div
          style={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              width: '100%',
            }}
          >
            <h4 style={{ marginRight: 5 }}>$</h4>

            <input
              className="form-control ml-1"
              type="number"
              value={refundAmount}
              placeholder="00.00"
              onChange={(event) => setRefundAmount(event.target.value)}
            />
          </div>

          <input
            style={{
              width: '95%',
              marginTop: 10,
              marginLeft: 10,
            }}
            className="form-control"
            value={refundDescription}
            placeholder="Refund description"
            onChange={(event) => setRefundDescription(event.target.value)}
          />
        </div>
      ) : (
        <div />
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          marginTop: 20,
        }}
      >
        <button
          className="btn btn-secondary"
          style={{ marginRight: '12px', width: 150 }}
          onClick={() => leftButtonAction()}
          type="button"
        >
          {leftButtonTitle}
        </button>

        <button
          className="btn btn-danger"
          style={{ marginLeft: '12px', width: 150 }}
          onClick={() => rightButtonAction(refundAmount, refundDescription)}
          type="submit"
        >
          {rightButtonTitle}
        </button>
      </div>
    </div>
  );
};
